import React, { useContext } from 'react';

import './index.scss';
import {
    UploadInvoicesForm,
    Button,
    Collapse
} from 'components';
import {
    formatPrice,
    invoices
} from 'utils';
import { ModalContext } from 'contexts';

export const FTADetails = ({ ViewDetailsDoc, sampleDoc }) => {
    const { setAddTask, setDocument } = useContext(ModalContext);
    return (
        <div className="tr__fta-details">
            <div className="row">
                <div className="col-12 col-sm-3">
                    <div className="tr__fta-details--form">
                        <UploadInvoicesForm />
                        <div className="tr__fta-details--form__footer">
                            <p className="mbx2">All uploaded invoices shall be stored under document tab.</p>
                            <Button className="w-100">
                                Search Again
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    {invoices.map((invoice, invoiceIndex) => (
                        <div className={`tr__fta-details--invoice ${invoiceIndex < invoices.length - 1 ? 'mbx5' : ''}`} key={invoiceIndex}>
                            <div className='invoice-header'>
                                <h1 className="h1 f-medium mbx3 text-center">
                                    <small>Invoice No.:</small>
                                    <span>{invoice.no}</span>
                                </h1>
                            </div>
                            {invoice.goods.map((good, goodIndex) => (
                                <div key={goodIndex}>
                                    <div className="invoice-table mtx3">
                                        {good.type && (
                                            <h5 className="h5 f-medium mbx2">
                                                <span>Goods type:</span>
                                                <span>{good.type}</span>
                                            </h5>
                                        )}
                                        <Collapse
                                            initialOpen={true}
                                            className="details no-padding"
                                            renderHeader={() => (
                                                <div className="good-title d-flex align-items-center justify-content-between w-100">
                                                    <h5 className="h5 f-medium">
                                                        <span>{good.date}</span>
                                                        <span>{good.status}</span>
                                                    </h5>
                                                    <p className="toggle-btn">
                                                        <i className="icon-chevron-down" />
                                                    </p>
                                                </div>
                                            )}
                                        >
                                            {good.groups.map((group, groupIndex) => (
                                                <Collapse
                                                    key={groupIndex}
                                                    className="invoice-table--collapse no-padding"
                                                    disableHeaderClick={true}
                                                    renderHeader={handleCollapse => (
                                                        <div className="invoice-table--group d-flex w-100">
                                                            <div className="info">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <h3 className="h3 f-medium mbx2">
                                                                        <span className="mrx1">Group:</span>
                                                                        <span>{group.name}</span>
                                                                    </h3>
                                                                    {good.type === 'Candy' && (
                                                                        <p className="tr__link more-link secondary" onClick={handleCollapse}>
                                                                            <span className="show">Show more</span>
                                                                            <span className="hide">Show less</span>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className="d-flex align-items-center info-row">
                                                                    <p className="label info-row--label">Custom duty</p>
                                                                    <p className="info-row--value">{group.duty}</p>
                                                                </div>
                                                            </div>
                                                            <div className="price text-right">
                                                                {group.price && group.currency && (
                                                                    <>
                                                                        <h4 className="h4 f-medium">{group.currency}</h4>
                                                                        <h4 className="h4 f-medium">{formatPrice(group.price)} saved</h4>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    <div className="invoice-table--group details d-flex w-100">
                                                        <div className="info">
                                                            <div className="info--wrapper">
                                                                <p className="label mbx1">Others</p>
                                                                {group.details && group.details.length > 0 ? group.details.map((row, rowIndex) => (
                                                                    <div className="info-row d-flex align-items-center" key={rowIndex}>
                                                                        <p className="info-row--label">{row.name}</p>
                                                                        <p className="info-row--value">{row.value}</p>
                                                                    </div>
                                                                )) : (
                                                                        <p className="no-result">No details</p>
                                                                    )}
                                                            </div>
                                                            <div className="details-links">
                                                                <p className="tr__link" onClick={() => setDocument(true)}>
                                                                    <i className="icon-file" />
                                                                    <span>Show Document</span>
                                                                </p>
                                                                <p className="tr__link mlx3">
                                                                    <i className="icon-check" />
                                                                    <span>Add To Task</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="price"></div>
                                                    </div>
                                                </Collapse>
                                            ))}

                                        </Collapse>
                                    </div>
                                    <div className="invoice-table-documnents">
                                        <h3 className="h3 f-medium mtx3 mbx2">Documents</h3>
                                        <div className="table--content">
                                            <div className="table--content--header f-medium">
                                                Document Name
                                        </div>
                                            {good.documents.map((document, documentIndex) => (
                                                <div className="table--content--body d-flex table-body justify-content-between" key={documentIndex}>
                                                    <div className="column name">
                                                        {document.name}
                                                    </div>
                                                    <div className="column link f-medium d-flex justify-content-between">
                                                        <p className="tr__link mrx2" onClick={() => ViewDetailsDoc()}>Details</p>
                                                        <p className="tr__link" onClick={() => sampleDoc()}>Sample doc</p>
                                                    </div>
                                                    <div className="column action-button">
                                                        <Button
                                                            className="outline"
                                                            type="secondary"
                                                            onClick={() => setAddTask(true)}
                                                        >
                                                            Add To Task
                                                </Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};