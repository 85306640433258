import React, { createContext, useContext, useState } from 'react';
import * as _ from 'lodash';

import { 
    getIssue, 
    getIssues, 
    changeStatus,
    OPERATION_FAILED_MESSAGE, 
    createAnIssue,
    updateIssue,
    removePartnerIssue,
    addPartnerIssue,
    getComments,
    createComment,
    COMMENT_PAGE_SIZE,
    getTasksIssue,
    FILE_TYPE_ISSUE_CMT_DOCS,
    getUploadedSignedFileUrl,
} from 'actions';
import { HomeContext, AuthContext, UploadFileContext } from 'contexts';

export const IssueContext = createContext();

export const IssueContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
    } = useContext(HomeContext);
    const { doUploadFile } = useContext(UploadFileContext);

    const [issues, setIssues] = useState([]);
    const [taskIssue, setTaskIssue] = useState([]);
    const [issue, setIssue] = useState();
    const [comments, setComments] = useState([]);
    const [statusIssue, setStatusIssue] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalIssues, setTotalIssues] = useState(0);
    const [currentCommentPage, setCurrentCommentPage] = useState(0);
    const [totalCommentPages, setTotalCommentPages] = useState(1);
    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetIssues = async (shipmentId, params, callback) => {
        if(shipmentId) {
            try {
                setLoading(true);
                const response = await getIssues(shipmentId, params, loggedInUser);
                const { data, status } = response;
                if(status === 200) {
                    if(params.page !== currentPage) {
                        setIssues(oldIssues => [...oldIssues, ...(data.items || [])]);
                    } else {
                        setIssues(data.items || []);
                    }
                    setTotalPages(data.totalPage);
                    setCurrentPage(data.currentPage);
                    setTotalIssues(data.totalItems);

                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetIssue = async (shipmentId, issueId, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await getIssue(shipmentId, issueId, loggedInUser);
                const { data, status } = response;
                if(status === 200) {
                    setIssue(data || []);

                    doGetComments(shipmentId, issueId, {
                        page: 0,
                        limit: COMMENT_PAGE_SIZE
                    }, () => {
                        if(callback) callback();
                    })

                    
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doChangeStatus = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {         
            try {
                setLoading(true);
                const response = await changeStatus(shipmentId, issueId, payload, loggedInUser);
                const { status } = response;
                if( status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateIssues = async (shipmentId, payload, callback) => {
        if(shipmentId) { 
            try {
                setLoading(true);
                const response = await createAnIssue(shipmentId, payload, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    if (callback) callback(data);
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doUpdateIssue = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await updateIssue(shipmentId,issueId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            } 
        }
    }

    const doRemovePartnerIssue = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await removePartnerIssue(shipmentId, issueId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doAddPartnerIssue = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await addPartnerIssue(shipmentId, issueId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doGetComments = async (shipmentId, issueId, params, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await getComments(shipmentId, issueId, params, loggedInUser);
                const { data, status } = response;
                if(status === 200) {
                    let avatarUrls = [];
                    for (let comment of data.items) {
                        let avatarUrl = (comment.base.createdBy.avatar) ? _.find(avatarUrls, item => item.id === comment.base.createdBy.avatar) : null;
                        if (avatarUrl) avatarUrl = avatarUrl.url;

                        if (comment.base.createdBy.avatar && !avatarUrl) {
                            let signedAvatar = await getUploadedSignedFileUrl(comment.base.createdBy.avatar, loggedInUser);
                            avatarUrls.push({ id: comment.base.createdBy.avatar, url: signedAvatar.data.ret.downloadSignedURI.itemURI });
                            avatarUrl = signedAvatar.data.ret.downloadSignedURI.itemURI;
                        }

                        if (comment.base.createdBy.avatar) comment.base.createdBy.avatar = avatarUrl;

                        if (comment.attachments) {
                            for (let file of comment.attachments) {
                                let singedFile = await getUploadedSignedFileUrl(file.fileId, loggedInUser);
                                file.url = singedFile.data.ret.downloadSignedURI.itemURI;
                            }
                        }
                    }

                    if(params.page !== currentPage) {
                        setComments(oldComments => [...oldComments, ...(data.items || [])]);
                    } else {
                        setComments(data.items || []);
                    }
                    setTotalCommentPages(data.totalPage);
                    setCurrentCommentPage(data.currentPage);
                    // setTotalIssues(data.totalItems);

                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateComment = async (shipmentId, issueId, payload, callback) => {
        if (issueId) {
            try {
                setLoading(true);
                let tmpAttachments = [];
                let avatarUrl = '';

                if (payload.attachments) {
                    let promises = [];

                    for (let file of payload.attachments) {
                        promises.push(doUploadFile({
                            fileName: file.fileName.split('.').slice(0, -1).join('.'),
                            type: FILE_TYPE_ISSUE_CMT_DOCS,
                            subType: FILE_TYPE_ISSUE_CMT_DOCS,
                            fileExtension: file.fileName.split('.').pop(),
                        }, file.data, loggedInUser));
                    }

                    payload.attachments = await Promise.all(promises);
                    tmpAttachments = payload.attachments;
                    payload.attachments = payload.attachments.map(item => {
                        return {
                            fileName: item.name,
                            fileId: item.id,
                        };
                    });
                }

                const response = await createComment(shipmentId, issueId, payload, loggedInUser);
                const { data, status } = response;
               
                if(status === 200) {
                    if (data.base.createdBy.avatar) {
                        let signedAvatar = await getUploadedSignedFileUrl(data.base.createdBy.avatar, loggedInUser);
                        avatarUrl = signedAvatar.data.ret.downloadSignedURI.itemURI;
                        data.base.createdBy.avatar = avatarUrl;
                    }

                    data.attachments = tmpAttachments.map(item => {
                        return {
                            fileName: item.name,
                            url: item.url,
                        };
                    });
                    setComments(old => [...old, data]);
                    if(callback) callback()
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetTasksIssue = async (shipmentId, callback) => {
        if(shipmentId) {
            try {
                setLoading(true);
                const response = await getTasksIssue(shipmentId, loggedInUser);
                
                const { data, status } = response;
               
                if(status === 200) {
                    setTaskIssue(data || [])
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    return (
        <IssueContext.Provider 
            value={{
                currentPage,
                totalPages,
                totalIssues,
                issues,
                issue,
                statusIssue,
                comments,
                currentCommentPage,
                totalCommentPages,
                taskIssue,
                setTaskIssue,
                setComments,
                setStatusIssue,
                setIssue,
                setIssues,
                doGetIssues,
                doCreateIssues,
                doGetTasksIssue,
                doGetIssue,
                doUpdateIssue,
                setCurrentPage,
                doChangeStatus,
                doRemovePartnerIssue,
                doGetComments,
                doCreateComment,
                doAddPartnerIssue
            }}
        >
            { children }
        </IssueContext.Provider>
    )
}
