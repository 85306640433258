import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import avatar from 'assets/images/default-avatar.png';
import { Input, FileUploader } from 'components';
import { getErrorMessage, isEmail, isPhoneNumber, isPostalCode, isValidRegistrationNumber, isValidTaxNumber } from 'utils';
import { OrganizationContext } from 'contexts';

export const OrganizationDetailsForm = ({
    isModalForm = true,
    title ='',
    isEdit = false,
    children,
    register = () => {},
    errors = [],
    logo,
    setLogo,
}) => {
    const [file, setFile] = useState(null);
    const handleFileUpload = uploadFile => setFile(uploadFile);
    const { organization } = useContext(OrganizationContext);

    useEffect(() => {
        if (organization && logo) {
            organization.logo = logo;
        }
    }, [organization, logo]);

    const addressMarkup = (
        <div className="row address-markup">
            <div className="col-12">
                <Input
                    label="Company Address"
                    className="mbx3"
                    name="cAddress"
                    placeholder="Enter company address"
                    viewOnly={!isEdit}
                    refs={register({ required: true })}
                    defaultValue={organization && organization.companyAddress}
                    error={!!errors.cAddress}
                    errorMessage={getErrorMessage(errors.cAddress, "Company address")}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                <Input
                    label="Country"
                    className="mbx3"
                    name="country"
                    placeholder="Enter country"
                    viewOnly={!isEdit}
                    refs={register({ required: true })}
                    defaultValue={organization && organization.country}
                    error={!!errors.country}
                    errorMessage={getErrorMessage(errors.country, "Country")}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                <Input
                    label="Postal Code"
                    className="mbx3"
                    name="postalCode"
                    placeholder="Enter code"
                    viewOnly={!isEdit}
                    refs={register({ required: true, validate: isPostalCode })}
                    defaultValue={organization && organization.postalCode}
                    error={!!errors.postalCode}
                    errorMessage={getErrorMessage(errors.postalCode, "Postal code", "Invalid postal code")}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12'}>
                <Input
                    label="Company Registered Address"
                    className="mbx3"
                    name="companyRegisteredAddress"
                    placeholder="Enter company registered address"
                    viewOnly={!isEdit}
                    refs={register()}
                    defaultValue={organization && organization.registeredAddress}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                <Input
                    label="Registration Number"
                    className="mbx3"
                    name="registrationNumber"
                    placeholder="Enter registration number"
                    viewOnly={!isEdit}
                    refs={register({ validate: value => isValidRegistrationNumber(value, false) })}
                    defaultValue={organization && organization.registrationNumber}
                    error={!!errors.registrationNumber}
                    errorMessage={getErrorMessage(errors.registrationNumber, "Registration number", "Invalid registration number")}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                <Input
                    label="Company Tax Number"
                    className="mbx3 last"
                    name="companyTaxNumber"
                    placeholder="Enter company tax number"
                    viewOnly={!isEdit}
                    refs={register({ validate: value => isValidTaxNumber(value, false) })}
                    defaultValue={organization && organization.taxNumber}
                    error={!!errors.companyTaxNumber}
                    errorMessage={getErrorMessage(errors.companyTaxNumber, "Company tax number", "Invalid tax number")}
                />
            </div>
        </div>
    );

    return (
    <>
        {!isModalForm && (
            <div className="h2 tr__organization-title f-medium titleCard d-flex align-items-center">
                {children}
            </div>
        )}
        <div className={`tr__organization-details-form ${isModalForm ? 'modal-form' : ''}`}>
            {title && (
                <h2 className="h2 f-medium details-heading">{title}</h2>
            )}
            <div className="row main--content">
                <div className="col-12 col-sm-4 file-upload">
                    <FileUploader
                        mode="avatar"
                        multiple={false}
                        name="organizationLogo"
                        handleDrop={handleFileUpload}
                        uploadedFiles={file}
                        defaultAvatar={organization && organization.logo ? organization.logo : avatar}
                        disabled={!isEdit && !isModalForm}
                        refs={register()}
                        setAvatar={setLogo}
                    />
                </div>
                <div className="col-12 col-sm-8 organization-name">
                    <div className='col-12'>
                        <Input
                            label="Organization Name"
                            name="organizationName"
                            placeholder="Enter name"
                            viewOnly={!isEdit}
                            refs={register({ required: true })}
                            defaultValue={organization && organization.name}
                            error={!!errors.organizationName}
                            errorMessage={getErrorMessage(errors.organizationName, "Organization name")}
                        />
                    </div>
                </div>
               <div className="tr__organization-details-form--contact">
                    <div className="row">
                        <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                            <Input
                                label="Contact Email"
                                className="mbx3"
                                name="contactEmail"
                                placeholder="Enter email"
                                viewOnly={!isEdit}
                                refs={register({ required: true, validate: isEmail })}
                                maxLength="128"
                                defaultValue={organization && organization.contactEmail}
                                error={!!errors.contactEmail}
                                errorMessage={getErrorMessage(errors.contactEmail, "Contact email", "Invalid email format")}
                            />
                        </div>
                        <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                            <Input
                                label="Contact Phone Number"
                                className="mbx3"
                                name="organizationPhoneNo"
                                placeholder="Enter phone number"
                                viewOnly={!isEdit}
                                refs={register({ required: true, validate: isPhoneNumber })}
                                defaultValue={organization && organization.contactPhoneNumber}
                                error={!!errors.organizationPhoneNo}
                                errorMessage={getErrorMessage(errors.organizationPhoneNo, "Phone number", "Invalid phone number")}
                            />
                        </div>
                    </div>
                    {!isModalForm && (
                        addressMarkup
                    )}
                </div>
                {isModalForm && (
                    <div className="col-12">
                        {addressMarkup}
                    </div>
                )}
            </div>
        </div>
    </>
    )
};

OrganizationDetailsForm.propTypes = {
    isModalForm: PropTypes.bool,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    isEdit: PropTypes.bool,
    register: PropTypes.any,
    errors: PropTypes.any,
    logo: PropTypes.any,
    setLogo: PropTypes.func,
};
