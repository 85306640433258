import axios from 'axios';
import { authHeaders } from './auth';
import { SECTION, TEMPLATES,TEMPLATES_DETAILS } from './constants';

/** Get Templates
* @param {
*       tab : string
*       page: string
*       name: string
*       limit: string
* } params
*/
export const getTemplates = (params, token) => {
    return axios.get(TEMPLATES, params ? {
        params,
        ...authHeaders(token)
    } : authHeaders(token));
};

/** Get a Template
* @param {
    *      _id: string
    * } payload 
    */;

export const getTemplate = (id, token) => {
    if (!id) return;
    return axios.get(TEMPLATES_DETAILS.replace(':templateId', id), authHeaders(token));
};

/** Add new section
* @param {
    *      templateId: string
    *      name: string
    *      order: string
    * } payload 
    */;

export const addNewSection = (templateId,payload, token) => {
    if (!payload) return;
    return axios.post(SECTION.replace(':templateId',templateId), payload ,authHeaders(token));
};