import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
  ExtractedInfoTable,
  Button
} from 'components';
import { ModalContext, ExtractedContext, HomeContext } from 'contexts';

export const ViewExtractedInfo = ({ open = false, onCancel }) => {
  const { fileId } = useContext(HomeContext);
  const { viewExtractedInfo, setViewExtractedInfo } = useContext(ModalContext);
  const { ocrData, fileData, doGetOcrData } = useContext(ExtractedContext);
  const [extractedInfo, setExtractedInfo] = useState([]);
  const [file, setFile] = useState("");
  const handleCloseModal = () => {
    onCancel();
  }

  const handleCancel = () => {
    setViewExtractedInfo(false);
  }

  /*eslint-disable */
  useEffect(() => {
    if (viewExtractedInfo && fileId) {
      doGetOcrData(fileId);
    }
  }, [viewExtractedInfo])

  useEffect(() => {
    let temp = [];
    let commonGroup = {
      title: '',
      details: []
    };
    for (let gr in ocrData) {
      if (typeof (ocrData[gr]) === 'string') {
        commonGroup.details.push({
          name: gr,
          value: typeof (ocrData[gr]) === 'string' ? ocrData[gr] : JSON.stringify(ocrData[gr])
        });
      } else if (typeof (ocrData[gr]) === 'object') {
        let group = { title: gr, details: [] };
        for (let item in ocrData[gr]) {
          group.details.push({
            name: item,
            value: typeof (ocrData[gr][item]) === 'string' ? ocrData[gr][item] : JSON.stringify(ocrData[gr][item])
          });
        }
        temp.push(group);
      }
    }
    temp.unshift(commonGroup);
    setExtractedInfo(temp);
  }, [ocrData])

  useEffect(() => {
    setFile(fileData);
  }, [fileData])
  /*eslint-enable */

  return (
    <Modal
      open={open}
      className="tr__edit-extracted-info no-padding"
      onCancel={handleCloseModal}
      title='View And Edit Extracted Info'
      isBackDropClickable={false}
      footerLabel=""
    >
      <div className="page-box">
        <div className="tr__edit-extracted-info--title mtx2 mbx3 d-flex f-medium align-items-center">
          <h4 className="h4">Invoice No..:</h4>
          <h2 className="h2 mlx1">9340019971</h2>
        </div>
        <div className="d-flex justify-content-between">
          <div className="tax-image">
            <embed src={file.data} width="800px" height="1000px" type={file.contentType} />
          </div>
          <div className="tax-info">
            <ExtractedInfoTable
              extractedInfo={extractedInfo}
            />
          </div>
        </div>
      </div>
      <div className="tr__add-link-task__footer d-flex align-items-center justify-content-between">
        {/* <Button
          className="icon"
          type="primary"
          icon="icon-plus"
        >
          Add New Section
                </Button> */}
        <div></div>
        <div>
          <p className="tr__link secondary f-medium" onClick={handleCancel}>Cancel</p>
          <Button>
            Continue
                    </Button>
        </div>
      </div>
    </Modal>
  )
};

ViewExtractedInfo.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func
}