import React from 'react';
import PropTypes from 'prop-types';

import {
    EditShipmentInfoInput,
    Collapse
} from 'components';

export const EditContainers = ({ register, containerTracking, watch, editShipmentInfo, containers, setContainers }) => {

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Container Tracking" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="measurement"
                                        label="Measurement"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.measurement ? containerTracking.measurement : ''}
                                        originalvalue={containerTracking && containerTracking.measurement ? containerTracking.measurement : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('measurement',containerTracking && containerTracking.measurement ? containerTracking.measurement : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="containerYard"
                                        label="Container yard"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.containerYard ? containerTracking.containerYard : ''}
                                        originalvalue={containerTracking && containerTracking.containerYard ? containerTracking.containerYard : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('containerYard',containerTracking && containerTracking.containerYard ? containerTracking.containerYard : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="commodityDescription"
                                        label="Commodity description"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.commodityDescription ? containerTracking.commodityDescription : ''}
                                        originalvalue={containerTracking && containerTracking.commodityDescription ? containerTracking.commodityDescription : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('commodityDescription',
                                        containerTracking && containerTracking.commodityDescription ? containerTracking.commodityDescription : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="serviceType"
                                        label="Service type"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.serviceType ? containerTracking.serviceType : ''}
                                        originalvalue={containerTracking && containerTracking.serviceType ? containerTracking.serviceType : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('serviceType',containerTracking && containerTracking.serviceType ? containerTracking.serviceType : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="grossWeightUnit"
                                        label="Gross weight unit"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.unit : ''}
                                        originalvalue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.unit : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('grossWeightUnit',
                                                containerTracking && containerTracking.grossWeight 
                                                ? containerTracking.grossWeight.unit 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="grossWeightValue"
                                        label="Gross weight value"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.value : ''}
                                        originalvalue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.value : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('grossWeightValue',
                                                containerTracking && containerTracking.grossWeight 
                                                ? containerTracking.grossWeight.value 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="hsCode"
                                        label="HS Code"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.hsCode ? containerTracking.hsCode : ''}
                                        originalvalue={containerTracking && containerTracking.hsCode ? containerTracking.hsCode : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('hsCode',containerTracking && containerTracking.hsCode ? containerTracking.hsCode : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="numberOfContainers"
                                        label="Number of containers"
                                        refs={register()}
                                        defaultValue={containerTracking && containerTracking.numberOfContainers ? containerTracking.numberOfContainers : ''}
                                        originalvalue={containerTracking && containerTracking.numberOfContainers ? containerTracking.numberOfContainers : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('numberOfContainers',
                                                containerTracking && containerTracking.numberOfContainers 
                                                ? containerTracking.numberOfContainers 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="mtx4">Containers</div>
                                {containers.map(container => (
                                    <React.Fragment key={container}>
                                        <div className="col-12 col-sm-12">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`containerNumber${container}`}
                                                label="Container number"
                                                placeholder="BN823592"
                                                refs={register()}
                                                maxLength={50}
                                                defaultValue={
                                                    containerTracking && containerTracking.containers[container] 
                                                    ? containerTracking.containers[container].containerNumber 
                                                    : ''
                                                }
                                                originalvalue={
                                                    containerTracking && containerTracking.containers[container] 
                                                    ? containerTracking.containers[container].containerNumber 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`containerNumber${container}`,
                                                        containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].containerNumber 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`carrierSealNumber${container}`}
                                                label="Carrier seal number"
                                                placeholder="BN823592"
                                                refs={register()}
                                                defaultValue={
                                                    containerTracking && containerTracking.containers[container] 
                                                    ? containerTracking.containers[container].carrierSealNumber 
                                                    : ''
                                                }
                                                originalvalue={containerTracking && containerTracking.containers[container] 
                                                    ? containerTracking.containers[container].carrierSealNumber 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`carrierSealNumber${container}`,
                                                        containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].carrierSealNumber 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`containerType${container}`}
                                                label="Container type"
                                                refs={register()}
                                                defaultValue={
                                                    containerTracking && containerTracking.containers[container] 
                                                    ? containerTracking.containers[container].containerType 
                                                    : ''
                                                }
                                                originalvalue={containerTracking && containerTracking.containers[container] 
                                                    ? containerTracking.containers[container].containerType 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`containerType${container}`,
                                                        containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].containerType 
                                                        : ''
                                                    )
                                            }   
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`weightUnit${container}`}
                                                label="Weight unit"
                                                refs={register()}
                                                defaultValue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].weight.unit 
                                                    : ''
                                                }
                                                originalvalue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].weight.unit 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`weightUnit${container}`,
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].weight.unit 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`weightValue${container}`}
                                                label="Weight value"
                                                refs={register()}
                                                defaultValue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].weight.value 
                                                    : ''
                                                }
                                                originalvalue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].weight.value 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`weightValue${container}`,
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].weight.value : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`volumeUnit${container}`}
                                                label="Volume unit"
                                                refs={register()}
                                                defaultValue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].volume.unit 
                                                    : ''
                                                }
                                                originalvalue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].volume.unit 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`volumeUnit${container}`,
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].volume.unit 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`volumeValue${container}`}
                                                label="Volume value"
                                                refs={register()}
                                                defaultValue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].volume.value 
                                                    : ''
                                                }
                                                originalvalue={
                                                    containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                    ? containerTracking.containers[container].volume.value 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`volumeValue${container}`,
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].volume.value 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                                <div className="h4 f-medium add-container mtx4 align-items-center" onClick={() => setContainers(old => [...old, old.length])}>
                                    <i className="icon-plus mrx1"/>
                                    <span>Add New Container</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditContainers.propTypes = {
    register: PropTypes.func,
    containerTracking: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool,
    containers: PropTypes.array, 
    setContainers: PropTypes.func,
};
