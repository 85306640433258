import React, { useState } from 'react';

import './index.scss';
import {
    Button,
    UploadInvoicesForm,
    VerifyInvoices,
    FTADetails
} from 'components';

export const FTAAdvisor = () => {
    const [step, setStep] = useState(0);
    const handleChangeSection = stepValue => setStep(oldStep => oldStep + stepValue);

    return (
        <div className="tr__fta-advisor">
            <div className="tr__fta-advisor--header d-flex align-items-center">
                {step > 0 && (
                    <p className="tr__link tag mrx2" onClick={() => handleChangeSection(-1)}>
                        <i className="icon-chevron-left" />
                        <span>Back</span>
                    </p>
                )}
                {step < 2 && (
                    <h2 className="h2 f-medium">Search</h2>
                )}
            </div>
            {step < 2 ? (
                <div className={`tr__fta-advisor--content ${step ? 'wide' : ''}`}>
                    {step === 0 && (
                        <UploadInvoicesForm />
                    )}
                    {step === 1 && (
                        <VerifyInvoices />
                    )}
                </div>
            ) : <FTADetails />}
            {step < 2 && (
                <div className="tr__fta-advisor--footer d-flex align-items-center justify-content-end">
                    {step === 0 && (
                        <>
                            <p>All uploaded invoices shall be stored under document tab.</p>
                            <Button onClick={() => handleChangeSection(1)}>Continue</Button>
                        </>
                       
                    )}
                    {step === 1 && (
                        <div className="d-flex align-items-center">
                            <p className="tr__link f-medium secondary mrx2">Cancel</p>
                            <Button
                                onClick={() => handleChangeSection(1)}
                            >
                                Save and Continue
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}