import React from 'react';

import './index.scss';
import {
    DocumentTable
} from 'components';

export const VerifyInvoices = () => {
    return (
        <div className="tr__verify-invoices">
            <DocumentTable
                document="9340019971"
                className="mbx3"
            />
            <DocumentTable
                document="9340019832"
            />
        </div>
    )
};