import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { RadioGroup, ShipmentForm } from 'components';
import { roles, shipmentTypes } from 'utils';
import { BookingRequestContext, HomeContext } from 'contexts';
import { FREIGHT_PARTNER } from 'actions';

export const AddNewRequestDetails = ({ 
    className = '',
    register = () => {},
    errors = [],
    containers = [],
    setContainers = () => {},
    reset = () => {},
    getValues = () => {},
    shipmentDetails
}) => {
    const {
        brType
    } = useContext(HomeContext);
    const { bookingRequest } = useContext(BookingRequestContext);

    const [selectedRole, setSelectedRole] = useState(roles[0]);
    const [selectedShipmentType, setSelectedShipmentType] = useState(shipmentTypes[0]);
    const [typeOfShipment, setTypeOfShipment] = useState(false);

    /*eslint-disable */
    useEffect(() => {
        if (bookingRequest || shipmentDetails) {
            const creatorRole = bookingRequest ? bookingRequest.creatorRole : shipmentDetails.creatorRole;
            const type = bookingRequest ? bookingRequest.type : shipmentDetails.type;
            const selectedType = shipmentTypes.find(t => t.id === type);
            setSelectedRole(roles.find(role => role.id === creatorRole));
            setSelectedShipmentType(selectedType);
            handleChooseTypeShipment(selectedType);
        } else {
            setSelectedRole(null);
            handleChooseTypeShipment(shipmentTypes[0]);
            setContainers([0]);
        }
    }, [bookingRequest, shipmentDetails])
    /*eslint-enable */

    const handleChooseTypeShipment = type => {
        setSelectedShipmentType(type)
        if(type.id === "LCL") {
            setTypeOfShipment(true)
        } else {
            setTypeOfShipment(false)
        }

        reset();
    }
    return (
        <div className={`tr__add-request-details ${className}`}>
            <div className="tr__add-request-form">
                    {brType !== FREIGHT_PARTNER && (
                        <div className="tr__add-request-form__details">
                            <RadioGroup
                                label="Your role"
                                name="shipmentRole"
                                className="big-label"
                                refs={register()}
                                horizontal
                                items={roles.slice(0,2)}
                                value={selectedRole}
                                onChange={role => setSelectedRole(role)}
                            />
                        </div>
                    )}

                    <div className="tr__add-request-form__details">
                        <RadioGroup
                            label="Select type of shipment"
                            name="shipmentType"
                            className="big-label mtx2"
                            refs={register()}
                            horizontal
                            items={shipmentTypes}
                            value={selectedShipmentType}
                            onChange={handleChooseTypeShipment}
                         />
                    </div>
                                      
                    <ShipmentForm
                        register={register}
                        errors={errors}
                        containers={containers}
                        setContainers={setContainers}
                        typeOfShipment={typeOfShipment}
                        getValues={getValues}
                        shipmentDetails={shipmentDetails}
                    />
                </div>
        </div>
    )
};

AddNewRequestDetails.propTypes = {
    className: PropTypes.string,
    register: PropTypes.func,
    errors: PropTypes.any,
    containers: PropTypes.array,
    setContainers: PropTypes.func,
    reset: PropTypes.func,
    getValues: PropTypes.func,
    shipmentDetails: PropTypes.any
};