import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';

import './index.scss';
import {
    AddressDetails, 
    EditAISTracking, 
    EditBookingDetails,
    EditContainerTracking,
    EditInvoice,
    ShippingDetails
} from 'components';
import { HomeContext, ModalContext } from 'contexts';
import { MARK_TRACK_SHIPMENT } from 'actions';

export const VerifyDetailsForm = ({
    register = () => {},
    errors = [],
    getValues = () => {},
    shipmentDetails,
    vessels = [],
    setVessels = () => {},
    watch = () => {},
    setShipmentDetails = () => {},
    clearErrors = () => {},
    formState
}) => {
    const { markAsShipmentType, trackerTemplate, setFileId } = useContext(HomeContext);
    const { setViewExtractedInfo } = useContext(ModalContext);
    const handleViewExtracted = () => {
        setViewExtractedInfo(true);
        setFileId('5ff56fe5a9af000009f99f72');
    }
    return (
        <div className="tr__verify-details-form mbx2">
            <ShippingDetails 
                shippingDetails={shipmentDetails ? shipmentDetails.shippingDetails : null}
            />
            <AddressDetails
                register={register}
                routes={shipmentDetails ? shipmentDetails.routes : null}
                editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                setShipmentDetails={setShipmentDetails}
            />
            <EditBookingDetails
                register={register}
                bookingDetails={shipmentDetails ? shipmentDetails.bookingDetail : null}
                editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                watch={watch}
            />
            <EditContainerTracking
                register={register}
                shipmentDetails={shipmentDetails}
                editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                watch={watch}
            />
            <EditAISTracking
                register={register}
                vessels={vessels}
                setVessels={setVessels}
                errors={errors}
                getValues={getValues}
                trackings={shipmentDetails && shipmentDetails.vessels ? shipmentDetails.vessels : []}
                editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                watch={watch}
                clearErrors={clearErrors}
                formState={formState}
            />
            <EditInvoice 
                register={register} 
                errors={errors} 
            />
            <div className="tr__verify-details-form--note d-flex flex-column align-items-center justify-content-center">
                <Button
                    className="outline f-medium mtx2"
                    type="secondary"
                    onClick={handleViewExtracted}
                >
                    View All Extracted Information
                </Button>
            </div>
        </div>
    )
};

VerifyDetailsForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.any,
    getValues: PropTypes.func,
    vessels: PropTypes.array,
    setVessels: PropTypes.func,
    shipmentDetails: PropTypes.object,
    watch: PropTypes.func,
    clearErrors: PropTypes.func,
    formState: PropTypes.any
};