import React, { useContext, useState } from 'react';

import './index.scss';
import {
    Modal,
    TaskDetails,
    AddDueDate,
    AddRole
} from 'components';
import { HomeContext, ManagementContext, ModalContext, ShipmentInfoContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { dueDateOptions, dueDates } from 'utils';
import moment from 'moment';
import { VDL } from 'actions';
import { useLocation } from 'react-router-dom';

export const AddTask = ({ open = false, onCancel }) => {
    const { isTemplate, isEditTask, sectionId, setNotificationMessage, sectionName, eventTask, documentTask, selectTask, setIsEditTask } = useContext(HomeContext);
    const { doCreateTask, doUpdateShipmentTask, newEstSection, taskManagements, doDeleteTask } = useContext(ManagementContext);
    const { setAddTask } = useContext(ModalContext);
    const { handleSubmit, register, errors, reset } = useForm();
    const [dayAjust, setDayAjust] = useState(1);
    const [roles, setRoles] = useState('');
    const [sectionType, setSectionType] = useState('')
    const [dateType, setDateType] = useState(dueDates[0]);
    const [eventBasedDate, setEventBasedDate] = useState(dueDateOptions[0]);
    const [date, setDate] = useState(moment(new Date()).format('DD MMM YYYY'));
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    
    const handleAddTask = (data) => {
        const { taskName, typeOfTask, dueDate, eventBasedDate} = data;
        let selectedRole = [];
        roles.forEach(role => {
            selectedRole = [
                ...selectedRole,
                role.id.toUpperCase().replace('-', '_')
            ]
        })
        let selectedDueDate= { }
        if(dateType.id === "specific-date") {
            selectedDueDate = {
                specificDate: new Date(dueDate).getTime(),
            }
        } else {
            selectedDueDate = {
                eventBasedDate: {
                    typeOfEvent: eventBasedDate,
                    dateOfEvent: eventBasedDate === VDL ? shipmentInfo.addresses.pod.date : shipmentInfo.addresses.pol.date,
                    adjustDays:  dayAjust
                }
            }
        }

        const payload = {
            taskName: taskName.trim(),
            type: typeOfTask.toUpperCase(),
                dueDate : selectedDueDate
            ,
            roles: selectedRole,
        };
        doCreateTask(shipmentId, sectionType.key, payload, () => {
            reset();
            setAddTask(false);
            setRoles([]);
            setNotificationMessage(`
            <p> Task has been added successfully!</p>
            `);
        });
    }

    const handleEditTask = (data) => {
        const { taskName, typeOfTask, dueDate, eventBasedDate} = data;
        let selectedRole = [];
        roles.forEach(role => {
            selectedRole = [
                ...selectedRole,
                role.id.toUpperCase().replace('-', '_')
            ]
        })
        let selectedDueDate= { }
        if(dateType.id === "specific-date") {
            selectedDueDate = {
                specificDate: new Date(dueDate).getTime(),
            }
        } else {
            selectedDueDate = {
                eventBasedDate: {
                    typeOfEvent: eventBasedDate,
                    dateOfEvent: eventBasedDate === VDL ? shipmentInfo.addresses.pod.date : shipmentInfo.addresses.pol.date,
                    adjustDays:  dayAjust
                }
            }
        }
        const payload = {
            sectionId: sectionType.key,
            taskName: taskName.trim(),
            type: typeOfTask.toUpperCase(),
                dueDate : selectedDueDate
            ,
            roles: selectedRole,
        };
        doUpdateShipmentTask(shipmentId, eventTask.id, payload, () => {
            setAddTask(false);
            setIsEditTask(false);
            setNotificationMessage(`
            <p> Task has been updated successfully!</p>
        `);
        })
    }

    const hanndleDeleteTask = () => {
        doDeleteTask(shipmentId, eventTask.id, () => {
            setAddTask(false);
            setIsEditTask(false);
            setNotificationMessage(`
            <p> Task has been deleted successfully!</p>
        `);
        })
    }
    
    const handleCloseModal = () => {
        reset();
        onCancel();
        setIsEditTask(false);
        setDateType(dueDates[0])
        setRoles([]);
    }

    
    return (
        <Modal
            open={open}
            className="tr__add-task no-padding"
            onCancel={handleCloseModal}
            title={isTemplate ? 'Task Settings' : (isEditTask ? 'Edit task' : 'Add New Task')}
            cancelButton={isTemplate || isEditTask}
            onCancelClick={hanndleDeleteTask}
            cancelButtonLabel="Delete Task"
            cancelButtonClassNames="outline"
            cancelButtonType="primary"
            btnClasses={isTemplate || isEditTask ? 'justify-content-between' : 'justify-content-end'}
            submitButton
            submitButtonLabel={isTemplate || isEditTask ? 'Save Changes' : "Save Task"}
            onSubmitClick={isEditTask ? handleSubmit(handleEditTask) : handleSubmit(handleAddTask)}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit()}>
                    {children}
                </form>
            )}
        >
            <TaskDetails 
                title="Task Details"
                register={register}
                errors={errors}
                sectionTask={taskManagements}
                sectionName={sectionName}
                eventTask={eventTask}
                documentTask={documentTask ? documentTask : ''}
                isEditTask={isEditTask}
                setSectionType={setSectionType}
                sectionType={sectionType}
                newEstSection={newEstSection}
                sectionId={sectionId}
                selectTask={selectTask}
             />
            <AddDueDate 
                title="Add Due Date"
                register={register}
                errors={errors}
                setDayAjust={setDayAjust}
                dayAjust={dayAjust}
                eventTask={eventTask}
                setDateType={setDateType}
                dateType={dateType}
                date={date}
                setDate={setDate}
                isEditTask={isEditTask}
                eventBasedDate={eventBasedDate}
                setEventBasedDate={setEventBasedDate}
                optionBaseDate={shipmentInfo}
             />
            <AddRole
                title="Add Role"
                register={register}
                errors={errors}
                setRoles={setRoles}
                roles={roles}
                eventTask={eventTask}
                isEditTask={isEditTask}
            />
        </Modal>
    )
};