import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
    Input
} from 'components';
import { HomeContext, TemplateContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import { last, split } from "lodash"

export const AddNewSection = ({ open = false, onCancel }) => {
    const textInput = useRef(null);
    const {doAddNewSection } = useContext(TemplateContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const {doGetTemplatesDetails}  = useContext(TemplateContext)
    const templateId = last(split(useLocation().pathname,'/'));

    const onSubmit = () => {
        doAddNewSection(templateId, { name : textInput.current.value, order : 0 },() => {
            setNotificationMessage(`
                <p><strong>${textInput.current.value}</strong> Section has been created successfully!</p>
            `);
            doGetTemplatesDetails(templateId)
            onCancel()
            }
        )
    }

    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title="Add New Section"
            btnClasses="justify-content-between"
            submitButton
            onSubmitClick={onSubmit}
            submitButtonLabel="Add new"
            isBackDropClickable={false}
        >
            <div className="tr__section-name">
                <Input
                    label="Section name"
                    className="mbx3"
                    name="sectionName"
                    refs = {textInput}
                />
            </div>
        </Modal>
    )
};

AddNewSection.propTypes ={
    open : PropTypes.bool,
    onCancel : PropTypes.func,
}