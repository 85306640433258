import React, { useState } from 'react';

import './index.scss';
import {
    Input,
    Table,
    Pagination,
    Button
} from 'components';
import {
    shipmentLogs
} from 'utils';

export const ShipmentLog = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const columns = [
        {
            dataIndex: 'name',
            render: name => (
                <p>{name}</p>
            )
        },
        {
            dataIndex: 'email',
            render: email => (
                <p>{email}</p>
            )
        },
		{
			dataIndex: 'activity',
			render: activity => (
                <p>{activity}</p>
            )
        },
        {
			dataIndex: 'dateTime',
			render: dateTime => (
                <p>{dateTime}</p>
            )
        }
    ];

    return (
        <div
            className="tr__shipment-log"
        >
            <div className="tr__shipment-log--title d-flex align-items-center justify-content-between">
                <h2 className="h2 f-medium">Shipment Activities Log</h2>
                <div className="d-flex">
                    <Input
                        icon="icon-search"
                        iconPosition="right"
                        placeholder="Search"
                    />
                    <Button className="mlx4"> Export to CSV</Button>
                </div>
            </div>
            <div className="tr__shipment-log--content" id="shipment-logs">
                <Table
                    className="shipment-logs"
                    dataSource={shipmentLogs}
                    columns={columns}
                >
                   <div className="tr__shipment-log--table-header flex align-items-center justify-content-between table-header">
                        <div className="column name">
                            <p>Name</p>
                            <i className="icon-filter" />
                        </div>
                        <div className="column email">
                            <p>Email</p>
                            <i className="icon-filter" />
                        </div>
                        <div className="column activity">
                            <p>Activities</p>
                        </div>
                        <div className="column date">
                            <p>Date &amp; Time</p>
                            <i className="icon-filter" />
                        </div>
                    </div>
                </Table>
            </div>
            <div className="tr__shipment-log--footer d-flex align-items-center justify-content-end">
                <Pagination
                    anchorElement="#shipment-logs"
                    current={currentPage}
                    size={10}
                    total={500}
                    onChange={page => setCurrentPage(page)}
                />
            </div>
        </div>
    )
};