import React, { useContext, useState, useEffect } from 'react';

import {
    Tab,
    TabPane,
    ShipmentList
} from 'components';
import { useHistory } from 'react-router-dom';
import { HomeContext, ActiveShipmentsContext } from 'contexts';
import {
    // ACTIVE_SHIPMENT_PAGE_SIZE,
    ACTIVE_SHIPMENT_TABS,
    ACTIVE
} from 'actions';

export const ShipmentTabs = () => {
    const history = useHistory();
    const { isArchive, setShipment } = useContext(HomeContext);
    const { doGetActiveShipments, activeShipments, doGetNeedAttentionActiveShipments, needAttentionActiveShipments } = useContext(ActiveShipmentsContext);
    const [tabs] = useState(isArchive
        ? [ACTIVE_SHIPMENT_TABS.ALL, ACTIVE_SHIPMENT_TABS.COMPLETED, ACTIVE_SHIPMENT_TABS.CANCELLED]
        : [ACTIVE_SHIPMENT_TABS.ALL, ACTIVE_SHIPMENT_TABS.MY_TASK]);
        
    /*eslint-disable */
    useEffect(() => {
        doGetActiveShipments({
            // page: 0,
            // limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: ACTIVE
        });

        doGetNeedAttentionActiveShipments({
            tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: ACTIVE,
        });
    }, [])
    /*eslint-enable */

    const handleShowDetails = shipmentId => {
        if (shipmentId) {
            history.push(`/${isArchive ? 'archive' : 'active-shipments'}/${shipmentId._id}`);
            setShipment(shipmentId);
        }
    }

    const handleTabChange = tab => {
        let tabOption = ACTIVE_SHIPMENT_TABS.ALL;
        switch(tab) {
            case ACTIVE_SHIPMENT_TABS.ALL:
                tabOption = ACTIVE_SHIPMENT_TABS.ALL;
                break;
            case ACTIVE_SHIPMENT_TABS.MY_TASK:
                tabOption = ACTIVE_SHIPMENT_TABS.MY_TASK.replace('_', '');
                break;
            default:
        }

        doGetActiveShipments({
            // page: 0,
            // limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: tabOption,
            status: ACTIVE
        });

        doGetNeedAttentionActiveShipments({
            tab: tabOption,
            status: ACTIVE
        });
    }

    return (
        <div className="tr__shipment-tabs mtx2">
            <Tab initialTab={0} onTabClick={handleTabChange}>
                {tabs.map((tab, tabIndex) => (
                    <TabPane
                        key={tabIndex}
                        tabName={tab.toLowerCase().replace('_', ' ')}
                        tabSlug={tab}
                    >
                        <>
                            {needAttentionActiveShipments && (
                                <ShipmentList
                                    className='mbx4'
                                    title="Needed Your Attention"
                                    shipments={needAttentionActiveShipments}
                                    onShowDetails={handleShowDetails}
                                    isArchive={isArchive}
                                />
                            )}
                            <ShipmentList
                                className='mbx4'
                                title="Other Shipments"
                                shipments={activeShipments}
                                onShowDetails={handleShowDetails}
                                isArchive={isArchive}
                            />
                        </>
                    </TabPane>
                ))}
            </Tab>
        </div>
    )
};
