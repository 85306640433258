import React, { useContext, useEffect } from 'react';
import moment from 'moment';

import './index.scss';
import {
    Collapse,
    Button,
    Dropdown,
    ShipmentStep
} from 'components';
import { useHistory } from 'react-router-dom';
import { MARK_TRACK_SHIPMENT, SHIPMENT_DETAILS_TEMPLATE, SHIPPING_TRACKER } from 'actions';
import { HomeContext, TrackShipmentContext } from 'contexts';

export const SearchResults = ({ searchParams, setSearched, result, setIsTrackerSubmitted, className, shipmentInfo }) => {
    const history = useHistory();
    const { setTrackerTemplate, setMarkAsShipmentType } = useContext(HomeContext);
    const {
        trackers,
        selectedTracker,
        setCarriers,
        firstCarriers,
        trackerParams,
        setSelectedTracker,
        doGetRoute,
        setTrackers,
        setRoutes,
        doGetTrackers
    } = useContext(TrackShipmentContext);

    const handleChangeOptions = (value) => {
        if (trackers && trackers.length > 0) {
            const option = trackers.find(result => result.containerId === value.key);
            setSelectedTracker(option);

            doGetRoute({
                imo: option.vessels[0].imo,
                originPort: option.prePol.location.locode,
                destinationPort: option.postPod.location.locode
            })
        }
    }

    const handleSearchAgain = () => {
        if (setIsTrackerSubmitted) setIsTrackerSubmitted(false);
        setTrackers([]);
        setRoutes(null);
        setCarriers(firstCarriers);
        setSearched(false);
    }

    const handleMarkAsActiveShipment = () => {
        if (selectedTracker) {
            setMarkAsShipmentType(MARK_TRACK_SHIPMENT);
            setTrackerTemplate(selectedTracker);
            history.push(SHIPMENT_DETAILS_TEMPLATE.replace(':type', SHIPPING_TRACKER));
        }
    }

    /*eslint-disable */
	useEffect(() => {
        if(shipmentInfo) {
            doGetTrackers({
                type : shipmentInfo && shipmentInfo.searchParameter ? shipmentInfo.searchParameter.type : '',
                ...shipmentInfo && shipmentInfo.searchParameter ? shipmentInfo.searchParameter.parameter : ''});
        }
    }, [shipmentInfo]) 
    /*eslint-enable */

    return (
        <div className={`${className} tr__search-result`}>
            <div className="form-group">
                {searchParams && (
                    <>
                        <div className='tr__search-result--header mbx2 d-flex align-center justify-content-between'>
                            <p className="tr__link" onClick={handleSearchAgain}>Search Again</p>
                            <i className="icon-times" onClick={handleSearchAgain} />
                        </div>
                        <Collapse title="You have searched for">
                            <div className="tr__search-params">
                                <div className="param-row d-flex flex-wrap">
                                    {searchParams.slice(0, 1).map((param, paramIndex) => (
                                        <div
                                            key={paramIndex}
                                            className={`tr__search-params--item ${param.block ? 'block' : ''}`}
                                        >
                                            <p className="f-medium mbx1">{result}</p>
                                            <h6 className="h6">{trackerParams[2]}</h6>
                                        </div>
                                    ))}
                                    {searchParams.slice(1, 2).map((param, paramIndex) => (
                                        <div
                                            key={paramIndex}
                                            className={`tr__search-params--item ${param.block ? 'block' : ''}`}
                                        >
                                            <p className="f-medium mbx1">Carrier</p>
                                            <h6 className="h6">{trackerParams[1]}</h6>
                                        </div>
                                    ))}
                                </div>
                                <div className="param-row d-flex flex-wrap">
                                    {searchParams.slice(2, 3).map((param, paramIndex) => (
                                        <div
                                            key={paramIndex}
                                            className={`tr__search-params--item ${param.block ? 'block' : ''}`}
                                        >
                                            <p className="f-medium mbx1">Ports</p>
                                            {trackers && trackers.length > 0 && (
                                                <h6 className="h6">{`${trackers[0].pol.title} - ${trackers[0].pod.title}`}</h6>
                                            )}

                                        </div>
                                    ))}
                                </div>
                                <div className="param-row d-flex">
                                    <div
                                        className={`tr__search-params--item block`}
                                    >
                                        <p className="f-medium mbx1">Vessel ETD</p>
                                        {trackers && trackers.length > 0 && (
                                            <h6 className="h6">{`${moment(trackers[0].prePol.items[0].items[0].date).format('DD MMM YYYY | hh:mm A')}`}</h6>
                                        )}
                                    </div>
                                    <div
                                        className={`tr__search-params--item block`}
                                    >
                                        <p className="f-medium mbx1">Vessel ETA</p>
                                        {trackers && trackers.length > 0 && (
                                            <h6 className="h6">{`${moment(trackers[0].postPod.items[0].items[0].date).format('DD MMM YYYY | hh:mm A')}`}</h6>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </>
                )}
                {result !== "Container no" && (
                    <>
                        <p className="f-medium mbx1 mtx3 plx1">{`${trackers.length} Container${trackers.length > 1 ? 's' : ''} `}</p>
                        <div className="tr__search-result--containers mtbx2">
                            <Dropdown
                                className="select"
                                icon="icon-chevron-down"
                                iconPosition="right"
                                mode="select"
                                name="container"
                                label="Select contanier"
                                options={trackers && trackers.length > 0 ? trackers.map(container => {
                                    return {
                                        key: container.containerId,
                                        value: `${container.containerId}`
                                    }
                                }) : []}
                                defaultValue={selectedTracker ? selectedTracker.containerId : ''}
                                dropdownPosition="center"
                                onChange={handleChangeOptions}
                            />
                        </div>
                    </>
                )}

                <div className="tr__search-result--process mtbx2">
                    {selectedTracker && (
                        <>
                            <ShipmentStep step={selectedTracker.prePol} />
                            <ShipmentStep step={selectedTracker.pol} />
                            <ShipmentStep step={selectedTracker.pod} />
                            <ShipmentStep step={selectedTracker.postPod} />
                        </>
                    )}
                </div>
            </div>
            {searchParams && (
                <div className="form-btns">
                    <Button
                        isSubmitBtn={true}
                        onClick={handleMarkAsActiveShipment}
                    >
                        <span>Mark as active shipment</span>
                    </Button>
                </div>
            )}
        </div>
    )
}
