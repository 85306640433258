import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const Statuses = (
    {
        className = '',
        label,
        statuses = [],
        showLabel = false,
        suffix = '',
        unit = 'percentage',
        inputUnit = 'number',
    }
) => {
    const [totalTasks, setTotalTasks] = useState(0);

    /*eslint-disable */
    useEffect(() => {
        if (inputUnit === 'number' && statuses && statuses.length > 0 && !totalTasks) {
            let total = 0;
            statuses.forEach(status => total += status.num);

            setTotalTasks(total);
        }
    }, [statuses])
    /*eslint-enable */

    return statuses && statuses.length > 0 ? (
        <div className={`tr__statuses d-flex align-items-center mtx1 ${className}`}>
            {label && (
                <p className="f-medium">{label}</p>
            )}
            <div className="tr__statuses--list d-flex">
                {statuses.map((status, statusIndex) => (
                    <span
                        key={statusIndex}
                        className={`tr__status task-status ${status.id} ${className} ${showLabel ? 'label' : ''}`}
                        style={{ width: `${(inputUnit === 'number') ? ((status.num/totalTasks)*100) : status.num}%`}}
                        percentage={inputUnit === 'number' ? (unit === 'percentage' ? (status.num/totalTasks)*100 : (status.num > 0 ? status.num : '')) : status.num}
                        suffix={suffix}
                    />
                ))}
            </div>
        </div>
    ) : '';
};

Statuses.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    statuses: PropTypes.array,
    showLabel: PropTypes.bool,
    suffix: PropTypes.string,
    unit: PropTypes.oneOf(['number', 'percentage']),
    inputUnit: PropTypes.oneOf(['number', 'percentage']),
}
