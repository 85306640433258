import React, { useCallback, useContext, useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    Collaborators,
    Button,
    Tooltip,
    Collapse
} from 'components';
import { HomeContext, ModalContext } from 'contexts';
import { roles } from 'utils';
import { useDrag, useDrop } from 'react-dnd';
import { cloneDeep, concat, forEach, get, filter, find } from 'lodash';
import { CARD, EVENT, DOCUMENT } from 'actions';

const Card =
    ({  task,
        index,
        moveCard,
        isMyTemplate,
        partnerLimit,
        tasksLength 
    }) => {
        const { setAddTask, setAssignCollaborators, setEditSection } = useContext(ModalContext);
        const { setIsTemplate } = useContext(HomeContext);
        const [limit] = useState(partnerLimit || 4);
        const ref = useRef(null);

        const events = useMemo(()=>{
            return filter(get(task,'tasks',[]),['type',EVENT])
        },[task])

        const documents = useMemo(() =>{
            return filter(get(task,'tasks',[]),['type',DOCUMENT])
        },[task])

        const getCollaborators = (tasks)=>{
            let collaborators = [];

            forEach(tasks, t => {
                collaborators = concat(collaborators,t.partners)
            })

            return collaborators
        }

        const [, drop] = useDrop({
            accept: CARD,
            hover(item, monitor) {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }
                const hoverBoundingRect = ref.current?.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }
                moveCard(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });
        const [{ isDragging }, drag] = useDrag({
            item: { type: CARD, id: task.id, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        const opacity = isDragging ? 0 : 1;
        drag(drop(ref));
        return <div ref={ref} className="tr__template-task-item__wrapper" style={{ opacity }}>
            <Collapse
                className="tr__template-task-item mtx3"
                title={task.name}
                initialOpen={true}
                disableHeaderClick={true}
                renderHeader={setOpen => (
                    <>
                        <span onClick={setOpen}>{task.name}</span>
                        <div className="toggle-btn d-flex align-items-center">
                            <i className="icon-chevron-down" onClick={setOpen} />
                            {isMyTemplate && (
                                <>
                                    <Button
                                        className="icon mlx2"
                                        icon="icon-arrow-up"
                                        type="secondary"
                                        onClick={() => { index > 0 && moveCard(index, index - 1) }}
                                    />
                                    <Button
                                        className="icon mlx1"
                                        icon="icon-arrow-down"
                                        type="secondary"
                                        onClick={() => { index < tasksLength - 1 && moveCard(index, index + 1) }}

                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
            >
                <>
                    {task.tasks.events && task.tasks.events.length > 0 && (
                        <div className='tr__template-task-item--table'>
                            <div className='table--header'>
                                <h4 className="h4 f-medium">Events</h4>
                            </div>
                            {task.tasks.events.map((event, eventIndex) => (
                                <div className="table--body d-flex table-body justify-content-between" key={`${index}-event-${eventIndex}`}>
                                    <div className="column name">
                                        <h4 className="h4 f-medium">
                                            <i className="icon-check" />
                                            <span>{event.name}</span>
                                        </h4>
                                    </div>
                                    <div className="column partner">
                                        <div className="partners">
                                            {event.roles && event.roles.length > 0 && (
                                                <>
                                                    {event.roles.slice(0, limit).map((role, roleIndex) => (
                                                        <Tooltip
                                                            content={role.replace('_', ' ').toLowerCase()}
                                                            key={roleIndex}
                                                        >
                                                            <i className={get(find(roles,['id',role]),'icon')} />
                                                        </Tooltip>
                                                    ))}

                                                    {limit < event.roles.length && (
                                                        <Tooltip
                                                            renderContent={() => (
                                                                <div className="tags d-flex flex-column">
                                                                    {event.roles.slice(limit, event.roles.length).map((partner, partnerIndex) => (
                                                                        <span key={partnerIndex}>{partner.replace('_', ' ').toLowerCase()}</span>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        >
                                                            <span className="f-medium more">+{event.roles.length - limit}</span>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="column assignee">
                                        <Collaborators
                                            members={getCollaborators(events)}
                                            skip={3}
                                            onAdd={() => setAssignCollaborators(true)}
                                        />
                                    </div>
                                    <div className="column date d-flex align-items-center justify-content-between">
                                        {(event && event.dueDate.eventBasedDate.typeOfEvent !== null) ? (
                                            <p className="tr__badge mlx2">{`${event.dueDate.eventBasedDate.typeOfEvent} ${(event.dueDate.eventBasedDate.adjustDays > 0) ? "+" + event.dueDate.eventBasedDate.adjustDays : event.dueDate.eventBasedDate.adjustDays} `}</p>
                                        ) : (<p></p>)}
                                        <p className="tr__link info" onClick={() => {
                                            setAddTask(true);
                                            setIsTemplate(true);
                                        }}>
                                            <i className="icon-pencil" />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {task.tasks.documents && task.tasks.documents.length > 0 && (
                        <div className='tr__template-task-item--table mtx3'>
                            <div className='table--header'>
                                <h4 className="h4 f-medium">Documents</h4>
                            </div>
                            {task.tasks.documents.map((doc, docIndex) => (
                                <div className="table--body d-flex table-body justify-content-between" key={docIndex}>
                                    <div className="column name">
                                        <h4 className="h4 f-medium">
                                            <i className="icon-check" />
                                            <span>{doc.name}</span>
                                        </h4>
                                    </div>
                                    <div className="column partner">
                                        <div className="partners">
                                            {doc.roles && doc.roles.length > 0 && (
                                                <>
                                                    {doc.roles.slice(0, limit).map((role, roleIndex) => (
                                                        <Tooltip
                                                            content={role.replace('_', ' ').toLowerCase()}
                                                            key={roleIndex}
                                                        >
                                                            <i className={get(find(roles,['id',role]),'icon')} />
                                                        </Tooltip>
                                                    ))}

                                                    {limit < doc.roles.length && (
                                                        <Tooltip
                                                            renderContent={() => (
                                                                <div className="tags d-flex flex-column">
                                                                    {doc.roles.slice(limit, doc.roles.length).map((partner, partnerIndex) => (
                                                                        <span key={partnerIndex}>{partner.replace('_', ' ').toLowerCase()}</span>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        >
                                                            <span className="f-medium">+{doc.roles.length - limit}</span>
                                                        </Tooltip>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="column assignee">
                                        <Collaborators
                                            members={getCollaborators(documents)}
                                            skip={3}
                                            onAdd={() => setAssignCollaborators(true)}
                                        />
                                    </div>
                                    <div className="column date d-flex align-items-center justify-content-between">
                                        {doc.dueDate.eventBasedDate && doc.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                            <p className="tr__badge mlx2">{`${doc.dueDate.eventBasedDate.typeOfEvent} ${(doc.dueDate.eventBasedDate.adjustDays > 0) ? "+" + doc.dueDate.eventBasedDate.adjustDays : doc.dueDate.eventBasedDate.adjustDays} `}</p>
                                        ) : (<p></p>)}
                                        
                                        <p className="tr__link info" onClick={() => {
                                            setAddTask(true);
                                            setIsTemplate(true);
                                        }}>
                                            <i className="icon-pencil" />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            </Collapse>
            <div className="tr__template-task-item-btns d-flex align-items-center">
                <Button
                    className="outline icon mrx2"
                    icon="icon-plus"
                    type="primary"
                    onClick={() => {
                        setAddTask(true)
                    }}
                >
                    Add New Task
            </Button>
                <Button
                    className="outline icon"
                    icon="icon-pencil"
                    type="secondary"
                    onClick={() => setEditSection(true)}
                >
                    Edit
            </Button>
            </div>
        </div>
    }


export const TemplateTaskItem = ({ sections, partnerLimit, isMyTemplate = true }) => {

    const [tasks, setTasks] = useState([]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragCard = tasks[dragIndex];
        let newTasks = cloneDeep(tasks)
        const temp = cloneDeep(dragCard)
        newTasks[dragIndex] = cloneDeep(newTasks[hoverIndex])
        newTasks[hoverIndex] = temp
        
        setTasks(newTasks)
    }, [tasks]);

    useEffect(()=>{
        setTasks(cloneDeep(sections))
    },[sections])

    return tasks.length > 0 ? tasks.map((task, taskIndex) => {
        return (
            <Card task={task} index={taskIndex} key={task.id} moveCard={moveCard} partnerLimit={partnerLimit} isMyTemplate={isMyTemplate} tasksLength={tasks.length} />
        )
    }) : '';
};

TemplateTaskItem.propTypes = {
    isMyTemplate: PropTypes.bool,
    partnerLimit: PropTypes.number,
    sections: PropTypes.array
};