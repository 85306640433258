import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import moment from 'moment';

export const TabBookingDetails = ({ shippingDetails = {} }) => {
    return <InfoBlock
        className="tr__details-box"
        title="Shipping details"
    >
        <InfoRow>
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Shipment mode"
                        value={shippingDetails.shipmentMode ? shippingDetails.shipmentMode : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Shipment type"
                        value={shippingDetails.shipmentType ? shippingDetails.shipmentType : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Booking date"
                        value={shippingDetails.bookingDate ?  moment(shippingDetails.bookingDate).format('DD MMM YYYY') : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Shipper"
                        value={shippingDetails.shipper ? shippingDetails.shipper : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="ETA"
                        value={shippingDetails.eta ? shippingDetails.eta : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="ETD"
                        value={shippingDetails.etd ? shippingDetails.etd : ''}
                    />
                </div>
            </div>
        </InfoRow>
    </InfoBlock>
};

TabBookingDetails.propTypes = {
    shippingDetails: PropTypes.object,
};
