import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    Input,
    Collapse,
    EditShipmentInfoDropdown,
    EditShipmentInfoInput
} from 'components';
import { TrackShipmentContext } from 'contexts';
import { DESTINATION, LOCODE_PAGE_SIZE, ORIGIN } from 'actions';

export const AddressDetails = ({ 
    register = () => {}, 
    routes, 
    editShipmentInfo,
    setShipmentDetails = () => {}
}) => {
    const { origins, setOrigins, destinations, setDestinations, firstLocode, doGetLocodes } = useContext(TrackShipmentContext);

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [shipperAddress, setShipperAddress] = useState('');
    const [consigneeAddress, setConsigneeAddress] = useState('');
    const [originalAddresses, setOriginalAddresses] = useState();
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [newPrepol, setNewPrepol] = useState();
    const [newPostpod, setNewPostpod] = useState();
    const [originalPrePol, setOriginalPrePol] = useState();
    const [originalPostPod, setOriginalPostPod] = useState();

    /*eslint-disable */
    useEffect(() => {
        if(routes){
            const { prepol, postpod } = routes;
            if (routes.shipperPickupAdd) {
                const {shipperPickupAdd, consigneeDeliveryAdd } = routes
                setOriginalAddresses({...routes});
                setShipperAddress(shipperPickupAdd);
                setConsigneeAddress(consigneeDeliveryAdd);
            }

            setNewPrepol(getLocationValue(prepol));
            setNewPostpod(getLocationValue(postpod));
            setOriginalPrePol(getLocationValue(prepol));
            setOriginalPostPod(getLocationValue(postpod));
        }
        const scripts = document.scripts;
        const MAPBOX_GEOCODER_URL = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.min.js';
        let isInjected = false;

        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src === MAPBOX_GEOCODER_URL) {
                isInjected = true;
                break;
            }
        }

        if (!isInjected) {
            let mapGeocoderScriptElement = document.createElement('script');
            mapGeocoderScriptElement.src = MAPBOX_GEOCODER_URL;

            document.head.appendChild(mapGeocoderScriptElement);
        } else {
            setScriptLoaded(true);
        }

        if (firstLocode && firstLocode.length > 0) {
            setOrigins(firstLocode);
            setDestinations(firstLocode);
        }
    }, [])

    useEffect(() => {
        if (window.MapboxGeocoder) setScriptLoaded(true);
    }, [window.MapboxGeocoder])

    useEffect(() => {
        if (scriptLoaded) {
            addGeocoder('sa', 'shipperAddressId', 'Shipper Pick-up Address', setShipperAddress);
            addGeocoder('ca', 'consigneeAddressId', 'Consignee Delivery Address', setConsigneeAddress);
        }
    }, [scriptLoaded])
    /*eslint-enable */

    const getLocationValue = data => {
        if (!data || (data && !data.location)) return '';
        const { name, country, locode } = data.location;
        return name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
    }

    const addGeocoder = (key, selector, placeholder, setPlace) => {
        window[key] = null;
        if (window.MapboxGeocoder) {
            window[key] = new window.MapboxGeocoder({
                accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
                types: 'country,region,place,postcode,locality,neighborhood',
                placeholder
            });

            window[key].addTo(`#${selector}`);

            window[key].on('result', result => {
                setPlace(result.result.place_name);
            })
        }
    }

    const handleSearch = (value, key, setValue) => {
        setValue(value);
        if (window[key]) {
            if (value) window[key].setInput(value)._geocode(value);
            else window[key].setInput(value);
        }
    }

    const onSearchOriginLocode = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Address" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-6 color-icon">
                                    <EditShipmentInfoDropdown
                                        label="PRE POL"
                                        name="prePol"
                                        className="mtx2"
                                        placeholder="PRE POL Address"
                                        icon="icon-chevron-down"
                                        iconPosition="right"
                                        mode="input"
                                        refs={register()}
                                        options={origins && origins.length > 0 ? origins.map(locode => {
                                            return {
                                                key: locode.locode,
                                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                                item: locode
                                            }
                                        }) : []}
                                        dropdownPosition="center"
                                        onInputChange={onSearchOriginLocode}
                                        searchLoading={originLoading}
                                        onChange={option => {
                                            setNewPrepol(option ? option.key : '');
                                            setShipmentDetails(old => {
                                                const { item } = option;
                                                return {
                                                    ...old,
                                                    routes: {
                                                        ...old.routes,
                                                        prepol: {
                                                            ...old.routes.prepol,
                                                            location: {
                                                                name: item.city,
                                                                country: item.country,
                                                                locode: item.locode
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                        }}
                                        defaultValue={routes && routes.prepol && routes.prepol.location ? {
                                            key: routes.prepol.location.locode,
                                            value: `${routes.prepol.location.name}, ${routes.prepol.location.country} (${routes.prepol.location.locode})`,
                                            item: routes.prepol.location,
                                        } : null}
                                        originalvalue={originalPrePol}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newPrepol}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        className="mtx2"
                                        name="pol"
                                        label="POL"
                                        placeholder="POL Address"
                                        defaultValue={routes && routes.pol && routes.pol.location
                                            ? `${routes.pol.location.name}, ${routes.pol.location.country} (${routes.pol.location.locode})`
                                            : routes && routes.pol 
                                            ? getLocationValue(routes.pol) 
                                            : ''
                                        }
                                        viewOnly={true}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        label="POD"
                                        name="pod"
                                        className="mtx2"
                                        placeholder="POD Address"
                                        defaultValue={routes && routes.pod && routes.pod.location
                                            ? `${routes.pod.location.name}, ${routes.pod.location.country} (${routes.pod.location.locode})`
                                            : routes && routes.pod 
                                            ? getLocationValue(routes.pod) 
                                            : ''
                                        }
                                        viewOnly={true}
                                    />
                                </div>
                                <div className="col-12 col-sm-6 color-icon">
                                    <EditShipmentInfoDropdown
                                        className="mtx2"
                                        name="postPod"
                                        label="POST POD"
                                        placeholder="POST POD Address"
                                        icon="icon-chevron-down"
                                        iconPosition="right"
                                        mode="input"
                                        refs={register()}
                                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                                            return {
                                                key: locode.locode,
                                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                                item: locode
                                            }
                                        }) : []}
                                        dropdownPosition="center"
                                        onInputChange={onSearchDestLocode}
                                        searchLoading={destinationLoading}
                                        onChange={option => {
                                            setNewPostpod(option ? option.key : '');
                                            setShipmentDetails(old => {
                                                const { item } = option;
                                                return {
                                                    ...old,
                                                    routes: {
                                                        ...old.routes,
                                                        postpod: {
                                                            ...old.routes.postpod,
                                                            location: {
                                                                name: item.city,
                                                                country: item.country,
                                                                locode: item.locode
                                                            }
                                                        }
                                                    }
                                                }
                                            })
                                        }}
                                        defaultValue={routes && routes.postpod && routes.postpod.location ? {
                                            key: routes.postpod.location.locode,
                                            value: `${routes.postpod.location.name}, ${routes.postpod.location.country} (${routes.postpod.location.locode})`,
                                            item: routes.postpod.location,
                                        } : null}
                                        originalvalue={originalPostPod}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newPostpod}
                                    />
                                </div>
                                <div className="col-12 col-sm-12 color-icon">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="shipperAddress"
                                        label="Shipper Pick-up Address"
                                        placeholder="Search by city or country name"
                                        errorMessage="Please Shipper Pick-up Address"
                                        iconPosition="right"
                                        autoComplete="new-password"
                                        value={shipperAddress}
                                        refs={register()}
                                        onChange={e => handleSearch(e.target.value, 'sa', setShipperAddress)}
                                        icon="icon-location"
                                        originalvalue={originalAddresses ? originalAddresses.shipperPickupAdd : ''}
                                        newValue={shipperAddress}
                                        tooltipOldlvalue={editShipmentInfo}
                                    />
                                    <div id="shipperAddressId" className={shipperAddress ? '' : 'no-result'} />
                                </div>
                                <div className="col-12 col-sm-12 color-icon">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="consigneeDeliveryAdress"
                                        label="Consignee Delivery Address"
                                        placeholder="Search by city or country name"
                                        iconPosition="right"
                                        autoComplete="new-password"
                                        value={consigneeAddress}
                                        refs={register()}
                                        onChange={e => handleSearch(e.target.value, 'ca', setConsigneeAddress)}
                                        icon="icon-location"
                                        originalvalue={originalAddresses ? originalAddresses.consigneeDeliveryAdd : ''}
                                        newValue={consigneeAddress}
                                        tooltipOldlvalue={editShipmentInfo}
                                    />
                                    <div id="consigneeAddressId" className={consigneeAddress ? '' : 'no-result'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

AddressDetails.propTypes = {
    error: PropTypes.bool,
    routes: PropTypes.object,
    editShipmentInfo: PropTypes.bool,
    errors: PropTypes.any,
    getValues: PropTypes.func,
    register: PropTypes.func
};
