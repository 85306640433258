import React, { useState, useContext } from 'react';
import {useForm} from "react-hook-form";
import PropTypes from 'prop-types';

import './index.scss';
import {
    Modal,
    CheckboxGroup,
    Dropdown,
    Datepicker,
    Button,
    MultiSelect
} from 'components';
import {
    taskRoles,
    filterEventOptions,
    shipmentTypes,
} from 'utils';
import { ActiveShipmentsContext } from 'contexts';

export const ShipmentFilter = ({ open = false, onCancel }) => {
    const { handleSubmit, register } = useForm();

    const { doSetFilter, carriers, needAttentionCarriers, ports, bookingNos, blNumbers, handlerClearFilterShipment } = useContext(ActiveShipmentsContext);

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    const [selectedBookingNos, setSelectedBookingNos] = useState([]);
    const [selectedblNumbers, setSelectedBlNumber] = useState([]);
    const [events, setEvents] = useState([{
        id: 1,
        dates: null
    }]);

    const handleAddEvent = () => {
        setEvents(oldEvents => [...oldEvents, {
            id: null,
            dates: null
        }]);
    };

    // const filteredArray = (options, selectedValues) => {
    //     return options.filter(value => !selectedValues.includes(value));
    // };

    const handleSubmitFilter = data => {
        doSetFilter(data);
    }

    return (
        <Modal
            open={open}
            className="tr__shipment-filter"
            onCancel={onCancel}
            title='Filters'
            type="panel"
        >
            <form
                onSubmit={handleSubmit(handleSubmitFilter)}
            >
                <div className="tr__shipment-filter--group mbx2 pbx1">
                    <CheckboxGroup
                        label="Your Role"
                        name="shipmentRole"
                        className="big-label"
                        vertical
                        items={taskRoles}
                        values={selectedRoles}
                        onChange={roles => setSelectedRoles(roles)}
                        buttonMode={true}
                        refs={register}
                    />
                </div>
                <div className="tr__shipment-filter--group route mbx3 pbx3">
                    <span className="route-icon">
                        <i className="icon-location" />
                        <i className="icon-location" />
                    </span>
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="origin"
                        label="Port"
                        horizontalLabel="Origin:"
                        options={ports.map(port => {
                            return {
                                key: port.locode,
                                value: `${port.name}, ${port.country} (${port.locode})`,
                            };
                        })}
                        placeholder="Search by country or port"
                        dropdownPosition="center"
                        refs={register}
                    />
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destination"
                        horizontalLabel="Destination:"
                        options={ports.map(port => {
                            return {
                                key: port.locode,
                                value: `${port.name}, ${port.country} (${port.locode})`,
                            };
                        })}
                        placeholder="Search by country or port"
                        dropdownPosition="center"
                        refs={register}
                    />
                </div>
                {events.map((event, eventIndex) => (
                    <div className="tr__shipment-filter--group mbx3 pbx3" key={eventIndex}>
                        <Dropdown
                            className="select mbx3"
                            icon="icon-chevron-down"
                            iconPosition="right"
                            mode="select"
                            name="eventType"
                            label="Event"
                            value={filterEventOptions.find(e => e.key === event.id)}
                            options={filterEventOptions}
                            placeholder="Select event"
                            dropdownPosition="center"
                            refs={register}
                        />
                        <Datepicker
                            name="vesselShedule"
                            label="Date Range"
                            placeholder="Filter by date range"
                            range={true}
                            refs={register}
                        />
                        {eventIndex === events.length - 1 && (
                            <p className="tr__link mtx3" onClick={handleAddEvent}>Select more event</p>
                        )}
                    </div>
                ))}
                <div className="tr__shipment-filter--group mbx3 pbx3">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="shipmentType"
                        label="Type"
                        options={shipmentTypes.map(item => {
                            return {
                                key: item.id,
                                value: item.name,
                            };
                        })}
                        placeholder="Select Shipment Type"
                        dropdownPosition="center"
                        refs={register}
                    />
                </div>
                <div className="tr__shipment-filter--group last mbx4">
                    <MultiSelect
                        className="mbx4"
                        options={carriers.concat(needAttentionCarriers).map(item => {
                            return {
                                id: item,
                                description: item,
                            };
                        })}
                        name="carrier"
                        label="Carrier"
                        value={selectedCarriers}
                        icon="icon-chevron-down"
                        iconPosition="right"
                        isSelectable={true}
                        placeholder='Search Carrier'
                        onChange={items => setSelectedCarriers(items)}
                        register={register}
                    />
                    <MultiSelect
                        className="mbx4"
                        options={bookingNos.map(item => {
                            return {
                                id: item,
                                description: `No.: ${item}`,
                            };
                        })}
                        name="bookingNo"
                        label="Booking No."
                        value={selectedBookingNos}
                        icon="icon-chevron-down"
                        iconPosition="right"
                        isSelectable={true}
                        placeholder='Ex: 8083720028'
                        onChange={items => setSelectedBookingNos(items)}
                        register={register}
                    />
                    <MultiSelect
                        className="mbx4"
                        options={blNumbers.map(item => {
                            return {
                                id: item,
                                description: `No.: ${item}`,
                            };
                        })}
                        name="blNumber"
                        label="BL Number"
                        value={selectedblNumbers}
                        icon="icon-chevron-down"
                        iconPosition="right"
                        isSelectable={true}
                        placeholder='Ex: 8083720028'
                        onChange={items => setSelectedBlNumber(items)}
                        register={register}
                    />
                </div>
                <div className="tr__shipment-filter--btns d-flex align-items-center">
                    <Button
                        className="icon outline"
                        icon="icon-reload"
                        type="secondary"
                        onClick={handlerClearFilterShipment}
                    >
                        Clear
                    </Button>
                    <Button
                        type="primary"
                        isSubmitBtn={true}
                    >
                        Apply
                    </Button>
                </div>
            </form>
        </Modal>
    )
};

ShipmentFilter.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
};
