import React, { useContext, useState } from 'react';

import './index.scss';
import {
    Button,
    TabBookingDetails,
    TabAddress,
    TabVesselRouteInfo,
    ContainerTracking,
    AISTracking,
    Invoice,
    EditShipmentInfo,
    CutOff,
    ContactDetails,
    TranshipmentInfo,
    ReuploadDocument
} from 'components';
import { HomeContext, ModalContext} from 'contexts';
import _ from 'lodash';

export const ShipmentInfo = ({shipmentInfo}) => {
    const { reuploadDoc, setReuploadDoc, setViewExtractedInfo } = useContext(ModalContext);
    const { isArchive, setFileId } = useContext(HomeContext);
    const [editShipmentInfo, setEditShipmentInfo] = useState(false);
    // const shipmentId = '5fe2fde4e07d1a379c729286';

    /*eslint-disable */
    // useEffect(() => {
    //     doGetLocodes({
    //         limit: LOCODE_PAGE_SIZE
    //     });
    //     doGetShipmentInfo(shipmentId);
    // }, [])
    /*eslint-disable */

    const handleViewExtracted = () => {
        setReuploadDoc(false);
        setViewExtractedInfo(true);
        setFileId('5ff56fe5a9af000009f99f72');
    }
    
    return editShipmentInfo ? (
        <EditShipmentInfo
            goBack={() => setEditShipmentInfo(false)}
        />
    ) : (
        <div className="tr__shipment-info">
            <div className="tr__shipment-info--content">
                <TabBookingDetails shippingDetails={shipmentInfo.shippingDetails} />
                <TabAddress addresses={shipmentInfo.addresses} />
                <TabVesselRouteInfo bookingDetails={shipmentInfo.bookingDetails || {}} />
                <ContainerTracking containerTracking={shipmentInfo.containerTracking || {}} />
                <AISTracking aisTracking={shipmentInfo.aisTracking || []} />
                <Invoice invoices={shipmentInfo.invoices} />
                <ContactDetails contactDetails={shipmentInfo.contactDetail || {}}/>
                <TranshipmentInfo transhipmentInfo={shipmentInfo.transhipmentInfo || []} />
                <CutOff cutOff={shipmentInfo.cutOff || {}} />
            </div>
            {!isArchive && !_.isEmpty(shipmentInfo) && (
                <div className="tr__shipment-info--footer d-flex align-items-center justify-content-between">
                      <Button
                            className="icon outline mlx2"
                            icon="icon-pencil"
                            type="secondary"
                            onClick={() => setEditShipmentInfo(true)}
                        >
                            Edit
                        </Button>
                    <div className="btns d-flex align-items-center">
                        <Button
                            className="icon outline"
                            icon="icon-upload"
                            type="secondary"
                            onClick={() => setReuploadDoc(true)}
                        >
                            Reupload
                        </Button>
                    </div>
                </div>
            )}
            <ReuploadDocument
                open={reuploadDoc}
                onCancel={() => setReuploadDoc(false)}
                onSubmit={handleViewExtracted}
            />
        </div>
    )
};