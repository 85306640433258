import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Link } from 'react-router-dom';

export const TemplateHeader = ({ className = '', backUrl = '/', name, description }) => {
    return (
        <div className={`tr__template-header d-flex align-items-center ${className}`}>
            <div className="d-flex align-items-center">
                <Link to={backUrl} className="tr__link tag">
                    <i className="icon-chevron-left" />
                    <span>Back</span>
                </Link>
                <div className="mlx2">
                    <h2 className="h2 f-medium mbx1">{name}</h2>
                    <h6 className="text-grey f-medium">{description}</h6>
                </div>
            </div>
        </div>
    )
};

TemplateHeader.propTypes = {
    className: PropTypes.string
};