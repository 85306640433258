import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import {
    Statuses
} from 'components';

export const ShipmentSteps = ({ active = false, type = 'big', align = 'left', step }) => {
    return step ? (
        <div className={`
            tr__active-shipment-step
            d-flex
            flex-column
            ${active ? 'active' : ''}
            ${!step.title ? 'icon-only' : ''}
            ${align}
            ${type}
        `}>
            <i className={step.icon} />
            {step.title && (
                <h6 className="h6 f-medium mtx2">
                    {step.title}
                </h6>
            )}
            {step.label && step.date && (
                <p className="sub mtx1">
                    <span>{step.label}</span>
                    <span className="f-medium">{moment(step.date).format(`DD MMM YYYY${step.showHours ? ' | hh:mm A' : ''}`)}</span>
                </p>
            )}
            <Statuses
                statuses={step.statuses}
                inputUnit='percentage'
            />
        </div>
    ) : ''
};

ShipmentSteps.propTypes = {
    active: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'right']),
    type: PropTypes.oneOf(['big', 'small']),
    step: PropTypes.object
};
