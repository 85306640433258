import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import { 
    CollaboratorsContext, 
    HomeContext, 
    MemberContext, 
    TeamContext 
} from 'contexts';

import {
    MultiSelect,
    Button,
    ItemCard
} from 'components';
import {
    CONSIGNEE,
    EXPORT_CUSTOMS,
    EXPORT_LOGISTICS,
    FREIGHT,
    IMPORT_CUSTOMS,
    IMPORT_LOGISTICS,
    MARK_BOOKING_REQUEST,
    MARK_NEW,
    MARK_TRACK_SHIPMENT,
    MEMBER,
    SHIPPER,
    TEAM
} from 'actions';
import avatar from 'assets/images/avatar.png';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

export const AddCollaborator = ({ 
    goBack,
 }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [shipper, setShipper] = useState([])
    const [consignee, setConsignee] = useState([]);
    const [exportCustom, setExportCustom] = useState([]);
    const [freightPartner, setFreightPartner] = useState([]);
    const [exportLogistics, setExportLogistics] = useState([]);
    const [importCustom, setImportCustom] = useState([]);
    const [importLogistics, setImportLogistics] = useState([]);
    const [selectedOptionShipper, setSelectedOptionShipper] = useState([]);
    const [selectedOptionConsignee, setSelectedOptionConsignee] = useState([]);
    const [selectedOptionExCustom, setSelectedOptionExCustom] = useState([]);
    const [selectedOptionFreight, setSelectedOptionFreight] = useState([]);
    const [selectedOptionExLogistic, setSelectedOptionExLogistic] = useState([]);
    const [selectedOptionImCustom, setSelectedOptionImCustom] = useState([]);
    const [selectedOptionImLogistic, setSelectedOptionImLogistic] = useState([]);
    const [dropdownList, setDropdownList] = useState([]);
    const [memberLoading, setMemberLoading] = useState(Array(7).fill(false));
    const [teamLoading, setTeamLoading] = useState(Array(7).fill(false));
    const { members, doGetMembers } = useContext(MemberContext);
    const { teams, doGetTeams } = useContext(TeamContext);
    const { collaborators, doCreateCollaborators, doGetCollaborators } = useContext(CollaboratorsContext);
    const {
        markAsShipmentType,
        markAsShipmentTemplate,
        trackerTemplate,
        setNotificationMessage
    } = useContext(HomeContext);
    const { register } = useForm();
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    const MEMBER_U = MEMBER.toUpperCase();
    const TEAM_U = TEAM.toUpperCase();
    const newArray = selectedValues.map(item => {
        return item.partners;
    })
    const getMembersTeams = (index, value) => {
        setMemberLoading(old => [...old.map((item, itemIndex) => itemIndex === index)]);
        setTeamLoading(old => [...old.map((item, itemIndex) => itemIndex === index)]);

        doGetMembers({
            term: value,
            isSearching: true
        }, () => {
            setMemberLoading(Array(7).fill(false));
        }, () => {
            setMemberLoading(Array(7).fill(false));
        }, false);

        doGetTeams({
            term: value,
            isSearching: true
        }, () => {
            setTeamLoading(Array(7).fill(false));
        }, () => {
            setTeamLoading(Array(7).fill(false));
        }, false);
    }

    const handleAddPartner = (items, roleType) => {
        const partner = items.map( item => {
            return {
                id: item.id,
                type: item.type.toUpperCase(),
            }
        });
        if (selectedValues.length > 0) {
            let isExisted = false;
            selectedValues.map((e) => {
                if (e.roleType === roleType) {
                    e.partners = e.partners.concat(partner);
                    isExisted = true;
                } 
                return e;
            });
            if (!isExisted) {
                let roleShipment  = { roleType: roleType, partners : partner };
                setSelectedValues(selectedValues.concat(roleShipment));
            }
        } else {
            let roleShipment  = { roleType: roleType, partners : partner };
            setSelectedValues(oldValue => [...oldValue, roleShipment]);
        }
    }

    const handleRemove = (item, roleType) => {
        selectedValues.map((e, index) => {
            if (e.roleType === roleType) {
                e.partners = e.partners.filter(o => o.id !== item.id);   
            }
            return e;
        });
        setSelectedValues(selectedValues);
    }

    const mappingPartners = source => {
        return source.map(m => {
            return {
                id: m.id,
                icon: m.type === TEAM_U ? 'icon-users' : (m.avatar || avatar),
                title: m.username,
                description: m.type === TEAM_U ? m.organization.name : m.username,
                type: m.type
            }
        })
    }
  
    const handleAddCollaborator = () => {
        const payload = {
            rolesInShipment : selectedValues
        }
        doCreateCollaborators(shipmentId, payload, () => {
            setNotificationMessage(`
            <p> Collaborator has been added successfully!</p>
            `);
            doGetCollaborators(shipmentId, {

            })
            goBack();
           
        })
    }

    const filteredOptions = (options, selectedOptions) => {
        const afterSelectedOptions = options.filter(item => !selectedOptions.some(i => i.id === item._id));
        return afterSelectedOptions;
    }

    /*eslint-disable */
    useEffect(() => {
        doGetMembers({
            isSearching: true
        });
        doGetTeams({
            isSearching: true
        })
    }, [])

    useEffect(() => {
        selectedValues.forEach(value => {
            if (value.roleType === SHIPPER && !shipper.length) setShipper(value.partners);
            if (value.roleType === CONSIGNEE && !consignee.length) setConsignee(value.partners);
            if (value.roleType === EXPORT_CUSTOMS && !exportCustom.length) setExportCustom(value.partners);
            if (value.roleType === FREIGHT && !freightPartner.length) setFreightPartner(value.partners);
            if (value.roleType === EXPORT_LOGISTICS && !exportLogistics.length) setExportLogistics(value.partners);
            if (value.roleType === IMPORT_CUSTOMS && !importCustom.length) setImportCustom(value.partners);
            if (value.roleType === IMPORT_LOGISTICS && !importLogistics.length) setImportLogistics(value.partners);
        });
    }, [selectedValues])

    useEffect(() => {
        setDropdownList([...(members || []).map(mem => {
            return {
                ...mem,
                type: MEMBER_U
            }
        }), ...(teams || []).map(team => {
            return {
                ...team,
                type: TEAM_U
            }
        })].sort((a, b) => a.name.localeCompare(b.name)));
    }, [members, teams]);
    
    useEffect(() => {
        if (markAsShipmentType !== MARK_NEW) {
            switch(markAsShipmentType) {
                case MARK_TRACK_SHIPMENT:
                    if (trackerTemplate) {
                        
                    }
                    break;
                case MARK_BOOKING_REQUEST:
                    if (markAsShipmentTemplate) {
                        const { memberInfo, freight, creatorRole, base } = markAsShipmentTemplate;
                        let freights = [];
                        let creators = [];
                        let collaborators = [];

                        memberInfo.forEach(m => {
                            if (m.id === freight.freightId) freights.push({
                                ...m,
                                type: freight.freightType
                            });
                            else if (m.id === base.createdBy) creators.push({
                                ...m,
                                type: MEMBER_U
                            });
                            else collaborators.push(m);
                        });

                        setFreightPartner(old => [...old, ...mappingPartners(freights)]);
                        handleAddPartner(mappingPartners(freights), FREIGHT);
                        switch(creatorRole) {
                            case CONSIGNEE:
                                setConsignee(old => [...old, ...mappingPartners(creators)]);
                                handleAddPartner(mappingPartners(creators), CONSIGNEE);
                                break;
                            case SHIPPER:
                                setShipper(old => [...old, ...mappingPartners(creators)]);
                                handleAddPartner(mappingPartners(creators), SHIPPER);
                                break;
                        };
                        setConsignee(old => [...old, ...mappingPartners(collaborators)]);
                        handleAddPartner(mappingPartners(collaborators), CONSIGNEE);
                        
                    }
                    break;
                default:
                    break;
            }
        }
    }, [markAsShipmentType, markAsShipmentTemplate, trackerTemplate])
    /*eslint-enable */

    return (
        <div
            className="tr__add-collaborator"
        >
            <div className="tr__add-collaborator--title d-flex align-items-center col-12">
                <p className="tr__link tag mrx2" onClick={goBack}>
                    <i className="icon-chevron-left" />
                    <span>Back</span>
                </p>
                <h2 className="h2 f-medium">Add Collaborator To Shipment</h2>
            </div>
            <div className="tr__add-collaborator--selects mtx3 col-12 col-sm-6 offset-sm-3">
                <MultiSelect
                    className="mbx4"
                    value={consignee}
                    name="consignee"
                    label='Assign as a Consignee'
                    addButton={true}
                    options={filteredOptions(dropdownList.filter(option => !collaborators.some(collaborator => collaborator.roles.includes(CONSIGNEE) && option._id === collaborator._id)), selectedOptionConsignee).map(item => {
                        return {
                            id: item._id,
                            icon: item.type === MEMBER_U ? (item.avatar || avatar) : 'icon-users',
                            title: item.name,
                            description: item.type === MEMBER_U ? item.email : item.organization.name,
                            type: item.type
                        }
                    })}
                    addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(0, value)}
                    searchLoading={memberLoading[0] || teamLoading[0]}
                    labelIcon='icon-box'
                    placeholder='You can search by name, email, team name or can invite new people...'
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items, CONSIGNEE);
                        setConsignee(oldValue => [...oldValue, ...items]);
                        setSelectedOptionConsignee(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, CONSIGNEE );
                                        setConsignee(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionConsignee(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                 <MultiSelect
                    className="mbx4"
                    value={shipper}
                    name="shipper"
                    label='Assign as a Shipper'
                    addButton={true}
                    options={filteredOptions(dropdownList.filter(option => !collaborators.some(collaborator => collaborator.roles.includes(SHIPPER) && option._id === collaborator._id)), selectedOptionShipper).map(item => {
                        return {
                            id: item._id,
                            icon: item.type === MEMBER_U ? (item.avatar || avatar) : 'icon-users',
                            title: item.name,
                            description: item.type === MEMBER_U ? item.email : item.organization.name,
                            type: item.type
                        }
                    })}
                    addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(1, value)}
                    searchLoading={memberLoading[1] || teamLoading[1]}
                    labelIcon='icon-shipper'
                    placeholder='You can search by name, email, team name or can invite new people...'
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items, SHIPPER);
                        setShipper(oldValue => [...oldValue, ...items]);
                        setSelectedOptionShipper(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, SHIPPER);
                                        setShipper(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionShipper(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={filteredOptions(dropdownList.filter(option => !collaborators.some(collaborator => collaborator.roles.includes(EXPORT_CUSTOMS) && option._id === collaborator._id)), selectedOptionExCustom).map(item => {
                        return {
                            id: item._id,
                            icon: item.type === MEMBER_U ? (item.avatar || avatar) : 'icon-users',
                            title: item.name,
                            description: item.type === MEMBER_U ? item.email : item.organization.name,
                            type: item.type
                        }
                    })}
                    value={exportCustom}
                    name="exportCustom"
                    label='Assign as an Export Customs Broker'
                    addButton={true}
                    addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(2, value)}
                    searchLoading={memberLoading[2] || teamLoading[2]}
                    labelIcon='icon-export'
                    placeholder='You can search by name, email, team name or can invite new people...'
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, EXPORT_CUSTOMS);
                        setExportCustom(oldValue => [...oldValue, ...items]);
                        setSelectedOptionExCustom(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, EXPORT_CUSTOMS );
                                        setExportCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionExCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={filteredOptions(dropdownList.filter(option => !collaborators.some(collaborator => collaborator.roles.includes(FREIGHT) && option._id === collaborator._id)), selectedOptionFreight).map(item => {
                        return {
                            id: item._id,
                            icon: item.type === MEMBER_U ? (item.avatar || avatar) : 'icon-users',
                            title: item.name,
                            description: item.type === MEMBER_U ? item.email : item.organization.name,
                            type: item.type
                        }
                    })}
                    value={freightPartner}
                    addButton={true}
                    addButtonLabel="Add"
                    name="freightPartner"
                    label='Assign as an Freight Partner'
                    onInputChange={value => getMembersTeams(3, value)}
                    searchLoading={memberLoading[3] || teamLoading[3]}
                    labelIcon='icon-truck'
                    placeholder='You can search by name, email, team name or can invite new people...'
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, FREIGHT);
                        setFreightPartner(oldValue => [...oldValue, ...items]);
                        setSelectedOptionFreight(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    onRemove={item => handleRemove(item)}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, FREIGHT);
                                        setFreightPartner(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionFreight(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={filteredOptions(dropdownList.filter(option => !collaborators.some(collaborator => collaborator.roles.includes(EXPORT_LOGISTICS) && option._id === collaborator._id)), selectedOptionExLogistic).map(item => {
                        return {
                            id: item._id,
                            icon: item.type === MEMBER_U ? (item.avatar || avatar) : 'icon-users',
                            title: item.name,
                            description: item.type === MEMBER_U ? item.email : item.organization.name,
                            type: item.type
                        }
                    })}
                    value={exportLogistics}
                    addButton={true}
                    addButtonLabel="Add"
                    name="exportLogistic"
                    label='Assign as an Export Logistics'
                    onInputChange={value => getMembersTeams(4, value)}
                    searchLoading={memberLoading[4] || teamLoading[4]}
                    labelIcon='icon-box-export'
                    placeholder='You can search by name, email, team name or can invite new people...'
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, EXPORT_LOGISTICS);
                        setExportLogistics(oldValue => [...oldValue, ...items]);
                        setSelectedOptionExLogistic(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={mems => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {mems.length > 0 && mems.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, EXPORT_LOGISTICS);
                                        setExportLogistics(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionExLogistic(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={filteredOptions(dropdownList.filter(option => !collaborators.some(collaborator => collaborator.roles.includes(IMPORT_CUSTOMS) && option._id === collaborator._id)), selectedOptionImCustom).map(item => {
                        return {
                            id: item._id,
                            icon: item.type === MEMBER_U ? (item.avatar || avatar) : 'icon-users',
                            title: item.name,
                            description: item.type === MEMBER_U ? item.email : item.organization.name,
                            type: item.type
                        }
                    })}
                    value={importCustom}
                    addButton={true}
                    addButtonLabel="Add"
                    name="importCustom"
                    onInputChange={value => getMembersTeams(5, value)}
                    searchLoading={memberLoading[5] || teamLoading[5]}
                    label='Assign as an Import Customs Broker'
                    labelIcon='icon-import'
                    placeholder='You can search by name, email, team name or can invite new people...'
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, IMPORT_CUSTOMS);
                        setImportCustom(oldValue => [...oldValue, ...items]);
                        setSelectedOptionImCustom(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, IMPORT_CUSTOMS);
                                        setImportCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionImCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={filteredOptions(dropdownList.filter(option => !collaborators.some(collaborator => collaborator.roles.includes(IMPORT_LOGISTICS) && option._id === collaborator._id)), selectedOptionImLogistic).map(item => {
                        return {
                            id: item._id,
                            icon: item.type === MEMBER_U ? (item.avatar || avatar) : 'icon-users',
                            title: item.name,
                            description: item.type === MEMBER_U ? item.email : item.organization.name,
                            type: item.type
                        }
                    })}
                    value={importLogistics}
                    addButton={true}
                    addButtonLabel="Add"
                    name="importLogistic"
                    label='Assign as an Import Logistics'
                    onInputChange={value => getMembersTeams(6, value)}
                    searchLoading={memberLoading[6] || teamLoading[6]}
                    labelIcon='icon-box-import'
                    placeholder='You can search by name, email, team name or can invite new people...'
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items, IMPORT_LOGISTICS);
                        setImportLogistics(oldValue => [...oldValue, ...items]);
                        setSelectedOptionImLogistic(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, IMPORT_LOGISTICS);
                                        setImportLogistics(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionImLogistic(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
            </div>
            <div className="tr__add-collaborator--footer d-flex align-items-center justify-content-between">
                <p className="f-medium">We will invite all new assignee on behalf of you</p>
                <Button
                    onClick={handleAddCollaborator}
                    disabled={newArray.some( e => e.length > 0) ? false : true}
                >
                    Add
                </Button>
            </div>
        </div>
    )
};

AddCollaborator.propTypes = {
    goBack: PropTypes.func
};