import React, { useContext } from 'react';
import { HomeContext, ModalContext } from 'contexts';
import {
    AddOrganization,
    AddTeam,
    AddMember,
    EditSection,
    AddTask,
    EditTask,
    AssignModal,
    MemberSettings,
    AllCollaborators,
    IssueModal,
    AssignMembersModal,
    DocumentModal,
    LinkTaskModal,
    Spinner,
    Notification,
    ConfirmStatusIssue,
    ConfirmStatusShipment,
    ViewExtractedInfo,
    ConfirmRemoveCollaborator,
    AddBRCollaborators,
    AddNewSection,
    CloneTemplate,
    DeleteSection,
    EditExtractedInfo,
    ConfirmSaveAsTemplate
} from 'components';

export const Modals = () => {
    const {
            addOrganization,
            addTeam,
            addMember,
            memberSettings,
            editSection,
            addSection,
            deleteSection,
            cloneTemplate,
            addTask,
            editTask,
            assignMembers,
            assignCollaborators,
            allCollaborators,
            addIssue,
            editIssue,
            isEditSection,
            editExtractedInfo,
            document,
            addLink,
            notification,
            confirmStatusIssue,
            confirmStatusShipment,
            viewExtractedInfo,
            removeCollaborator,
            addBRCollaborators,
            confirmSaveTemplate,
            setAddOrganization,
            setAddTeam,
            setAddMember,
            setMemberSettings,
            setEditSection,
            setAddSection,
            setDeleteSection,
            setCloneTemplate,
            setAddTask,
            setEditTask,
            setAssignMembers,
            setAssignCollaborators,
            setAllCollaborators,
            setAddIssue,
            setEditExtractedInfo,
            setDocument,
            setAddLink,
            setNotification,
            setConfirmStatusIssue,
            setConfirmStatusShipment,
            setViewExtractedInfo,
            setRemoveCollaborator,
            setAddBRCollaborators,
            setConfirmSaveTemplate,
        } = useContext(ModalContext);
        const {
            loading,
            notificationMessage,
            notificationDuration,
            notificationType,
            taskId,
            setIsTemplate,
            setTeamMember,
            setIsEditTask,
            setNotificationMessage
        } = useContext(HomeContext);
    
    return (
        <>
            <AddOrganization
                open={addOrganization}
                onCancel={() => setAddOrganization(false)}
            />
            <AddTeam
                open={addTeam}
                onCancel={() => setAddTeam(false)}
            />
            <AddMember
                open={addMember}
                onCancel={() => {
                    setAddMember(false);
                    setTeamMember(false);
                }}
            />
            <MemberSettings
                open={memberSettings}
                onCancel={() => setMemberSettings(false)}
            />
            <AddNewSection 
                open={addSection}
                onCancel={() => setAddSection(false)}/>
            <EditSection
                open={editSection}
                onCancel={() => setEditSection(false)}
                isEdit={isEditSection}
            />
             <DeleteSection
                open={deleteSection}
                onCancel={() => setDeleteSection(false)}
            />
            <CloneTemplate
                open={cloneTemplate}
                onCancel={() => setCloneTemplate(false)}
            />
            <AddTask
                open={addTask}
                onCancel={() => {
                    setAddTask(false);
                    setIsTemplate(false);
                    setIsEditTask(false);
                }}
            />
            <EditTask
                open={editTask}
                onCancel={() => setEditTask(false)}
            />

            <AssignModal
                open={assignCollaborators}
                onCancel={() => setAssignCollaborators(false)}
                taskId={taskId}
            />
            <AssignMembersModal
                open={assignMembers}
                onCancel={() => setAssignMembers(false)}
            />
            <AllCollaborators
                open={allCollaborators}
                onCancel={() => setAllCollaborators(false)}
            />
            <IssueModal
                open={addIssue}
                onCancel={() => setAddIssue(false)}
                isEdit={editIssue}
            />
            <EditExtractedInfo 
                open={editExtractedInfo}
                onCancel={() => setEditExtractedInfo(false)}
            />
            <DocumentModal
                open={document}
                onCancel={() => setDocument(false)}
            />
            <LinkTaskModal 
                open={addLink}
                onCancel={() => setAddLink(false)}
            />
            <Notification
                open={notification}
                type={notificationType}
                onCancel={() => {
                    setNotification(false);
                    setNotificationMessage('');
                }}
                notificationMessage={notificationMessage}
                duration={notificationDuration}
            />
            <ConfirmStatusIssue
                open={confirmStatusIssue}
                onCancel={() => setConfirmStatusIssue(false)}
				// onSubmit={}
                title="Confirmation Change Status"
				message={`Are you sure you want to change status of this issue ?`}
            />
            <ConfirmStatusShipment
                open={confirmStatusShipment}
                onCancel={() => setConfirmStatusShipment(false)}
            />
            <ViewExtractedInfo 
                open={viewExtractedInfo}
                onCancel={() => setViewExtractedInfo(false)}
            />
            <ConfirmRemoveCollaborator
                open={removeCollaborator}
                onCancel={() => setRemoveCollaborator(false)}
                title="Confirmation Remove Collaborator"
                message={`Are you sure you want to remove this collaborator ?`}
            />
            <AddBRCollaborators
                open={addBRCollaborators}
                onCancel={() => {
                    setAddBRCollaborators(false);
                }}
            />
            <ConfirmSaveAsTemplate 
                open={confirmSaveTemplate}
                onCancel={() => {
                    setConfirmSaveTemplate(false);
                }}
            />
            {loading && (
                <Spinner type="transparent" />
            )}
        </>
    );
};