import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import avatar from 'assets/images/avatar.png';
import { Button, Textarea } from 'components';
import { AuthContext, BookingRequestContext } from 'contexts';
import { BR_COMMENTS_PAGE_SIZE } from 'actions';
import { getErrorMessage } from 'utils';

export const CommentTab = ({
    errors = [],
    register = () => {}
}) => {
    const { userInfo } = useContext(AuthContext);
    const {
        bookingRequest,
        doGetBookingRequestComments,
        comments,
        totalComments,
        currentPageComments,
        totalPagesComments
    } = useContext(BookingRequestContext);

    /*eslint-disable */
    useEffect(() => {
        if (bookingRequest) {
            doGetBookingRequestComments(bookingRequest._id, {
                page: 0,
                limit: BR_COMMENTS_PAGE_SIZE
            })
        }
    }, [bookingRequest])
    /*eslint-enable */

    const loadMoreComments = () => {
        doGetBookingRequestComments(bookingRequest._id, {
            page: currentPageComments + 1,
            limit: BR_COMMENTS_PAGE_SIZE
        });
    }

    return (
        <div className="comments">
            {totalComments > 0 ? (
                <>
                    <p>{totalComments} comment{totalComments > 1 ? 's' : ''}</p>
                    {comments.map((comment, commentIndex) => (
                        <div className="comments-item d-flex" key={commentIndex}>
                            <div className="comments-item--avatar mrx2">
                                <img src={comment.creator.avatar || avatar} alt={comment.user} />
                            </div>
                            <div className="comments-item--content mbx3">
                                {comment.creator && (
                                    <div className="user">
                                        <h6 className="h6 f-medium mbx1">{comment.creator.name}</h6>
                                        <p className="f-medium">{moment(comment.base.createdDate).format('DD MMM YYYY | hh:mm A')}</p>
                                    </div>
                                )}
                                <div className="content mtx2">
                                    <pre>{comment.content}</pre>
                                </div>
                                {comment.files && comment.files.length > 0 && (
                                    <div className="gallery mtx3">
                                        {comment.files.map((item, itemIndex) => (
                                            <img src={item} key={itemIndex} alt={itemIndex} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {totalPagesComments > currentPageComments + 1 && (
                        <div className="load-more-link mtx2 text-center">
                            <p className="tr__link" onClick={loadMoreComments}>Load more comments...</p>
                        </div>
                    )}
                </>
            ) : (
                <p className="mbx3">No comments</p>
            )}
            {userInfo && (
                <div className="comments-item form mtx3">
                    <div className="comments-item--field d-flex">
                        <div className="comments-item--avatar mrx2">
                            <img src={userInfo.avatar || avatar} alt={userInfo.name} />
                        </div>
                        <div className="comments-item--content d-flex align-items-end">
                            <div className="mrx2">
                                <div className="form-btns">
                                    <h6 className="h6 f-medium mbx1">You</h6>
                                    <Textarea
                                        name="commentMessage"
                                        placeholder="Add your comment"
                                        icon="icon-paperclip"
                                        iconPosition="right"
                                        refs={register({ required: true })}
                                        error={!!errors.commentMessage}
                                        errorMessage={getErrorMessage(errors.commentMessage, 'Comment')}
                                    />
                                </div>
                            </div>
                            <Button
                                isSubmitBtn={true}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

CommentTab.propTypes = {
    errors: PropTypes.any,
    register: PropTypes.func,
    reset: PropTypes.func
}


