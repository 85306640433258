import React, { useContext, useState } from 'react';
import moment from 'moment';

import './index.scss';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
    Table,
    TableFooter, 
    TableHeader, 
    Input, 
    BookingTaskHeader,
    Tooltip,
    MembersAvatar
} from 'components';
import {
    ADD_NEW_REQUEST_URL,
    BOOKING_REQUESTS_PAGE_SIZE,
    CREATOR,
    EDIT_REQUEST_URL,
    MARKED,
    MARK_AS_ACTIVE_SHIPMENT
} from 'actions';
import { BookingRequestContext, HomeContext } from 'contexts';


export const RequestList = ({
    typeRequest,
    searchParams,
    setSearchParams = () => {}
}) => {
    const history = useHistory();
    const { searchMessage, setBrType } = useContext(HomeContext);
    const {
        currentPage,
        totalPages,
        bookingRequests,
        doGetBookingRequests
    } = useContext(BookingRequestContext);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState();

    const columns = [
        {
			dataIndex: 'referenceNumber,note',
			render: (referenceNumber, note) => (
                <div className="reference-no d-flex align-items-center justify-content-between">
                    <h6 className="h6 f-medium">{referenceNumber}</h6>
                    {note && (
                        <Tooltip
                            content={note}
                        >
                            <i className="icon-notes" />
                        </Tooltip>
                    )}
                </div>
                
            )
        },
        {
			dataIndex: 'bookingRequestNumber',
			render: bookingRequestNumber => (
                <div className="booking-no">
                    <h6 className="h6 f-medium" title={bookingRequestNumber || ''}>{bookingRequestNumber || ''}</h6>
                </div>
                
            )
        },
        {
			dataIndex: 'loadingPort',
			render: loadingPort => (
                <h6 className="h6">{loadingPort ? `${loadingPort.city}, ${loadingPort.country} (${loadingPort.locode})` : ''}</h6>
            )
        },
        {
			dataIndex: 'dischargePort',
			render: dischargePort => (
                <h6 className="h6">{dischargePort ? `${dischargePort.city}, ${dischargePort.country} (${dischargePort.locode})` : ''}</h6>
            )
        },
        {
			dataIndex: 'dateOfReceipt',
			render: dateOfReceipt => {
                const isValidDates = dateOfReceipt && dateOfReceipt.fromDate && dateOfReceipt.toDate;
                const fromDateStr = moment(dateOfReceipt.fromDate).format('DD MMM YYYY');
                const toDateStr = moment(dateOfReceipt.toDate).format('DD MMM YYYY')
                return (
                    <h6 className="h6">{isValidDates ? `${fromDateStr}${fromDateStr !== toDateStr ? ` - ${toDateStr}` : ''}` : ''}</h6>
                )
            }
        },
        {
			dataIndex: 'members',
            render: members => <MembersAvatar  
                className="small" 
                members={members} 
                skip={4}
            />
        },
        {
			dataIndex: 'base',
			render: base => (
                <h6 className="h6">{moment(base.createdDate).format('DD MMM YYYY')}</h6>
            )
        },
        {
			dataIndex: 'status',
			render: status => (
                <div className={`booking-status ${(status || '').toLowerCase()}`}>
                    <p>{status === MARK_AS_ACTIVE_SHIPMENT ? MARKED.toLowerCase() : status.toLowerCase()}</p>
                </div>
            )
        }
    ];

    const handlePageChange = page => {
        const params = {
            ...searchParams,
            page,
            limit: BOOKING_REQUESTS_PAGE_SIZE,
            tab: typeRequest,
            isSearching: true
        };

        doGetBookingRequests(params);
    }

    const handleAddRequest = () => {
        setBrType(CREATOR);
        history.push({
            pathname: ADD_NEW_REQUEST_URL,
            state: false
        });
    }

    const handeNavigateEditReference = data => {
        const { _id } = data;
        if (_id) history.push(EDIT_REQUEST_URL.replace(':brId', _id));
    };

    const onInput = () => {
        const newParams = {
            ...searchParams,
            tab: typeRequest,
            limit: BOOKING_REQUESTS_PAGE_SIZE,
            page: 0,
            isSearching: true
        };
        if (searchTerm) newParams.query = searchTerm.trim();

        setSearchParams(newParams);
        setSearchLoading(true);

        doGetBookingRequests(newParams, () => {
            setSearchLoading(false);
        }, false);
    };

    const handleStatusSorting = () => {
        
    }

    return (
        <div className="tr__request-list">
              <div className="wrapper">
                <div className="main-content">
                    <TableHeader
                        onAddButtonClick={handleAddRequest}
                        labelButton="Add New Request"
                        actionSearch = {false}
                    > 
                        <Input
                            icon="icon-search"
                            iconPosition="right"
                            name="bookingRequestSearch"
                            onIconClick={() => onInput()}
                            placeholder="Search"
                            loading={searchLoading}
                            onKeyPress={e => {
                                if (e.which === 13) onInput();
                            }}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </TableHeader>

                    <BookingTaskHeader 
                        className="mtx2" 
                        typeRequest={typeRequest}
                        onStatusSorting={handleStatusSorting}
                    />

                    <Table
                        className="border"
                        dataSource={bookingRequests}
                        columns={columns}
                        emptyMessage={searchMessage ? searchMessage : 'No booking request found'}
                        onRowClick={handeNavigateEditReference}
                    />
                </div>
                {totalPages > 1 && (
                    <TableFooter
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={handlePageChange}
                        note='All drafts, cancelled requests and rejected requests will be deleted after 15 days.'
                    />
                )}
            </div>
        </div>
    )
};

RequestList.propTypes = {
    className: PropTypes.string,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func
};