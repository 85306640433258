import React, { useContext, useState } from 'react';

import './index.scss';
import {
    Modal,
    MultiSelect,
    AssigneeList,
    ItemCard
} from 'components';
import avatar from 'assets/images/avatar.png';
import { CollaboratorsContext, HomeContext, ManagementContext, ModalContext } from 'contexts';
import { ALL, COLLABORATORS_PAGE_SIZE, TEAM } from 'actions';
import { useLocation } from 'react-router-dom';

export const AssignModal = ({ open = false, onCancel }) => {
    const { taskId, taskName, taskRoles } = useContext(HomeContext);
    const { taskCollaborators, doGetCollaborators } = useContext(CollaboratorsContext);
    const { taskPartners, doAssignTaskPartner, doGetTaskManagements, doRemoveTaskPartner } = useContext(ManagementContext);
    const { setAssignCollaborators } = useContext(ModalContext);
    const [assignees, setAssignees] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];

    const handleAddPartners = () => {
        let selectedPartners = [];
        assignees.forEach(item => {
            selectedPartners = [
                ...selectedPartners,
                {
                    id: item.id,
                    type: item.type,
                    roles: item.roles
                }
            ]
        });
        const payload = {
            partners: selectedPartners
        }
        setAssignees([]);
        doAssignTaskPartner(shipmentId, taskId, payload);
        setAssignCollaborators(false);
    }

    const handleSearchCollaborators = (value) => {
        const params = {
            query: value.trim(),
            limit: COLLABORATORS_PAGE_SIZE,
            page: 0,
            role: taskRoles
        };
        setSearchLoading(true);
        doGetCollaborators(shipmentId, params, () => {
            setSearchLoading(false);
        });
    }

    const handleRemovePartner = (member) => {
        console.log(member)
        doRemoveTaskPartner(taskId, {
            id: member.id
        })
    }

    const handleChange = items => {
        setAssignees(oldValue => [...oldValue, ...items.map(item => {
            return {
                ...item,
            }
        })]);
    }

    const handleRemove = item => {
        setAssignees(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
    }

    return (
        <Modal
            open={open}
            className="tr__assign-modal no-padding"
            onCancel={() => { 
                onCancel();
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: ALL
                })
            }}
            title={`Assign to "${taskName}"`}
            submitButton
            submitButtonLabel="Add"
            isBackDropClickable={false}
            submitButtonDisabled={!(assignees && assignees.length > 0)}
            onSubmitClick={handleAddPartners}
        >
            <div className="tr__assign-modal--form">
                <MultiSelect
                    className="mbx2"
                    options={taskCollaborators && taskCollaborators.length > 0 ? taskCollaborators.filter(collaborator => !taskPartners.some(m => m.id === collaborator._id)).filter(collaborator => !assignees.some(m => m.id === collaborator._id)).map(item => {
                        return {
                            id: item._id,
                            icon: item.avatar || (item.type === TEAM ? 'icon-users' : avatar),
                            title: item.name,
                            description: item.email,
                            type: item.type,
                            roles: item.roles
                        }
                    }) : []}
                    value={assignees}
                    addButton={true}
                    addButtonLabel="Select"
                    label={`Assign to "${taskName}"`}
                    placeholder='You can search by name, email, team name or can invite new people...'
                    onChange={handleChange}
                    onRemove={handleRemove}
                    onInputChange={handleSearchCollaborators}
                    searchLoading={searchLoading}
                    renderList={members => members.length > 0 && (
                        <div className='tr__partners d-flex flex-wrap mtx1'>
                            {members && members.length > 0 && members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={handleRemove}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
            </div>
            <div className="tr__assign-modal--list">
                <AssigneeList
                    title="Assigned"
                    assignees={taskPartners}
                    onRemove={handleRemovePartner}
                />
            </div>
        </Modal>
    )
};