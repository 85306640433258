import React, { useContext, useEffect, useState } from 'react';

import {
    Tab,
    TabPane,
    TaskManagement,
    ShipmentInfo,
    Documents,
    Issues,
    FTAAdvisor,
    ShipmentLog
} from 'components';
import { HomeContext } from 'contexts';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { 
    ACTIVE_SHIPMENT_DETAILS_URL, 
    ISSUES_URL, 
    TASK_MANAGEMENT, 
    DOCUMENTS,
    ISSUES,
    FTA,
    SHIPMENT_INFO,
    SHIPMENT_LOG,
    ISSUE_DETAILS_URL,
    DOCUMENTS_URL,
    FTA_URL,
    SHIPMENT_INFO_URL,
    SHIPMENT_LOG_URL,
    NOT_FOUND_URL,

} from 'actions';
import { IssueDetails } from '../components';



export const ShipmentDetailsTabs = ({ type, setNoResultSearch, shipmentInfo }) => {
    const location = useLocation();
    const history = useHistory();
    const { shipmentId } = useParams();
    const [currentTab, setCurrentTab] = useState(0);
    const pathnameArr = location.pathname.split('/');
    const id = pathnameArr[pathnameArr.length - 1];

    /*eslint-disable */
    useEffect(() => {
        switch (location.pathname) {
            case ACTIVE_SHIPMENT_DETAILS_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(0);
                break;
            case DOCUMENTS_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(1);
                break;
            case ISSUES_URL.replace(':shipmentId', shipmentId) + '/' + id:
                setCurrentTab(2);
                history.push(`${ISSUES_URL.replace(':shipmentId', shipmentId)}/${id}`);
                break;
            case ISSUES_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(2);
                break;
            case FTA_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(3);
                break;
            case  SHIPMENT_INFO_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(4);
                break;
            case SHIPMENT_LOG_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(5);
                break;
            default:
                history.push(NOT_FOUND_URL);
                break;
        }
       
    }, [])
    /*eslint-enable */
    
    const { isArchive } = useContext(HomeContext);

   
    const handleTabChange = (tab) => {
        switch (tab) {
            case TASK_MANAGEMENT:
                history.push(ACTIVE_SHIPMENT_DETAILS_URL.replace(':shipmentId',shipmentId));
                break;
            case DOCUMENTS:
                history.push(DOCUMENTS_URL.replace(':shipmentId',shipmentId));
                break;
            case ISSUES:
                history.push(ISSUES_URL.replace(':shipmentId',shipmentId));
                break;
            case FTA:
                history.push(FTA_URL.replace(':shipmentId',shipmentId));
                break;
            case SHIPMENT_INFO:
                history.push(SHIPMENT_INFO_URL.replace(':shipmentId',shipmentId));
                break;
            case SHIPMENT_LOG:
                history.push(SHIPMENT_LOG_URL.replace(':shipmentId',shipmentId));
                break;
            default:
                history.push(ACTIVE_SHIPMENT_DETAILS_URL.replace(':shipmentId', shipmentId));
                break;
        }
    }
    return (
        <div className="tr__shipment-details-tabs full-height">
            <Tab className="minimal full-height" initialTab={currentTab} onTabClick={handleTabChange}>
                <TabPane
                    tabName={`Task Management`}
                    tabSlug={TASK_MANAGEMENT}
                >
                    <Switch>
                        <Route path={ACTIVE_SHIPMENT_DETAILS_URL} exact>
                            <TaskManagement 
                                type={type}
                                setNoResultSearch={setNoResultSearch}
                            />
                        </Route>
                    </Switch>
                   
                </TabPane>
                <TabPane
                    tabName={DOCUMENTS}
                    tabSlug={DOCUMENTS}
                >
                    <Switch>
                        <Route path={DOCUMENTS_URL} exact>
                            <Documents />
                        </Route>
                    </Switch>
                </TabPane>
                
                <TabPane
                    tabName={ISSUES}
                    tabSlug={ISSUES}
                >   
                <Switch>
                     <Route path={ISSUES_URL} exact>
                        <Issues />
                    </Route>
                    <Route path={ISSUE_DETAILS_URL} exact>
                        <IssueDetails />
                    </Route>
                   
                </Switch>
                   
                </TabPane>
                {!isArchive && (
                    <TabPane
                        tabName="FTA advisor"
                        tabSlug={FTA}
                    >
                        <Switch>
                            <Route path={FTA_URL} exact>
                                <FTAAdvisor />
                            </Route>
                        </Switch>
                    </TabPane>
                )}
                <TabPane
                    tabName="Shipment Information"
                    tabSlug={SHIPMENT_INFO}
                >
                    <Switch>
                        <Route path={SHIPMENT_INFO_URL} exact>
                            <ShipmentInfo 
                                shipmentInfo={shipmentInfo}
                            />
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane
                    tabName="Shipment Log"
                    tabSlug={SHIPMENT_LOG}
                >
                    <Switch>
                        <Route path={SHIPMENT_LOG_URL} exact>
                            <ShipmentLog />
                        </Route>
                    </Switch>
                </TabPane>
            </Tab>
        </div>
    )
};