import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
} from 'components';
import './index.scss';
export const ShipmentDocument = ({icon, singleValue = false, values, onChange, items = []}) => {

    const handleCheckboxChange = item => {
        let checkedValues = [];
        const isExist = values.includes(item);
        if (singleValue) {
            if (isExist) checkedValues = [...values]
            else checkedValues = [item]
        } else {
            if (isExist) checkedValues = values.filter(v => v.id !== item.id);
            else checkedValues = [
                ...values,
                item
            ];
        }

        onChange(checkedValues);
    }

    return items.length > 0 ? (
        <div className="tr__shipment-document tr__file-list regular">
            {items.map((item, itemIndex) => (
                <li className="" key={itemIndex}>
                    <Checkbox
                        key={itemIndex}
                        name="documentName"
                        type="checkbox"
                        value={item.id}
                        checked={values && values.length > 0 && values.includes(item)}
                        onChange={() => handleCheckboxChange(item)}
                    />
                    <i className={`${icon} mrx1`}></i>
                    <div className="info">Invoice:  
                        <span> {item.name} </span>
                    </div>
                </li>
            ))}
            
        </div>
    ) : null
}

ShipmentDocument.propTypes = {
    icon: PropTypes.string,
    items: PropTypes.array,
    values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
};
