import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { HomeContext, ManagementContext } from 'contexts';
import { useHistory, useLocation } from 'react-router-dom';
import { TEMPLATES_URL } from 'actions';

export const ConfirmSaveAsTemplate = ({
    open = false,
    onCancel,
    ...rest
}) => {
    const history = useHistory();
    const { setNotificationMessage } = useContext(HomeContext);
    const { doSaveAsTemplate } = useContext(ManagementContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    const handleSubmit = () => {
        doSaveAsTemplate(shipmentId, {
            id: shipmentId,
        }, () => {
            history.push(TEMPLATES_URL);
            setNotificationMessage(`
                <p> Save as template successfully!</p>
            `);
        })
        onCancel();
    }

    return (
        <Modal
            open={open}
            className="tr__delete-confirmation"
            onCancel={onCancel}
            title='Confirmation Save As Template'
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Yes"
            onSubmitClick={handleSubmit}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
            {...rest}
        >
            <div className="text-center delete-message">
                <h6 className="h6 mtbx2 f-medium">Are you sure you want to save this shipment as a template?</h6>
            </div>
        </Modal>
    )
};

ConfirmSaveAsTemplate.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
};
