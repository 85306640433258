import React from 'react';

import './index.scss';
import {
    Modal
} from 'components';
import { documentModal } from 'utils';

export const DocumentModal = ({ open = false, onCancel }) => {

    return (
        <Modal
            open={open}
            className="tr__document-modal no-padding"
            onCancel={onCancel}
            title='Document'
            isBackDropClickable={false}
        >
            {documentModal.map((document, documentIndex) => (
                <div className="tr__document-modal--item" key={documentIndex}>
                    {document.map((field, fieldIndex) => (
                        <div className="tr__document-modal--field d-flex" key={fieldIndex}>
                            <p className="label f-medium">{field.key}</p>
                            <p className="value">{field.value}</p>
                        </div>
                    ))}
                    <p className="tr__link">See Example Document</p>
                </div>
            ))}
        </Modal>
    )
};