import React from 'react';

import './index.scss';

// import { Button } from 'components';
import { PropTypes } from 'prop-types';

export const ExtractedInfoTable = ({ extractedInfo = [] }) => {

    // const addNewLine = (infoExtract) => {
    // }

    // const removeLine = (item) => {
    // }
    return extractedInfo && extractedInfo.length > 0 ? (
        <>
        {extractedInfo.map((info, infoIndex) => {
            return (
            <div className="tr__extracted-info-table" key={infoIndex}>
                <p className="tr__link remove-card">-</p>
                <div className="tr__extracted-info-list">
                    <div className="tr__extracted-info-list--header d-flex justify-content-between align-items-center">
                        <div className="d-flex f-medium">
                            <h1 className="mrx1">{info.title}</h1>
                            <i className="icon-pencil" />
                        </div>
                        <div className="sort-icon">
                            <i className="icon-arrow-up"/>
                            <i className="icon-arrow-down"/>
                        </div>
                    </div>
                    <div className="tr__extracted-info-list--table">
                        {info.details.map((detailsInfo, detailsInfoIndex) => {
                            return (
                            <div className="table-content d-flex justify-content-between" key={detailsInfoIndex}>
                                <div className="col-6 column name d-flex align-items-center">
                                    {/* <Button
                                        type="secondary"
                                        className="icon"
                                        icon="icon-times"
                                        onClick={() => removeLine(detailsInfo)}
    
                                    /> */}
                                    {detailsInfo.name}
                                </div>
                                <div className="col-6 column">
                                    {detailsInfo.value}
                                </div>
                            </div>)
                        })}
                        {/* <div className="d-flex align-items-center add-new-line">
                            <i className="icon-plus mrx1"/>
                            <h4 className="f-medium" onClick={() => addNewLine(extractedInfo)}>Add New Line</h4>
                        </div> */}
                    </div>
                </div>
            </div>
            )
        })}
      </>
    ) : '';
};

ExtractedInfoTable.propTypes = {
    extractedInfo: PropTypes.array
}