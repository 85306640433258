/**
 * FIXME: Sometime FE drop user info section if stay at this screen so long (maybe cause by user section expire)
 */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './index.scss';
import {
    Button,
    Member,
    AssigneeList,
    MultiSelect,
    Textarea,
    Modal,
    FileUploader,
    FilePreview,
} from 'components';
import {
    getErrorMessage,
    getBase64DataFile,
} from 'utils';
import avatar from 'assets/images/avatar.png';
import {
    CLOSED, 
    COLLABORATORS_PAGE_SIZE, 
    COMMENT_PAGE_SIZE, 
    DONE, 
    ISSUES_URL, 
    OPEN, 
    TEAM,
} from 'actions';
import { 
    AuthContext, 
    CollaboratorsContext, 
    HomeContext, 
    IssueContext, 
    ModalContext 
} from 'contexts';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export const IssueDetails = () => {
    const history = useHistory();
    const { shipmentId, issueId } = useParams();
    const { handleSubmit, register, reset, errors, formState } = useForm();
    const { isArchive } = useContext(HomeContext);
    const { userInfo } = useContext(AuthContext);
    const { setAddIssue, setEditIssue, setConfirmStatusIssue } = useContext(ModalContext);
    const { 
        doGetIssue, 
        comments, 
        doGetComments, 
        doCreateComment,
        doAddPartnerIssue,
        currentCommentPage,
        totalCommentPages,
        issue,
        setStatusIssue,
        doGetTasksIssue,
        doRemovePartnerIssue,
        setIssues
    } = useContext(IssueContext);
    const { collaborators, doGetCollaborators } = useContext(CollaboratorsContext);

    const [assignee, setAssignee] = useState([]);
    const [searchLoadingPartner, setSearchLoadingPartner] = useState(false);
    const [isOpenCommendDocumentsUploader, setIsOpenCommentDocumentsUploader] = useState(false);
    const [rawFiles, setRawFiles] = useState([]);
    const [files, setFiles] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        doGetIssue(shipmentId, issueId);
        doGetCollaborators(shipmentId, {
            page: 0,
            limit: COLLABORATORS_PAGE_SIZE
        })
    },[])

    /*eslint-enable */

    const changeStatus = (status) => {
        setConfirmStatusIssue(true);
        setStatusIssue({
            id: issue._id,
            status: status,
        });
    }

    const onEditIssue = () => {
        setEditIssue(true);
        setAddIssue(true);
        doGetTasksIssue(shipmentId);
    }
    const sendComments = (data) => {
        const {comments } = data;
        const payload = {
            content: comments.trim(),
            attachments: files,
        }
        doCreateComment(shipmentId, issue._id, payload, () => {
            reset();
        })
    }
    const loadMoreComments = () => {
        doGetComments(shipmentId, issue._id, {
            page: currentCommentPage + 1,
            limit: COMMENT_PAGE_SIZE
        });
    }
    const handleAddAssignee = (item) => {
        const payload = {
            partner : {
                id: item[0].id,
                type: item[0].type,
            }
        }
        doAddPartnerIssue(shipmentId, issue._id, payload);
        setAssignee([]);
    }
    const handleRemoveMember = member => {
        const payload = {
            id: member.id,
            type: member.type
        }
        doRemovePartnerIssue(shipmentId, issue._id, payload, () => { })
    }

    const handleSearchPartners = (value) => {
        const params = {
            query: value.trim(),
            limit: COLLABORATORS_PAGE_SIZE,
            page: 0,
        };
        setSearchLoadingPartner(true);
        doGetCollaborators(shipmentId, params, () => {
            setSearchLoadingPartner(false);
        });
    }

    const handleFilesUpload = async uploadFiles => {
        let parsedFiles = files ? files : [];

        for (let file of uploadFiles) {
            let parsedFile = {
                fileName: file.name,
                data: await getBase64DataFile(file),
                size: file.size,
            };
            parsedFiles.push(parsedFile);
        }

        setRawFiles(parsedFiles);
    }

    const handleCancelFilesUpload = () => {
        setRawFiles([]);
        setIsOpenCommentDocumentsUploader(false);
    }

    const handleSubmitFilesUpload = () => {
        setFiles(rawFiles);
        setIsOpenCommentDocumentsUploader(false);
    }

    const handleGoBack = () => {
        setIssues([]);
        history.push(ISSUES_URL.replace(':shipmentId', shipmentId));
    }

    return issue ? (
        <div className="tr__shipment-issue">
            <div className="tr__shipment-issue--header d-flex align-items-center justify-content-between">
                <div className="main-title d-flex align-items-center justify-content-between">
                    <p className="tr__link tag mrx2" onClick={handleGoBack}>
                        <i className="icon-chevron-left" />
                        <span>Back</span>
                    </p>
                    <div className="issue-info">
                        <h2 className="h2 f-medium mbx1">{issue.title}</h2>
                        <p className="f-medium">{issue.description}</p>
                    </div>
                </div>
                {issue && issue.status === CLOSED && issue.base && (
                    <div className="closed-issue d-flex align-items-center">
                        <i className="icon-check mrx1" />
                        <div className="closed-issue--info d-flex align-items-center">
                            {issue && issue.base && (
                                    <>
                                    <img className="mrx1" 
                                        src={issue.base.updatedBy.avatar || avatar} 
                                    alt={issue.base.updatedBy.name} />
                                        <h6 className="h6 f-medium">{userInfo._id === issue.base.updatedBy._id ? 'You': issue.base.updatedBy.name}</h6>
                                        <p className="label">closed this issue on</p>
                                        <p>{moment(issue.base.updatedDate).format('DD MMM YYYY | hh:mm A')}</p>
                                    </>
                                )
                            }
                        </div>
                    </div>
                )}

                </div>

                {/*** Render comments ***/}
                <div className="tr__shipment-issue--content d-flex">
                    <div className="comments">
                        {comments && comments.length > 0 ? comments.map((comment, commentIndex) => (
                            <div className="comments-item d-flex" key={commentIndex}>
                                {comment && comment.base && (
                                    <>
                                        <div className="comments-item--avatar mrx2">
                                            <img src={comment.base.createdBy.avatar === "" ? avatar : comment.base.createdBy.avatar} alt={comment.base.createdBy.name} />
                                        </div>
                                        <div className="comments-item--content mbx3">
                                            <div className="user">
                                                <h6 className="h6 f-medium mbx1">{comment.base.createdBy.name}</h6>
                                                <p className="f-medium">{moment(comment.base.createdDate).format('DD MMM YYYY | hh:mm A')}</p>
                                            </div>
                                            {/* <div className="content mtx2" dangerouslySetInnerHTML={{ __html: comment.content }}></div> */}
                                            <div className="content mtx2">
                                                <pre>{comment.content}</pre>
                                            </div>
                                            {comment.gallery && comment.gallery.length > 0 && (
                                                <div className="gallery mtx3">
                                                    {comment.gallery.map((item, itemIndex) => (
                                                        <img src={item} key={itemIndex} alt={itemIndex} />
                                                    ))}
                                                </div>
                                            )}

                                            {comment.attachments && comment.attachments.length > 0 && (
                                                <div>
                                                    {comment.attachments.map(item => (
                                                        <FilePreview
                                                            file={item.url}
                                                            fileName={item.fileName}
                                                            fileExtension={item.fileName.split('.').pop()}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )) : (
                            <p className="mbx3">No comments</p>
                        )}
                        {/*** !!! Render comments ***/}

                        {totalCommentPages > currentCommentPage + 1 && (
                            <div className="load-more-link mtx2 mbx2 text-center">
                                <p className="tr__link" onClick={loadMoreComments}>Load more comments...</p>
                            </div>
                        )}

                    {/*** Show add comment block if issue not archived ***/}
                    {!isArchive && userInfo && (
                            <div className="comments-item form d-flex mtx3">
                                <div className="comments-item--avatar mrx2">
                                    <img src={userInfo.avatar ? userInfo.avatar : avatar} alt={userInfo.name} />
                                </div>
                                <div className="comments-item--content d-flex align-items-end">
                                    <form onSubmit={handleSubmit(sendComments)}>
                                        <div className="d-flex align-items-end">
                                            <div className="mrx2">
                                                <div className="form-btns">
                                                    <h6 className="h6 f-medium mbx1">You</h6>
                                                    <Textarea
                                                        refs={register({required: true, maxLength: 1000})}
                                                        name="comments"
                                                        placeholder="Add your comment..."
                                                        icon={{
                                                            className: 'icon-paperclip',
                                                            onClick: () => { setIsOpenCommentDocumentsUploader(true) },
                                                            cursor: 'pointer',
                                                        }}
                                                        iconPosition="right"
                                                        error={!!errors.comments}
                                                        errorMessage={getErrorMessage(errors.comments, 'Comments', 'Comments must be less 1000 characters')}
                                                    />
                                                    {/*** Upload multiple files popup ***/}
                                                    <Modal
                                                        className='tr__add-issue-comment__upload-documents'
                                                        title='Upload documents'
                                                        open={isOpenCommendDocumentsUploader}
                                                        onCancel={handleCancelFilesUpload}
                                                        submitButton={true}
                                                        submitButtonLabel='Upload'
                                                        onSubmitClick={handleSubmitFilesUpload}
                                                    >
                                                        <FileUploader
                                                            className='documents-uploader'
                                                            mode='list'
                                                            name='issueCommentDocuments'
                                                            handleDrop={handleFilesUpload}
                                                            uploadedFiles={rawFiles}
                                                            refs={register}
                                                        />
                                                    </Modal>
                                                    {/*** !!! Upload multiple files popup ***/}
                                                </div>
                                            </div>
                                            <Button
                                                isSubmitBtn={true}
                                                disabled={!formState.dirtyFields.comments}
                                            >
                                                Send
                                            </Button>
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>
                        )}
                    {/*** !!! Show add comment block if issue not archived ***/}

                    </div>
                    <div className="members">
                        <div className="tr__task--link__list">
                            {issue.linkedTasks && issue.linkedTasks.length > 0 && issue.linkedTasks.map((linkTask, linkTaskIndex) => (
                                    <div 
                                    className={`tr__task--link d-flex justify-content-start align-items-center mbx1 ${linkTask.status.taskStatus.toLowerCase() === DONE ? 'done' : ''}`}
                                    key={linkTaskIndex}
                                    >
                                        <i className="icon-chain"></i>
                                        <span>Linked Task: </span>
                                        <span>{linkTask.taskName}</span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="members--created">
                            <h3 className="h3 f-medium mbx2">Issue created</h3>
                            {issue && issue.base && (
                                 <Member
                                    member={issue.base.createdBy}
                                    minimal
                                    createdDate={moment(issue.base.createdDate).format('DD MMM YYYY | hh:mm A')}
                                />
                            )}
                        </div>
                        <div className="members--assignees">
                            <AssigneeList
                                title="Assignees"
                                assignees={issue.partners}
                                isClosed={issue && issue.status === CLOSED ? true : false}
                                shipmentId={shipmentId}
                                onRemove={handleRemoveMember}
                            />
                            {!isArchive && (
                                <div className="members--add-assignees mtx4">
                                    <MultiSelect
                                        className="mbx4"
                                        options={collaborators && collaborators.length > 0 ? collaborators.filter(mem => !issue.partners.some(m => m.id === mem._id)).map(item => {
                                            return {
                                                id: item._id,
                                                icon: item.avatar || (item.type === TEAM ? 'icon-users' : avatar),
                                                title: item.name,
                                                description: item.email,
                                                type: item.type
                                            }
                                        }) : []}
                                        value={assignee}
                                        addButton={true}
                                        addButtonLabel="Add"
                                        placeholder='Search by name'
                                        onChange={handleAddAssignee}
                                        onInputChange={handleSearchPartners}
                                        single={true}
                                        searchLoading={searchLoadingPartner}
                                        disabled={issue.status === CLOSED ? true : false}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {!isArchive && (
                    <div className="tr__shipment-issue--footer d-flex align-items-center justify-content-between">
                        {issue.status === OPEN ? (
                            <>
                                <Button
                                    className="icon outline"
                                    type="secondary"
                                    icon="icon-pencil"
                                    onClick={onEditIssue}
                                >
                                    Edit
                                </Button>
                                <Button
                                    className="outline"
                                    type="secondary"
                                    onClick={() => changeStatus(CLOSED)}
                                    disabled={
                                        issue && issue.partners &&
                                        (!issue.partners.some(p => p.id === userInfo._id || issue.permissionChangeStatus))
                                    }
                                >
                                    Close Issue
                                </Button>
                            </>
                        ) : (
                            <>
                                <div></div>
                                <Button
                                    onClick={() => changeStatus(OPEN)}
                                    disabled={issue.status === CLOSED ? true : false}
                                >
                                    Open Issue
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </div>
    ) : [];
};

IssueDetails.propTypes = {
    issue: PropTypes.object,
    onCloseIssue: PropTypes.func,
    onEditIssue: PropTypes.func
};
