import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import {
    Statuses,
    Tooltip
} from 'components';
import {
    shipmentStatuses
} from 'utils';

export const DetailsHeader = ({ shipment, note }) => {
    const [totalTasks, setTotalTasks] = useState(0);

    /*eslint-disable */
    useEffect(() => {
        if (shipmentStatuses && shipmentStatuses.length > 0 && !totalTasks) {
            let total = 0;
            shipmentStatuses.forEach(status => total += status.num);

            setTotalTasks(total);
        }
    }, [])
    /*eslint-enable */

    return shipment ? (
        <div className='tr__details-header d-flex align-items-end justify-content-between mtbx2'>
            <div className="d-flex align-items-center">
                <span className='h1 f-medium text-uppercase'>{shipment.departureCode}-{shipment.arrivalCode}-{moment(shipment.departureDate).format('DDMMYYYY')}</span>
            {note && (
            <Tooltip
                renderContent={() => (
                    <div className="statistics">
                        <div className="statistics--header d-flex align-items-center justify-content-between">
                            <p className="label f-medium">Total</p>
                            <p className="num">{totalTasks} task{totalTasks > 1 ? 's' : ''}</p>
                        </div>
                        <div className="statistics--content">
                            {shipmentStatuses.map((status, statusIndex) => (
                                <div className="statistics--item mtx05 d-flex align-items-center justify-content-between" key={statusIndex}>
                                    <p className="label">{status.id}</p>
                                    <p>
                                        {status.num - 1 > 0 && <span>{status.num - 1} event{status.num - 1 > 1 ? 's' : ''}</span>}
                                        <span>1 doc</span>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            >
                <Statuses
                    className="bar mlx2"
                    statuses={shipmentStatuses}
                    showLabel={true}
                    suffix="%"
                />
            </Tooltip>
            )}
            </div>
            <p className="f-medium mlx3">{note}</p>
        </div>
    ) : '';
};

DetailsHeader.propTypes = {
    shipment: PropTypes.object,
    note: PropTypes.string
};
