import React, { useContext } from 'react';

import './index.scss';
import {
    Collaborators,
    Button,
    Table,
    DocumentHeader ,
    UploadDocuments
} from 'components';
import { documentTable } from 'utils';
import { HomeContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { VIEW_DOCUMENTS_URL } from 'actions';

export const Documents = () => {
    const history = useHistory();
    const { isArchive } = useContext(HomeContext);
    const navigateViewDocuments = () => {
        history.push(VIEW_DOCUMENTS_URL)
    }
    const columns = [
		{
			dataIndex: 'type,noDocs,favorite',
			render: (type, noDocs, favorite) => (
                <div className="tr__doc-title d-flex">
                    {favorite && <i className="icon-download mrx2"/>}
                    <div className={favorite ? 'favorite-icon' : 'view-document'}>
                        <p className="mbx1 d-flex align-items-center justify-content-between">
                            <span>{type}</span>
                            <span>{noDocs} doc{noDocs > 1 ? 's' : ''}</span>
                        </p>
                        <p className="tr__link" onClick={navigateViewDocuments}>View all documents</p>
                    </div>
                </div>
            )
        },
        {
			dataIndex: 'uploader',
			render: uploader => (
                <div className="tr__doc-date">
                    <p className="mbx1">{uploader.name}</p>
                    <p className="date">{uploader.date}</p>
                </div>
            )
        },
        {
			dataIndex: 'assignees',
            render: assignees => <Collaborators
                members={assignees}
                skip={2}
                label="Assign"
            />
        },
        {
			render: () => <Button
                type="secondary"
                className="icon outline"
                icon="icon-download"
            >Download</Button>
		}
    ];
    
    return (
        <div className="tr__shipment-documents d-flex">
            <div className="tr__shipment-documents--table">
                <Table
                    className="documents"
                    dataSource={documentTable}
                    columns={columns}
                >
                    <DocumentHeader />
                </Table>
            </div>
            {!isArchive && (
                <div className="tr__shipment-documents--uploader">
                    <UploadDocuments
                        title="Upload documents"
                        name="shipmentDocs"
                        mode="doc"
                        onFilesUpload={files => console.log(files)}
                    />
                </div>
            )}
        </div>
    )
};
