import React, { createContext, useContext, useState } from 'react';
import { 
    HomeContext,
    AuthContext,
} from 'contexts';
import {
    OPERATION_FAILED_MESSAGE,
    getTemplates,
    ERROR,
    getTemplate,
    addNewSection,
} from 'actions';

export const TemplateContext = createContext();

export const TemplateContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const { setLoading, setNotificationType, setNotificationMessage } = useContext(HomeContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const [templates, setTemplates] = useState([]);
    const [templatesDetails, setTemplatesDetails] = useState(null)
  

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType(ERROR);
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetTemplates = async (params, callback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);
            const response = await getTemplates(params, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                const { items } = data;
                setTemplates(items || []);
                setTotalPages(data.totalPage);
                setCurrentPage(data.currentPage);
                if (callback) callback();
            }
            if (hasLoading) setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doGetTemplatesDetails = async (id,callback) => {
        try {
            setLoading(true);
            const response = await getTemplate(id, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                setTemplatesDetails(data);
                
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    const doAddNewSection = async(templateId,payload, callback) => {
        try {
            setLoading(true);
            const response = await addNewSection(templateId,payload, loggedInUser);
            const { status } = response;
            if (status === 200) {                
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error)
        }
    }

    return (
        <TemplateContext.Provider
            value={{
                templates,
                currentPage,
                totalPages,
                templatesDetails,
                setCurrentPage,
                doGetTemplates,
                doGetTemplatesDetails,
                doAddNewSection
            }}
        >
            { children }
        </TemplateContext.Provider>
    );
}