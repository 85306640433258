import React, { useEffect, useState } from 'react';
import './index.scss';
import { 
    Dropdown,
    Tooltip,
    InputGroup 
} from 'components';
import PropTypes from 'prop-types';

export const EditShipmentInfoDropdown = ({ label, name, className, originalvalue, tooltipOldlvalue, newValue , ...rest }) => {
    const [isChangevalue, setChangeValue] = useState(false);

    /*eslint-disable */
    useEffect(() => {
        setChangeValue(originalvalue && originalvalue !== newValue);
    },[newValue])
    /*eslint-disable */

    return (
        <div className={`tr__description-input ${className}`}>
            <InputGroup>    
                <label htmlFor={name}>
                    <span>{label}</span>
                    {   
                        isChangevalue && tooltipOldlvalue ? 
                            <Tooltip
                                renderContent={() => (
                                    <div>Old Value: {originalvalue}</div>
                                )}
                            >
                                <i className="icon-warning mlx1"></i>
                            </Tooltip>
                        : ''
                    }
                </label>
                <Dropdown 
                    name={name}
                    {...rest}
                />
            </InputGroup>
        </div>
    )
}

EditShipmentInfoDropdown.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    originalvalue: PropTypes.string,
    tooltipOldlvalue: PropTypes.bool, 
    newValue: PropTypes.string
};