import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { ShipmentCard } from 'components';
import {
    HomeContext
} from 'contexts';

export const ShipmentList = ({
    className = '',
    title,
    shipments,
    onShowDetails,
    isLinkTask = false
}) => {
    const { isArchive } = useContext(HomeContext);

    return (
        <div className={`tr__active-shipment-list ${className}`}>
            {!isArchive && title && (
                <h2 className="h2 f-medium mbx2">{title}</h2>
            )}
            {shipments.length > 0 ? (
                <div className={`${isLinkTask ? 'tr__link-task-list' : ''}`}>
                    {shipments.map((shipment, shipmentIndex) => (
                        <ShipmentCard
                            key={shipmentIndex}
                            shipment={shipment}
                            className='mbx3'
                            onShowDetails={() => onShowDetails(shipment)}
                            isArchive={isArchive}
                        />
                    ))}
                    {isLinkTask && (
                        <i className='tr__link-task-pin icon-chain' />
                    )}
                </div>
            ) : (
                <p>No active shipments found.</p>
            )}
        </div>
    )
};

ShipmentList.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    onShowDetails: PropTypes.func,
    isLinkTask: PropTypes.bool,
};
