import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';

export const TabVesselRouteInfo = ({ bookingDetails = {} }) => {
    return <InfoBlock
        className="tr__details-box"
        title="Booking Details"
    >
          <InfoRow >
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Carrier name"
                        value={bookingDetails.carrierName ? bookingDetails.carrierName : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="SCAC code"
                        value={bookingDetails.scacCode ? bookingDetails.scacCode : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="Booking confirmation no"
                        value={bookingDetails.confirmationNo ? bookingDetails.confirmationNo.toString() : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label="Master bill of lading"
                        value={bookingDetails.masterBoL ? bookingDetails.masterBoL.toString() : ''}
                    />
                </div>
            </div>
            <div className="col-12 col-sm-6">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label="House bill of lading"
                        value={bookingDetails.houseBoL ? bookingDetails.houseBoL.toString() : ''}
                    />
                </div>
            </div>
        </InfoRow>
    </InfoBlock>
};

TabVesselRouteInfo.propTypes = {
    shippingDetails: PropTypes.object,
};