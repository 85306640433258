import React from 'react';
import PropTypes from 'prop-types';

import {
    EditShipmentInfoInput,
    Collapse
} from 'components';

export const EditInvoice = ({ register, shipmentDetails, watch, editShipmentInfo }) => {
    const invoiceValues = shipmentDetails && shipmentDetails.invoices ? shipmentDetails.invoices.toString() : '';
    const newInvoiceNumber = watch ? watch("invoiceNumber",invoiceValues) : '';

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Invoice" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="invoiceNumber"
                                        refs={register()}
                                        maxLength={50}
                                        label="Invoice number"
                                        placeholder="Eg: 94500331575,94500331575"
                                        defaultValue={invoiceValues}
                                        originalvalue={invoiceValues}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newInvoiceNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditInvoice.propTypes = {
    register: PropTypes.func,
    shipmentDetails: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool
};
