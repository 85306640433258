import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss'
import {
    Collapse,
    Button,
    Dropdown,
    Input
} from 'components';
import { sampleOptions } from 'utils';

export const DocumentTable = ({ className = '', document }) => {
    const [docs, setDocs] = useState([1, 2]);

    const handleAddDoc = () => {
        setDocs(oldDocs => [...oldDocs, oldDocs.length + 1]);
    }

    const handleRemoveDoc = doc => {
        setDocs(oldDocs => [...oldDocs.filter(d => d !== doc)]);
    }

    return (
        <div className={`tr__document-table ${className}`}>
            <Collapse
                className="details"
                initialOpen={true}
                renderHeader={() => (
                    <>
                        <span>
                            <span>{`Invoice Number: ${document}`}</span>
                            <i className="icon-pencil mlx1" onClick={e => {
                                e.stopPropagation();
                                console.log('Editing Invoice...');
                            }} />
                        </span>
                        <i className="icon-chevron-down" />
                    </>
                )}
            >
                <div className="tr__document-table--header table-row d-flex align-items-center table-header justify-content-between">
                    <div className="column type">
                        <p>Data type</p>
                    </div>
                    <div className="column description">
                        <p>Description</p>
                    </div>
                    <div className="column price">
                        <p>Total net price</p>
                    </div>
                    <div className="column currency">
                        <p>Currency</p>
                    </div>
                </div>
                <div className="tr__document-table--content mtx1">
                    {docs.map((doc, docIndex) => (
                        <div className="table-row d-flex align-items-center table-body justify-content-between" key={docIndex}>
                            <div className="column type d-flex align-items-center">
                                <i className="icon-times mrx2" onClick={() => handleRemoveDoc(doc)} />
                                <Dropdown
                                    mode="select"
                                    icon="icon-chevron-down"
                                    iconPosition="right"
                                    options={sampleOptions}
                                    dropdownPosition="center"
                                    placeholder="Choose type"
                                />
                            </div>
                            <div className="column description">
                                <Input
                                    name="description"
                                    placeholder="Description"
                                />
                            </div>
                            <div className="column price">
                                <Input
                                    name="totalNetPrice"
                                    placeholder="$$$$$$$"
                                />
                            </div>
                            <div className="column currency">
                                <Input
                                    name="currency"
                                    placeholder="USD"
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="tr__document-table--footer">
                    <p className="tr__link" onClick={handleAddDoc}>
                        <i className="icon-plus" />
                        <span>Add New</span>
                    </p>
                </div>
            </Collapse>
            <div className="d-flex align-items-center justify-content-between mtx2">
                <Button className="secondary outline"> View extracted information </Button>
                <Button
                    className="danger"
                >
                    Delete Invoice
                </Button>        
            </div>
            
        </div>
    )
};

DocumentTable.propTypes = {
    className: PropTypes.string
};