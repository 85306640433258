import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import { useForm } from 'react-hook-form';
import { Input, Dropdown, Button } from 'components';
import { getErrorMessage } from 'utils';
import { TrackShipmentContext } from 'contexts';
import {
    CARRIER_PAGE_SIZE,
    DESTINATION,
    LOCODE_PAGE_SIZE,
    ORIGIN,
    TAB_CONTAINER,
    TRACKER_BOOKING_BC
} from 'actions';

export const ContainerForm = ({ onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [carrierLoading, setCarrierLoading] = useState(false);
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);

    const { handleSubmit, register, errors, reset } = useForm();
    const { carriers, origins, destinations, doGetCarriers, doGetTrackers, doGetLocodes } = useContext(TrackShipmentContext);
    
    /*eslint-disable */
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
    }, [])
    /*eslint-enable */

    const handleSearch = (data) => {
        setLoading(true);
        const { carrier, containerNumber, origin, destination } = data
        doGetTrackers({
            type: TRACKER_BOOKING_BC,
            carrierNo: carrier,
            containerNo: containerNumber,
            originCode: origin,
            destCode: destination
        }, () => {
            setLoading(false);
            onSubmit(TAB_CONTAINER);
        }, () => {
            setLoading(false);
            reset();
        }, false);
    }

    const onSearchCarrier = (value) => {
        setCarrierLoading(true);
        doGetCarriers({
            limit: CARRIER_PAGE_SIZE,
            nameOrCode: value
        }, () => {
            setCarrierLoading(false);
        }, () => {
            setCarrierLoading(false);
        });
    }

    const onSearchOriginLocode = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }

    return (
        <form className="tr__container-form" onSubmit={handleSubmit(handleSearch)}>
            <div className="form-group">
                <div className="tr__container-form--group">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="carrier"
                        label="Select carrier"
                        error={!!errors.carrier}
                        errorMessage={getErrorMessage(errors.carrier, "carrier")}
                        refs={register({ required: true })}
                        options={carriers && carriers.length > 0 ? carriers.map(carrier => {
                            return {
                                key: carrier.scacCode,
                                value: `${carrier.carrierName} (${carrier.scacCode})`
                            }
                        }) : []}
                        placeholder="You can select any of the carrier..."
                        dropdownPosition="center"
                        onInputChange={onSearchCarrier}
                        searchLoading={carrierLoading}
                    />
                </div>
                <div className="tr__container-form--group">
                    <Input
                        label="Enter container number"
                        name="containerNumber"
                        placeholder="e.g. HMDU284299242"
                        refs={register({ required: true })}
                        error={!!errors.containerNumber}
                        errorMessage={getErrorMessage(errors.containerNumber, "Container Number")}
                    />
                </div>
                <div className="tr__container-form--group route">
                    <span className="route-icon">
                        <i className="icon-location" />
                        <i className="icon-location" />
                    </span>
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="origin"
                        label="Vessel route"
                        horizontalLabel="Origin:"
                        error={!!errors.origin}
                        errorMessage=" "
                        refs={register({ required: true })}
                        options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder="Search by country or port"
                        dropdownPosition="center"
                        onInputChange={onSearchOriginLocode}
                        searchLoading={originLoading}
                    />
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destination"
                        horizontalLabel="Destination:"
                        error={!!errors.destination}
                        errorMessage=" "
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        refs={register({ required: true })}
                        placeholder="Search by country or port"
                        dropdownPosition="center"
                        onInputChange={onSearchDestLocode}
                        searchLoading={destinationLoading}
                    />
                </div>
            </div>

            <div className="form-btns">
                <Button
                    isSubmitBtn={true}
                    loading={loading}
                >
                    Search shipment
                </Button>
            </div>
        </form>
    )
};