import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import { BookingRequestContext, ModalContext } from 'contexts';
import { 
    TabPane,
    Tab,
    Collaborators
} from 'components';

import { 
    CommentTab, 
    ActivityLogTab 
} from 'components';

import {
    ACTIVITIES_LOG,
    COMMENTS,
    MARKED,
    MARK_AS_ACTIVE_SHIPMENT
} from 'actions';

export const Reference = ({
    activityList,
    errors = [],
    register = () => {},
}) => {
    const { bookingRequest } = useContext(BookingRequestContext);
    const { setAddBRCollaborators } = useContext(ModalContext);

    return bookingRequest ? (
        <div className="tr_reference">
            <div className="tr_reference--header d-flex align-items-center justify-content-between">
                <Collaborators
                    members={bookingRequest ? bookingRequest.memberInfo : []}
                    skip={4}
                    onAdd={() => setAddBRCollaborators(true)}
                />
                <div className="tr__booking-request--status__badge">
                    <span className={`${bookingRequest.status.toLowerCase()}`}>{bookingRequest.status === MARK_AS_ACTIVE_SHIPMENT ? MARKED.toLowerCase() : bookingRequest.status.toLowerCase()}</span>
                </div>
            </div>
            <div className="tr_reference--form">
                <h2 className="h2 f-medium">Details</h2>
                <h3 className="h3 f-medium">Type of Shipment: <span>{bookingRequest.type}</span></h3>
                <div className="tr_reference--form__details">
                    <div className="row">
                        {bookingRequest.type === 'FCL' ? bookingRequest.containers && bookingRequest.containers.length > 0 && bookingRequest.containers.map((c, cIndex) => {
                            return (
                                <React.Fragment key={cIndex}>
                                    <div className="col-12 col-sm-6">
                                        <div className="reference--info mtx2">
                                            <label>Number of containers</label>
                                            <span>{c.numberOfContainer}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="reference--info mtx2">
                                            <label>Type of container</label>
                                            <span>{c.typeOfContainer}</span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }) : (
                            <>
                                <div className="col-12 col-sm-6">
                                    <div className="reference--info mtx2">
                                        <label>Number of package</label>
                                        <span>{bookingRequest.numberOfPackage}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="reference--info mtx2">
                                        <label>Number of pallet</label>
                                        <span>{bookingRequest.numberOfPallet}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="reference--info mtx2">
                                        <label>Total of volume</label>
                                        <span>{bookingRequest.totalOfVolume}</span>
                                    </div>
                                </div>
                            </>
                        )}
                        {bookingRequest.totalWeight && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>Total weight</label>
                                    <span>{bookingRequest.totalWeight.value} <span className="text-capitalize">{bookingRequest.totalWeight.weightUnit.toLowerCase()}</span></span>
                                </div>
                            </div>
                        )}
                        {bookingRequest.temperature && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>Temperature</label>
                                    <span>{bookingRequest.temperature.value} <span className="text-capitalize">{bookingRequest.temperature.unitOfMeasure === 'CENTIGRADE' ? 'Celsius' : bookingRequest.temperature.unitOfMeasure.toLowerCase()}</span></span>
                                </div>
                            </div>
                        )}
                        <div className="col-12 full-size">
                            <div className="reference--info mtx2">
                                <label>Remark</label>
                                <span>{bookingRequest.remark}</span>
                            </div>
                        </div>
                        {bookingRequest.loadingPort && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>Loading port</label>
                                    <span
                                        title={`${bookingRequest.loadingPort.city}, ${bookingRequest.loadingPort.country} (${bookingRequest.loadingPort.locode})`}
                                    >
                                        {`${bookingRequest.loadingPort.city}, ${bookingRequest.loadingPort.country} (${bookingRequest.loadingPort.locode})`}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-sm-6">
                            <div className="reference--info mtx2">
                                <label>Place of receipt</label>
                                <span title={bookingRequest.placeOfReceipt}>{bookingRequest.placeOfReceipt}</span>
                            </div>
                        </div>
                        <div className="col-12 full-size">
                            <div className="reference--info mtx2">
                                <label>Date of receipt</label>
                                <span>{bookingRequest.dateOfReceipt.fromDate !== bookingRequest.dateOfReceipt.toDate ? `${moment(bookingRequest.dateOfReceipt.fromDate).format('DD MMM YYYY')} - ` : ''}{moment(bookingRequest.dateOfReceipt.toDate).format('DD MMM YYYY')}</span>
                            </div>
                        </div>
                        {bookingRequest.dischargePort && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>Discharge port</label>
                                    <span
                                        title={`${bookingRequest.dischargePort.city}, ${bookingRequest.dischargePort.country} (${bookingRequest.dischargePort.locode})`}
                                    >
                                        {`${bookingRequest.dischargePort.city}, ${bookingRequest.dischargePort.country} (${bookingRequest.dischargePort.locode})`}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-sm-6">
                            <div className="reference--info mtx2">
                                <label>Place of Delivery</label>
                                <span title={bookingRequest.placeOfDelivery}>{bookingRequest.placeOfDelivery}</span>
                            </div>
                        </div>
                        {bookingRequest.type === 'FCL' ? (
                            <>
                                <div className="col-12 full-size">
                                    <div className="reference--info mtx2">
                                        <label>Empty P/U Address</label>
                                        <span>{bookingRequest.emptyAddress}</span>
                                    </div>
                                </div>
                                <div className="col-12 full-size">
                                    <div className="reference--info mtx2">
                                        <label>Drop Full Address</label>
                                        <span>{bookingRequest.dropAddress}</span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-12 half-size">
                                    <div className="reference--info mtx2">
                                        <label>Export Container Freight Station Address</label>
                                        <span>{bookingRequest.exportAddress}</span>
                                    </div>
                                </div>
                                <div className="col-12 half-size">
                                    <div className="reference--info mtx2">
                                        <label>Import Container Freight Station Address</label>
                                        <span>{bookingRequest.importAddress}</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <h2 className="h2 f-medium">Reference</h2>
                <div className="booking-number f-medium d-flex align-items-center">
                    <p>Booking Number</p>
                    <span>{bookingRequest.bookingRequestNumber || ''}</span>
                </div>
                
                <div className="reference-tab mtx4">
                    <Tab initialTab={0} onTabClick={tab => console.log(tab)}>
                        <TabPane tabName={COMMENTS} tabSlug={COMMENTS}>
                            <CommentTab
                                register={register}
                                errors={errors}
                            />
                        </TabPane>
                        <TabPane tabName={ACTIVITIES_LOG} tabSlug={ACTIVITIES_LOG}>
                            <ActivityLogTab
                                activityList={activityList}
                            />
                        </TabPane>
                    </Tab>
                </div>
            </div>
        </div>
    ) : '';
};

Reference.propTypes = {
    activityList: PropTypes.array,
    errors: PropTypes.any,
    register: PropTypes.func
};

