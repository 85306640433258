import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    getBookingRequests,
    createBookingRequest,
    getBookingRequest,
    updateBookingRequest,
    createDraftBookingRequest,
    updateBookingRequestStatus,
    updateBookingRequestCollaborators,
    getBookingRequestComments,
    addBookingRequestComment,
    OPERATION_FAILED_MESSAGE,
    BR_COMMENTS_PAGE_SIZE,
    CREATOR,
    FREIGHT_PARTNER,
    RECEIVED,
    CREATED,
    TEAM
} from 'actions';
import { HomeContext, AuthContext } from 'contexts';

export const BookingRequestContext = createContext();

export const BookingRequestContextProvider = ({ children }) => {
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
        setSearchMessage,
        setBrType,
        setBookingRequestTab
    } = useContext(HomeContext);
    const { loggedInUser, userInfo } = useContext(AuthContext);
    const [bookingRequests, setBookingRequests] = useState([]);
    const [bookingRequest, setBookingRequest] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [comments, setComments] = useState([]);
    const [currentPageComments, setCurrentPageComments] = useState(0);
    const [totalPagesComments, setTotalPagesComments] = useState(1);
    const [totalComments, setTotalComments] = useState(0);

    /*eslint-disable */
    useEffect(() => {
        if (bookingRequest && userInfo) {
            if (bookingRequest.freight) {
                let bookingRequestType = CREATOR;
                if (bookingRequest.freight.freightType === TEAM) {
                    bookingRequestType = userInfo.orgTeams && userInfo.orgTeams.some(org => org.teams.some(team => team.teamId === bookingRequest.freight.freightId)) ? FREIGHT_PARTNER : CREATOR;
                } else {
                    bookingRequestType = bookingRequest.freight.freightId === userInfo._id ? FREIGHT_PARTNER : CREATOR;
                }
                setBrType(bookingRequestType);
                
                setBookingRequestTab(bookingRequestType === FREIGHT_PARTNER ? RECEIVED : CREATED);
            }
        }
    }, [bookingRequest, userInfo])
    /*eslint-enable */

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetBookingRequests = async (params, callback, hasLoading = true) => {
        if (bookingRequests.length === 0 || currentPage !== params.page || params.isSearching) {
            setBookingRequests([]);
            try {
                if (hasLoading) setLoading(true);
                const newParams = {...params};
                delete newParams.isSearching;
                
                const response = await getBookingRequests(newParams, loggedInUser);
                const { data, status } = response;
                
                if (status === 200) {
                    setBookingRequests(data.items || []);
                    setTotalPages(data.totalPage);
                    setCurrentPage(data.currentPage);
                    if (callback) callback();
                    if (newParams.hasOwnProperty('limit')
                        && newParams.hasOwnProperty('page')
                        && newParams.hasOwnProperty('tab')
                        && Object.keys(newParams).length > 3
                    ) setSearchMessage('No search result');
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateBookingRequest = async (payload, callback) => {
        try {
            setLoading(true);

            const response = await createBookingRequest(payload, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                if (callback) callback(data);
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGetBookingRequest = async (bookingId, callback) => {
        if (bookingId) {
            try {
                setLoading(true);

                const response = await getBookingRequest(bookingId, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    setBookingRequest(data);

                    if (callback) callback();
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doUpdateBookingRequest = async (bookingId, payload, callback) => {
        try {
            setLoading(true);
            
            const response = await updateBookingRequest(bookingId, payload, loggedInUser);
            const { data, status } = response;
            
            if (status === 200) {
                if (callback) callback(data);
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doCreateDraftBookingRequest = async (payload, callback) => {
        try {
            setLoading(true);

            const response = await createDraftBookingRequest(payload, loggedInUser);
            const { status } = response;

            if (status === 200) {
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doUpdateBookingRequestStatus = async (bookingId, payload, callback, errorCallback) => {
        if (bookingId) {
            try {
                setLoading(true);
                
                const response = await updateBookingRequestStatus(bookingId, payload, loggedInUser);
                const { status } = response;
    
                if (status === 200) {
                    setBookingRequest(old => {
                        return {
                            ...old,
                            status: payload.status
                        }
                    })
                    if (callback) callback(bookingRequest.referenceNumber);
                }
    
                setLoading(false);
            } catch (error) {
                handleException(error);
                if (errorCallback) errorCallback();
            }
        }
    }

    const doUpdateBookingRequestCollaborators = async (bookingId, payload, callback) => {
        if (bookingId) {
            try {
                setLoading(true);
                
                const response = await updateBookingRequestCollaborators(bookingId, payload, loggedInUser);
                const { status } = response;
    
                if (status === 200) {
                    if (callback) callback();
                }
    
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetBookingRequestComments = async (bookingId, params, callback) => {
        if (bookingId) {
            try {
                setLoading(true);
                const newParams = {...params};
                delete newParams.isSearching;

                const response = await getBookingRequestComments(bookingId, newParams, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    if (params.page !== currentPageComments) setComments(oldComments => [...oldComments, ...(data.items || [])]);
                    else setComments(data.items || [])
                    setTotalPagesComments(data.totalPage);
                    setCurrentPageComments(data.currentPage);
                    setTotalComments(data.totalItems);

                    if (callback) callback();
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doAddBookingRequestComment = async (bookingId, payload, callback) => {
        try {
            setLoading(true);

            const response = await addBookingRequestComment(bookingId, payload, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                if (data.length > 0) {
                    doGetBookingRequestComments(bookingId, {
                        page: 0,
                        limit: BR_COMMENTS_PAGE_SIZE,
                        isSearching: true
                    }, () => {
                        if (callback) callback();
                    })
                }
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    return(
        <BookingRequestContext.Provider
            value={{
                currentPage,
                bookingRequests,
                bookingRequest,
                comments,
                totalPages,
                totalPagesComments,
                currentPageComments,
                totalComments,
                doGetBookingRequests,
                doCreateBookingRequest,
                setCurrentPage,
                doGetBookingRequest,
                doUpdateBookingRequest,
                doCreateDraftBookingRequest,
                doUpdateBookingRequestStatus,
                doUpdateBookingRequestCollaborators,
                doGetBookingRequestComments,
                doAddBookingRequestComment,
                setBookingRequest
            }}
        >
            { children }
        </BookingRequestContext.Provider>
    );
};