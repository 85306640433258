import React, { useContext } from 'react';
import './index.scss';
import {
    Modal,
    Input
} from 'components';
import { HomeContext, ManagementContext, ModalContext } from 'contexts';
import { useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils';
import { useLocation } from 'react-router-dom';

export const EditSection = ({ open = false, onCancel, isEdit= false }) => {
    const { setNotificationMessage, sectionName, sectionId } = useContext(HomeContext);
    const { setIsEditSection, setEditSection } = useContext(ModalContext);
    const { doCreateSection, doUpdateSection, doRomoveSection, doGetTaskManagements } = useContext(ManagementContext);
    const { handleSubmit, register, reset, errors } = useForm();
    const handleCloseModal = () => {
        onCancel();
        setIsEditSection(false);
    }
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];

    const handleEditSection = (data) => {
        const { sectionName } = data;
        const payload = {
            sectionName : sectionName
        }
        doUpdateSection(sectionId, payload, () => {
            setEditSection(false);
            doGetTaskManagements(shipmentId);
            setNotificationMessage(`
            <p> Section has been updated successfully!</p>
        `);
        });
    }

    const doAddSection = (data) => {
        const { sectionName } = data;
        const payload = {
            sectionType: 'USER_SECTION',
            sectionName: sectionName
        }
        doCreateSection(shipmentId, payload, () => {
            reset();
            setEditSection(false);
            setNotificationMessage(`
            <p> Section has been added successfully!</p>
        `);
        });
    }

    const handleDeleteSection = () => {
        doRomoveSection(shipmentId, sectionId, () => {
            setEditSection(false);
            setNotificationMessage(`
            <p> Section has been delete successfully!</p>
        `);
        });
    }
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={handleCloseModal}
            title={isEdit ? 'Edit section' : "Add new section"}
            btnClasses="justify-content-between"
            submitButton={true}
            onSubmitClick={isEdit ? handleSubmit(handleEditSection) : handleSubmit(doAddSection)}
            submitButtonLabel={isEdit ? "Save Change" : "Add"}
            cancelButton={isEdit}
            onCancelClick={isEdit ? handleSubmit(handleDeleteSection) : () => {}}
            cancelButtonLabel="Delete Section"
            cancelButtonType="primary"
            cancelButtonClassNames="outline"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit()}>
                    {children}
                </form>
            )}
        >
            <h4 className="h4 f-medium mbx2">Change Section Name</h4>
            <div className="tr__section-name">
                <Input
                    label="Section name"
                    className="mbx3"
                    name="sectionName"
                    refs={register({required: true})}
                    defaultValue={isEdit ? sectionName : ''}
                    error={!!errors.sectionName}
                    errorMessage={getErrorMessage(errors.sectionName, "section name")}
                />
            </div>
        </Modal>
    )
};