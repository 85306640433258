import React, { createContext, useContext, useState } from 'react'
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';
import { 
    OPERATION_FAILED_MESSAGE,
    getShipmentInfo,
    updateShipmentInfo,
    updateShipmentNote,
 } from 'actions';
export const ShipmentInfoContext = createContext();

export const ShipmentInfoContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const [shipmentInfo, setShipmentInfo] = useState({});
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
    } = useContext(HomeContext);

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doUpdateShipmentInfo = async (shipmentId, payload, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
                const response = await updateShipmentInfo(shipmentId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    await doGetShipmentInfo(shipmentId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetShipmentInfo = async (shipmentId, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
                const response = await getShipmentInfo(shipmentId, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setShipmentInfo(data || {});
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doUpdateShipmentNote = async (shipmentId, note, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
                const response = await updateShipmentNote(shipmentId, { note }, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    
    return (
        <ShipmentInfoContext.Provider
            value={{
                shipmentInfo,
                setShipmentInfo,
                doGetShipmentInfo,
                doUpdateShipmentInfo,
                doUpdateShipmentNote,
            }}
        >
            { children}
        </ShipmentInfoContext.Provider>
    )
}
