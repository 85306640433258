import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Button
} from 'components';
import { 
    HomeContext, 
    ModalContext, 
    ManagementContext, 
    CollaboratorsContext, 
    AuthContext
} from 'contexts';
import { CANCELED, COMPLETE } from 'actions';
import { useParams } from 'react-router-dom';

export const TaskManagementButtons = ({ className = '' }) => {
    const { userInfo } = useContext(AuthContext);
    const { isArchive, setSectionName } = useContext(HomeContext);
    const { setEditSection, setConfirmStatusShipment, setConfirmSaveTemplate } = useContext(ModalContext);
    const { setStatusShipment } = useContext(ManagementContext);
    const { collaborators } = useContext(CollaboratorsContext);
    const { shipmentId } = useParams();

    const changeStatus = (status) => {
        setConfirmStatusShipment(true);
        setStatusShipment({
            shipmentId,
            status
        })
    }

    const handleSaveAsTemplate = () => {
        setConfirmSaveTemplate(true);
    }
    
    return (
        <div className={`tr__task-management--buttons d-flex align-items-center justify-content-between ${className}`}>
            <div className="left d-flex align-items-center">
                {!isArchive && userInfo && userInfo._id && (
                    <Button
                        className="icon"
                        type="primary"
                        icon="icon-plus"
                        onClick={() => {
                            setEditSection(true);
                            setSectionName('');
                        }}
                        disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                    >
                        Add New Section
                    </Button>
                )}
                <Button
                    className="outline"
                    type="secondary"
                    onClick={handleSaveAsTemplate}
                    disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                >
                    Save as Template
                </Button>
            </div>
            {!isArchive && userInfo && userInfo._id && (
                <div className="right d-flex align-items-center">
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={() => changeStatus(CANCELED)}
                        disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                    >
                        Cancel This Shipment
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => changeStatus(COMPLETE)}
                        disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                    >
                        Mark as Complete
                    </Button>
                </div>
            )}
        </div>
    )
};

TaskManagementButtons.propTypes = {
    className: PropTypes.string
};