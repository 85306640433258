import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import {
  Modal,
} from 'components';
import { CollaboratorsContext, HomeContext } from 'contexts';
import { useLocation } from 'react-router-dom';
export const ConfirmRemoveCollaborator = ({
  open = false,
  onCancel,
  onSubmit = () => { },
  title = '',
  message = '',
}) => {
  const { collaboratorId } = useContext(HomeContext);
  const { collaborators, doRemoveCollaborator, setCollaborators } = useContext(CollaboratorsContext);
  const location = useLocation();
  const pathnameArr = location.pathname.split('/');
  const shipmentId = pathnameArr[pathnameArr.length - 1];

  const handleSubmit = () => {
    const payload = {
      id: collaboratorId,
    }
    doRemoveCollaborator(shipmentId, payload, () => {
      const filterCollaborators = collaborators.filter(collaborator => collaborator._id !== collaboratorId);
      setCollaborators(filterCollaborators);
      onCancel();
    })
  }
  return (
    <Modal
      open={open}
      className="tr__collaborator-confirmation"
      onCancel={onCancel}
      title={title}
      btnClasses="justify-content-center"
      submitButton
      submitButtonLabel="Yes"
      onSubmitClick={handleSubmit}
      cancelButton={true}
      cancelButtonLabel="No"
      cancelButtonClassNames="outline secondary"
      onCancelClick={onCancel}
      isBackDropClickable={false}
    >
      <div className="text-center remove-collaborator">
        <h6 className="h6 mtbx2 f-medium">{message}</h6>
      </div>
    </Modal>
  )
}
ConfirmRemoveCollaborator.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
};
