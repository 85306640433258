import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const DocumentHeader = ({ className = '', isDocuments = false }) => {
    return (
        <div className={`tr__document-header f-medium d-flex align-items-center justify-content-between table-header ${className} ${isDocuments ? 'document' : ''}`}>
            {!isDocuments && (
                <>
                    <div className="column name">
                        <p>Task</p>
                    </div>
                    <div className="column uploader">
                        <p>Uploaded</p>
                    </div>
                    <div className="column assignee">
                        <p>{isDocuments ? <>&nbsp;</> : 'Assignee'}</p>
                    </div>
                    <div className="column btns">&nbsp;</div>
                 </>
            )}
            {isDocuments && (
                <>
                    <div className="column name">
                        <p>Document</p>
                    </div>
                    <div className="column uploader">
                        <p>Uploaded</p>
                    </div>
                    <div className="column type">
                        <p>Document Type</p>
                    </div>
                    <div className="column btns">&nbsp;</div>
                </> )
            }
        </div>
    )
};

DocumentHeader.propTypes = {
    className: PropTypes.string
};
