import { MembersAvatar } from 'components/pages/Organizations';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { HomeContext } from 'contexts';

export const Collaborators = ({ members = [], skip, onAdd, label = "Assign", onAvatarsClick, done }) => {
    const { isArchive } = useContext(HomeContext);
    return (
        <div className="tr__collaborators d-flex align-items-center">
            {!isArchive && (
                <p className={`tr__link icon mrx2 ${done ? 'disabled' : ''}`} onClick={onAdd}>
                    <i className="icon-plus mrx1" />
                    {members.length === 0 && label && (
                        <span>{label}</span>
                    )}
                </p>
            )}
            {members.length > 0 && (
                <MembersAvatar
                    className="small"
                    members={members}
                    skip={skip}
                    onClick={onAvatarsClick}
                />
            )}
        </div>
    )
};

Collaborators.propTypes = {
    members: PropTypes.array,
    skip: PropTypes.number,
    onAdd: PropTypes.func,
    onAvatarsClick: PropTypes.func,
    label: PropTypes.string,
    done: PropTypes.bool,
};