import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Input,
    Collapse,
    EditShipmentInfoDatepicker,
    EditShipmentInfoInput
} from 'components';
import moment from 'moment';

export const ShippingDetails = ({ 
    shippingDetails,
    register = () => {},
    watch = () => {},
    editShipmentInfo
}) => {
    const [date,setDate] = useState(shippingDetails && shippingDetails.bookingDate ? moment(shippingDetails.bookingDate).format('DD MMM YYYY') : '');

    const getValueByKey = (key) => {
        return shippingDetails ? shippingDetails[key] : ''
    }
   
    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Shipping Details" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <Input
                                        className="mtx2"
                                        name="shipmentMode"
                                        label="Shipment mode"
                                        defaultValue={getValueByKey('shipmentMode')}
                                        viewOnly={true}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        className="mtx2"
                                        name="shipmentType"
                                        label="Shipment type"
                                        defaultValue={getValueByKey('shipmentType')}
                                        viewOnly={true}
                                    />
                                </div>
                                {editShipmentInfo && (
                                    <React.Fragment>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoDatepicker
                                                className="mtx2"
                                                name="bookingDate"
                                                label="Booking date"
                                                refs={register()}
                                                value={date}
                                                onDatesChange={(date) => setDate(moment(date).format('DD MMM YYYY'))}
                                                originalvalue={shippingDetails && shippingDetails.bookingDate ? moment(shippingDetails.bookingDate).format('DD MMM YYYY') : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={date}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="shipper"
                                                label="Shipper"
                                                refs={register()}
                                                defaultValue={getValueByKey('shipper')}
                                                originalvalue={getValueByKey('shipper')}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('shipper',getValueByKey('shipper'))}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="eta"
                                                label="ETA"
                                                refs={register()}
                                                defaultValue={getValueByKey('eta')}
                                                originalvalue={getValueByKey('eta')}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('eta',getValueByKey('eta'))}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="etd"
                                                label="ETD"
                                                refs={register()}
                                                defaultValue={getValueByKey('etd')}
                                                originalvalue={getValueByKey('etd')}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('etd',getValueByKey('etd'))}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

ShippingDetails.propTypes = {
    shippingDetails: PropTypes.object,
    editShipmentInfo: PropTypes.bool,
    register: PropTypes.func,
    watch: PropTypes.func
};
