import React from 'react';
import PropTypes from 'prop-types';

import {
    EditShipmentInfoInput,
    Collapse
} from 'components';

export const EditTranshipmentInfo = ({ register, transhipmentInfo, watch, editShipmentInfo, transhipments, setTranshipments }) => {

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Transhipment Information" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                {transhipments.map(transhipment => (
                                    <React.Fragment key={transhipment}>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`tsPort${transhipment}`}
                                                label="TS port"
                                                placeholder="BN823592"
                                                refs={register()}
                                                maxLength={50}
                                                defaultValue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsPort
                                                    ? transhipmentInfo[transhipment].tsPort 
                                                    : ''
                                                }
                                                originalvalue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsPort
                                                    ? transhipmentInfo[transhipment].tsPort 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`tsPort${transhipment}`,
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsPort
                                                        ? transhipmentInfo[transhipment].tsPort 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`tsNextPort${transhipment}`}
                                                label="TS next port"
                                                placeholder="BN823592"
                                                refs={register()}
                                                defaultValue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsNextPort
                                                    ? transhipmentInfo[transhipment].tsNextPort 
                                                    : ''
                                                }
                                                originalvalue={transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsNextPort
                                                    ? transhipmentInfo[transhipment].tsNextPort 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`tsNextPort${transhipment}`,
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsNextPort
                                                        ? transhipmentInfo[transhipment].tsNextPort 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`tsVessel${transhipment}`}
                                                label="TS vessel"
                                                refs={register()}
                                                defaultValue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVessel
                                                    ? transhipmentInfo[transhipment].tsVessel 
                                                    : ''
                                                }
                                                originalvalue={transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVessel
                                                    ? transhipmentInfo[transhipment].tsVessel 
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`tsVessel${transhipment}`,
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVessel
                                                        ? transhipmentInfo[transhipment].tsVessel 
                                                        : ''
                                                    )
                                            }   
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`tsVesselIMO${transhipment}`}
                                                label="TS vessel IMO"
                                                refs={register()}
                                                defaultValue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVesselIMO 
                                                    ? transhipmentInfo[transhipment].tsVesselIMO
                                                    : ''
                                                }
                                                originalvalue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVesselIMO 
                                                    ? transhipmentInfo[transhipment].tsVesselIMO
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`tsVesselIMO${transhipment}`,
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVesselIMO 
                                                        ? transhipmentInfo[transhipment].tsVesselIMO
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`tsETD${transhipment}`}
                                                label="TS ETD"
                                                refs={register()}
                                                defaultValue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETD 
                                                    ? transhipmentInfo[transhipment].tsETD 
                                                    : ''
                                                }
                                                originalvalue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETD 
                                                    ? transhipmentInfo[transhipment].tsETD
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`tsETD${transhipment}`,
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETD 
                                                        ? transhipmentInfo[transhipment].tsETD : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name={`tsETA${transhipment}`}
                                                label="TS ETA"
                                                refs={register()}
                                                defaultValue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETA 
                                                    ? transhipmentInfo[transhipment].tsETA
                                                    : ''
                                                }
                                                originalvalue={
                                                    transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETA 
                                                    ? transhipmentInfo[transhipment].tsETA
                                                    : ''
                                                }
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch(`tsETA${transhipment}`,
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETA 
                                                        ? transhipmentInfo[transhipment].tsETA 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                                <div className="h4 f-medium add-container mtx4 align-items-center" onClick={() => setTranshipments(old => [...old, old.length])}>
                                    <i className="icon-plus mrx1"/>
                                    <span>Add New Transhipment</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditTranshipmentInfo.propTypes = {
    register: PropTypes.func,
    transhipmentInfo: PropTypes.array,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool,
    transhipments: PropTypes.array, 
    setTranshipments: PropTypes.func,
};
