import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import './index.scss';
import wordIcon from 'assets/images/file-icons/word.svg';
import excelIcon from 'assets/images/file-icons/excel.svg';
import pdfIcon from 'assets/images/file-icons/pdf.svg';

export const FilePreview = (
    {
        file,
        fileName,
        fileExtension,
    }
) => {
    const [downloadUrl, setDownloadUrl] = useState(file);
    const [icon, setIcon] = useState(file);

    useEffect(() => {
        switch (fileExtension) {
            case 'docx':
            case 'doc':
                setIcon(wordIcon);
                break;
            case 'xlsx':
            case 'xls':
            case 'csv':
                setIcon(excelIcon);
                break;
            case 'pdf':
                setIcon(pdfIcon);
                break;
            default:
                break;
        }
    }, [fileExtension]);

    useEffect(() => {
        if (file) {
            setDownloadUrl(file);
        }
    }, [file]);

    return (
        <a
            className='tr__file-preview'
            download
            href={downloadUrl}
            style={{
                display: 'inline',
            }}
        >
            <img
                className='file-icon'
                src={icon || downloadUrl}
                alt={fileName}
            />
        </a>
    );
}

FilePreview.propTypes = {
    file: PropTypes.string,
    fileName: PropTypes.string,
    fileExtension: PropTypes.string,
}
