import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';

export const Invoice = ({ invoices = [] }) => {
    return <InfoBlock
        className="tr__details-box"
        title="Invoice"
    >
         <InfoRow>
            <div className="col-12 col-sm-3">
                <div className='row'>
                    <InfoCell
                        className="col-12"
                        label="Invoice number"
                        value={invoices ? invoices.toString() : ''}
                    />
                </div>
            </div>
        </InfoRow>
    </InfoBlock>
};

Invoice.propTypes = {
    invoices: PropTypes.array
};
