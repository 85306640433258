import React, { createContext, useContext, useState } from 'react'

import { AuthContext } from './AuthContext';
import { HomeContext } from './HomeContext';
import { 
    OPERATION_FAILED_MESSAGE,
    getTasks,
    getTaskPartners, 
    assignTaskPartners, 
    changeStatusTask, 
    addShipmentSection, 
    addShipmentTask, 
    updateShipmentSection, 
    deleteShipmentSection,
    updateShipmentTask,
    removeTaskPartner,
    changeStatusShipment,
    deleteShipmentTask,
    saveAsTemplate
 } from 'actions';

export const ManagementContext = createContext();

export const ManagementContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const [taskManagements, setTaskManagements] = useState([]);
    const [statusShipment, setStatusShipment] = useState({});
    const [taskPartners, setTaskPartners] = useState([]);
    const [newEstSection, setNewEstSection] = useState([]);
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
    } = useContext(HomeContext);

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doChangeStatusShipment = async (shipmentId, params, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
                const response = await changeStatusShipment(shipmentId, params, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if(callback) callback();
                }
                setLoading(false); 
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetTaskManagements = async (shipmentId, params, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
                const response = await getTasks(shipmentId, params, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setTaskManagements(data.items);
                    if (callback) callback();
                }
                setLoading(false); 
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateSection = async (shipmentId, payload, callback) => {
        if(shipmentId) { 
            try {
                setLoading(true);
                const response = await addShipmentSection(shipmentId, payload, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setNewEstSection(data)
                    doGetTaskManagements(shipmentId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetTaskPartners = async (taskId, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await getTaskPartners(taskId, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setTaskPartners(data || []);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    
    const doCreateTask = async (shipmentId, sectionId, payload, callback) => {
        if(sectionId) { 
            try {
                setLoading(true);
                const response = await addShipmentTask(sectionId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetTaskManagements(shipmentId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doUpdateSection = async (sectionId, payload, callback) => {
        if(sectionId) { 
            try {
                setLoading(true);
                const response = await updateShipmentSection(sectionId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
 
    const doDeleteTask = async (shipmentId, taskId, callback) => {
        if(taskId) { 
            try {
                setLoading(true);
                const response = await deleteShipmentTask(taskId, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetTaskManagements(shipmentId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    } 

    const doAssignTaskPartner = async (shipmentId, taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await assignTaskPartners(taskId, payload, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setTaskPartners(data);
                    doGetTaskManagements(shipmentId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doRomoveSection = async (shipmentId, sectionId, callback) => {
        if(sectionId) { 
            try {
                setLoading(true);
                const response = await deleteShipmentSection(sectionId, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetTaskManagements(shipmentId);
                    if (callback) callback();

                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }
    const doChangeStatusTask = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await changeStatusTask(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doUpdateShipmentTask = async (shipmentId, taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await updateShipmentTask(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetTaskManagements(shipmentId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    const doRemoveTaskPartner = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await removeTaskPartner(taskId, payload, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setTaskPartners(data);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doSaveAsTemplate= async (shipmentId, params, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
                const response = await saveAsTemplate(shipmentId, params, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    
    return (
        <ManagementContext.Provider
            value={{
                taskManagements,
                taskPartners,
                statusShipment,
                newEstSection,
                doDeleteTask,
                doCreateSection,
                doCreateTask,
                doUpdateSection,
                doRomoveSection,
                doGetTaskManagements,
                doGetTaskPartners,
                doAssignTaskPartner,
                doChangeStatusTask,
                doUpdateShipmentTask,
                doRemoveTaskPartner,
                doChangeStatusShipment,
                setStatusShipment,
                doSaveAsTemplate
            }}
        >
            { children}
        </ManagementContext.Provider>
    )
}