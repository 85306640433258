import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import {
    Modal,
} from 'components';
import { IssueContext } from 'contexts';
import { useLocation } from 'react-router-dom';
export const ConfirmStatusIssue = ({
    open = false,
    onCancel,
    onSubmit = () => {},
    title='',
    message = '',
}) => {
    const { statusIssue, doChangeStatus } = useContext(IssueContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 3];
    
    const handleSubmit = () => {
        const payload = {
            status: statusIssue.status
        }
        doChangeStatus(shipmentId, statusIssue.id, payload, () => {
            onCancel();
        })
    }
    return (
        <Modal
            open={open}
            className="tr__issue-confirmation"
            onCancel={onCancel}
            title={title}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Yes"
            onSubmitClick={handleSubmit}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center change-status-issue">
                <h6 className="h6 mtbx2 f-medium">{message}</h6>
            </div>
        </Modal>
    )
}
ConfirmStatusIssue.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string
};
