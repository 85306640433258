import React, { useContext } from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import './index.scss';
import { AuthContext, ModalContext } from 'contexts';
import avatar from 'assets/images/avatar.png';
import logo from 'assets/images/logo.png';
import {
    UserDropdown,
    Dropdown,
    Sidebar,
    NotificationDropdown,
    DeleteConfirmation
} from 'components';
import {
    sampleOptions,
    userOptions,
    homeLinks,
    notifications,
    noticeOptions,
    removeAll
} from 'utils';
import { LOGIN_URL } from 'actions';

export const Header = () => {
    const history = useHistory();
    // const { userInfo, setUserInfo, setLoggedInUser } = useContext(AuthContext);
    const { userInfo } = useContext(AuthContext);
    const { signOut, setSignOut } = useContext(ModalContext);

    const handleChangeRoute = option => {
        if (option.key === 5) {
            setSignOut(true);
        } else if (option.key === 6) {
            // setUserInfo(null);
            // setLoggedInUser(null);
            removeAll();
            if (window) window.location = '/';
            else if (option.path) {
                history.push(option.path);
            }
        } else {
            if (option.path) history.push(option.path);
        }
    }

    return (
        <div className="tr__header--wrapper">
            <div className="tr__header">
                <div className="tr__header--logo text-center">
                    <NavLink to="/">
                        <img src={logo} alt="Trames Client" />
                    </NavLink>
                </div>
                <div className="tr__header--menu">
                    <Sidebar links={homeLinks} />
                </div>
                <div className="tr__header--search">
                    <Dropdown
                        mode="input"
                        icon="icon-search"
                        options={sampleOptions}
                        placeholder="Search all your active shipments"
                        dropdownPosition="center"
                    />
                </div>
                <div className="tr__header--notice">  
                    <Dropdown
                        mode="icon"
                        options={sampleOptions}
                        dropdownPosition="right"
                        render={(options, onChange) => <NotificationDropdown
                                notifications={notifications}
                                options={noticeOptions}
                            />
                        }
                    >
                        <div className="notification">
                            <i className="icon-bell" />
                            <span className="badge">3</span>
                        </div>
                    </Dropdown>
                </div>
                <div className="tr__header--user d-flex">
                    <Dropdown
                        mode="icon"
                        options={userOptions}
                        dropdownPosition="right"
                        onChange={handleChangeRoute}
                        render={(options, onChange) => <UserDropdown
                                options={options}
                                onChange={onChange}
                                onOrgSelect={org => console.log(org)}
                            />
                        }
                    >
                        {userInfo && (
                            <>
                                <img src={userInfo.avatar || avatar} alt="Avatar" />
                                <p>{userInfo.name}</p>
                            </>
                        )}
                        <i className="icon-chevron-down" />
                    </Dropdown>
                
                </div>
            </div>

            <DeleteConfirmation
                open={signOut}
                onCancel={() => setSignOut(false)}
				onSubmit={() => handleChangeRoute({
                    key: 6,
                    path: LOGIN_URL
                })}
                title="Sign Out"
				message={`Are you sure you want to sign out?`}
            />
        </div>
    );
};