import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    EditShipmentInfoInput,
    Collapse,
    EditShipmentInfoDropdown
} from 'components';
import { TrackShipmentContext } from 'contexts';
import { CARRIER_PAGE_SIZE } from 'actions';

export const EditBookingDetails = ({ register, bookingDetails, watch, editShipmentInfo  }) => {
    const { carriers, doGetCarriers } = useContext(TrackShipmentContext);
    const [scacCode, setScacCode] = useState();
    const [searchLoading, setSearchLoading] = useState(false);
    const confirmationNoValues = bookingDetails && bookingDetails.confirmationNo ? bookingDetails.confirmationNo.toString() : '';
    const newConfirmationNo = watch ? watch("bookingConfirmation",confirmationNoValues) : '';
    const masterBoLValues = bookingDetails && bookingDetails.masterBoL ? bookingDetails.masterBoL.toString() : '';
    const newMasterBoL = watch ? watch("masterBillOfLading",masterBoLValues) : '';
    const houseBoLValues = bookingDetails && bookingDetails.houseBoL ? bookingDetails.houseBoL.toString() : '';
    const newHouseBill = watch ? watch("houseBill",houseBoLValues) : '';
    const [carrierName, setCarrierName] = useState(bookingDetails && bookingDetails.carrierName ? bookingDetails.carrierName : '');

    
    /*eslint-disable */
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
    }, [])

    useEffect(() => {
        if (bookingDetails && bookingDetails.scacCode) setScacCode(bookingDetails.scacCode)
    }, [bookingDetails])
    /*eslint-enable */

    const onSearchCarrier = (value) => {
        setSearchLoading(true);
        setScacCode();
        
        doGetCarriers({
            limit: CARRIER_PAGE_SIZE,
            nameOrCode: value.trim(),
        }, () => {
            setSearchLoading(false);
        }, () => {
            setSearchLoading(false);
        }, true);
    }

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Booking Details" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoDropdown
                                        className="mtx2"
                                        icon="icon-chevron-down"
                                        iconPosition="right"
                                        mode="input"
                                        name="carrierName"
                                        label="Carrier name"
                                        refs={register()}
                                        options={carriers && carriers.length > 0 ? carriers.map(carrier => {  
                                            return {
                                                key: carrier.carrierName,
                                                value: carrier.carrierName,
                                                scacCode: carrier.scacCode
                                            }
                                        }) : []}
                                        placeholder="You can select any of the carrier..."
                                        dropdownPosition="center"
                                        onInputChange={onSearchCarrier}
                                        searchLoading={searchLoading}
                                        onChange={carrier => {setScacCode(carrier.scacCode); setCarrierName(carrier.value)}}
                                        defaultValue={bookingDetails ? bookingDetails.carrierName : ''}
                                        originalvalue={bookingDetails ? bookingDetails.carrierName : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={carrierName}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        label="SCAC Code"
                                        value={scacCode}
                                        viewOnly={true}
                                        originalvalue={bookingDetails ? bookingDetails.scacCode : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={scacCode}
                                    />
                                    <input
                                        name="scacCode"
                                        type="hidden"
                                        ref={register()}
                                        value={scacCode}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="bookingConfirmation"
                                        refs={register()}
                                        label="Booking confirmation no."
                                        placeholder="BN823592"
                                        maxLength={50}
                                        defaultValue={confirmationNoValues}
                                        originalvalue={confirmationNoValues}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newConfirmationNo}
                                   />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="masterBillOfLading"
                                        refs={register()}
                                        label="Master bill of lading"
                                        placeholder="BN823592"
                                        maxLength={50}
                                        defaultValue={masterBoLValues}
                                        originalvalue={masterBoLValues}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newMasterBoL}
                                    />
                                </div>
                                <div className="col-12 col-sm-12">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="houseBill"
                                        refs={register()}
                                        label="House bill of lading"
                                        placeholder="BN823592, BN823592, BN823592"
                                        maxLength={50}
                                        defaultValue={houseBoLValues}
                                        originalvalue={houseBoLValues}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newHouseBill}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditBookingDetails.propTypes = {
    register: PropTypes.func,
    bookingDetails: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool
};
