import React from 'react';
import PropTypes from 'prop-types';

import {
    Collapse,
} from 'components';
import { EditShipmentInfoInput } from 'components';

export const EditContactDetails = ({
    register = () => {},
    contactDetails,
    watch = () => {},
    editShipmentInfo
}) => {

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Contact Details" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="contactShipperAdd"
                                        label="Shipper address"
                                        refs={register()}
                                        defaultValue={contactDetails && contactDetails.shipperAddress ? contactDetails.shipperAddress : ''}
                                        originalvalue={contactDetails && contactDetails.shipperAddress ? contactDetails.shipperAddress : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('contactShipperAdd',contactDetails && contactDetails.shipperAddress ? contactDetails.shipperAddress : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="consignee"
                                        label="Consignee"
                                        refs={register()}
                                        defaultValue={contactDetails && contactDetails.consignee ? contactDetails.consignee : ''}
                                        originalvalue={contactDetails && contactDetails.consignee ? contactDetails.consignee : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('consignee',contactDetails && contactDetails.consignee ? contactDetails.consignee : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="consigneeAddress"
                                        label="Consignee address"
                                        refs={register()}
                                        defaultValue={contactDetails && contactDetails.consigneeAddress ? contactDetails.consigneeAddress : ''}
                                        originalvalue={contactDetails && contactDetails.consigneeAddress ? contactDetails.consigneeAddress : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('consigneeAddress',contactDetails && contactDetails.consigneeAddress ? contactDetails.consigneeAddress : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="notifyParty"
                                        label="Notify party"
                                        refs={register()}
                                        defaultValue={contactDetails && contactDetails.notifyParty ? contactDetails.notifyParty : ''}
                                        originalvalue={contactDetails && contactDetails.notifyParty ? contactDetails.notifyParty : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('notifyParty',contactDetails && contactDetails.notifyParty ? contactDetails.notifyParty : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="notifyPartyAddress"
                                        label="Notify party address"
                                        refs={register()}
                                        defaultValue={contactDetails && contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : ''}
                                        originalvalue={contactDetails && contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('notifyPartyAddress',contactDetails && contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : '')}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="serviceContractNumber"
                                        label="Service contract number"
                                        refs={register()}
                                        defaultValue={contactDetails && contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : ''}
                                        originalvalue={contactDetails && contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch('serviceContractNumber',contactDetails && contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : '')}
                                    />
                                </div> 
                                <div className="mtx4">
                                    Contact Information
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="shipperContact"
                                        label="Shipper contact"
                                        refs={register()}
                                        defaultValue={
                                            contactDetails && contactDetails.shipperContact 
                                            ? contactDetails.shipperContact 
                                            : ''
                                        }
                                        originalvalue={
                                            contactDetails && contactDetails.shipperContact 
                                            ? contactDetails.shipperContact 
                                            : ''
                                        }
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('shipperContact',
                                                contactDetails && contactDetails.shipperContact 
                                                ? contactDetails.shipperContact 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="shipperFax"
                                        label="Shipper fax"
                                        refs={register()}
                                        defaultValue={
                                            contactDetails && contactDetails.shipperFax 
                                            ? contactDetails.shipperFax 
                                            : ''
                                        }
                                        originalvalue={
                                            contactDetails && contactDetails.shipperFax 
                                            ? contactDetails.shipperFax 
                                            : ''
                                        }
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('shipperFax',
                                                contactDetails && contactDetails.shipperFax 
                                                ? contactDetails.shipperFax 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="consigneeContact"
                                        label="Consignee contact"
                                        refs={register()}
                                        defaultValue={
                                            contactDetails && contactDetails.consigneeContact 
                                            ? contactDetails.consigneeContact 
                                            : ''
                                        }
                                        originalvalue={
                                            contactDetails && contactDetails.consigneeContact 
                                            ? contactDetails.consigneeContact 
                                            : ''
                                        }
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('consigneeContact',
                                                contactDetails && contactDetails.consigneeContact 
                                                ? contactDetails.consigneeContact 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="consigneeFax"
                                        label="Consignee fax"
                                        refs={register()}
                                        defaultValue={
                                            contactDetails && contactDetails.consigneeFax 
                                            ? contactDetails.consigneeFax 
                                            : ''
                                        }
                                        originalvalue={
                                            contactDetails && contactDetails.consigneeFax 
                                            ? contactDetails.consigneeFax 
                                            : ''
                                        }
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('consigneeFax',
                                                contactDetails && contactDetails.consigneeFax 
                                                ? contactDetails.consigneeFax 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="notifyContact"
                                        label="Notify contact"
                                        refs={register()}
                                        defaultValue={
                                            contactDetails && contactDetails.notifyContact 
                                            ? contactDetails.notifyContact 
                                            : ''
                                        }
                                        originalvalue={
                                            contactDetails && contactDetails.notifyContact 
                                            ? contactDetails.notifyContact 
                                            : ''
                                        }
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('notifyContact',
                                                contactDetails && contactDetails.notifyContact 
                                                ? contactDetails.notifyContact 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="notifyFax"
                                        label="Notify fax"
                                        refs={register()}
                                        defaultValue={
                                            contactDetails && contactDetails.notifyFax 
                                            ? contactDetails.notifyFax 
                                            : ''
                                        }
                                        originalvalue={
                                            contactDetails && contactDetails.notifyFax 
                                            ? contactDetails.notifyFax 
                                            : ''
                                        }
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={
                                            watch('notifyFax',
                                                contactDetails && contactDetails.notifyFax 
                                                ? contactDetails.notifyFax 
                                                : ''
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditContactDetails.propTypes = {
    register: PropTypes.func,
    contactDetails: PropTypes.any,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool,
};
