import axios from 'axios';
import { authHeaders } from 'actions';
import {
    TEAMS,
    GET_ORG_TEAMS,
    GET_TEAM
} from './constants';

/** Get Teams
* @param {
*      name: string
*      id: [string]
*      page: int
*      limit: int
*      sort: name_asc | name_desc | ...
* } params 
*/
export const getTeams = (params, token) => {
    return axios.get(TEAMS, params ? {
        params,
        ...authHeaders(token)
    } : authHeaders(token));
};

/** Get Team
* @param {
*      id: [string]
* } params 
*/
export const getTeam = (teamId, token) => {
    if (!teamId) return;
    return axios.get(GET_TEAM.replace(':teamId', teamId), authHeaders(token));
};

/** Get Organization Teams
* @param {
*      orgId: string
*      page: int
*      limit: int
*      sort: name_asc | name_desc | ...
* } params 
*/
export const getOrgTeams = (params, token) => {
    const { orgId } = params;
    if (!orgId) return;
    delete params.orgId;
    return axios.get(GET_ORG_TEAMS.replace(':orgId', orgId), params ? {
        params,
        ...authHeaders(token)
    }: authHeaders(token));
};

/** Create a Team
* @param {
*      name: string
*      description: string
*      members: [
*            {
*               email: string
*               teamRoleName: string
*            }
*      ]
* } payload 
*/
export const createTeam = (payload, token) => {
    if (!payload) return;
    return axios.post(TEAMS, payload, authHeaders(token));
};

/** Update a Team
* @param {
*      name: string
*      description: string
*      members: [
*            {
*               email: string
*               teamRoleName: string
*            }
*      ]
* } payload 
*/
export const updateTeam = (payload, token) => {
    if (!payload) return;
    return axios.put(TEAMS, payload, authHeaders(token));
};

/** Remove a Team
* @param {
*      _id: String
* } payload 
*/
export const removeTeam = (teamId, token) => {
    if (!teamId) return;
    return axios.delete(TEAMS, {
        data: {
            _id: teamId
        },
        ...authHeaders(token)
    });
};