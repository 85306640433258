import React from 'react'
import './index.scss';
import { Table } from 'components';

export const ActivityLogTab = ({ activityList }) => {
    const columns = [
        {
			dataIndex: 'feild',
			render: (feild) => (
                <h6 className="h6">{feild}</h6>
            )
        },
        {
			dataIndex: 'originalValue',
			render: (originalValue) => (
                <h6 className="h6">{originalValue}</h6>
            )
        },
        {
			dataIndex: 'newValue',
			render: (newValue) => (
                <h6 className="h6">{newValue}</h6>
            )
        },
    ]
    return (
        <div className="activities-log">
            {activityList.length < 1 ? (
               <p> No data ! </p>
            ) : (
                activityList.map((itemActivity, indexActivities) => {
                    return (
                        <div className="" key={indexActivities}>
                            {itemActivity.activities && itemActivity.activities.length > 0 ? itemActivity.activities.map((item, index) => (
                                <div className="activities-log--item d-flex align-items-center" key={index}>
                                    <div className="activities-log--item__avatar mrx2">
                                        <img src={item.createdBy.avatar} alt={item.createdBy.user} />
                                    </div>
                                    <span className="activities-log--item__name"> {item.name} &nbsp; </span>  
                                    <span className="activities-log--item__content"> {item.content} &nbsp; </span>
                                    <span className="activities-log--item__time"> {item.time}</span>  
                                </div>
                            )) : (
                                <p> No activity !</p>
                            )}

                            <Table
                                className="activities-log--table border"
                                dataSource={activityList}
                                columns={columns}
                            >
                                <div className="activities-log--table-header flex align-items-center justify-content-between table-header">
                                    <div className="column field">
                                        <p>Feild</p>
                                    </div>
                                    <div className="column original-value">
                                        <p>Original Value</p>
                                    </div>
                                    <div className="column new-value">
                                        <p>New Value</p>
                                    </div>
                                </div>
                            </Table>
                        </div>
                    )
                })
            )}
        </div>
    )
}
