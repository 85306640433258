import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    ShipmentSteps
} from 'components';

export const ShipmentProgresses = ({ progresses = [] }) => {
    return progresses.length > 0 ? (
        <div className="tr__shipment-progresses d-flex">
            {progresses.map((progress, progressIndex) => (
                <ShipmentSteps
                    key={progressIndex}
                    step={progress}
                    type="small"
                />
            ))}
        </div>
    ) : '';
};

ShipmentProgresses.propTypes = {
    progresses: PropTypes.array
}