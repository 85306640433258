import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    RadioGroup,
    Dropdown,
    Datepicker,
    FileUploader,
    Checkbox,
    Button
} from 'components';
import {
    roles,
    shipmentTypes,
    reuploadDocument,
    getErrorMessage
} from 'utils';
import { HomeContext, OrganizationContext, TemplateContext, TrackShipmentContext } from 'contexts';
import { 
    DESTINATION,
    LOCODE_PAGE_SIZE, 
    MARK_BOOKING_REQUEST, 
    MARK_NEW, 
    MARK_TRACK_SHIPMENT, 
    MINE,
    ORGANIZATION_PAGE_SIZE,
    ORIGIN, 
    TEMPLATE_PAGE_SIZE,
    MAX_FILE_SHIPMENT_DETAIL_DOCS,
    MAX_SIZE_SHIPMENT_DETAIL_DOC,
} from 'actions';

export const AddShipmentDetailsForm = ({ 
    register = () => {}, 
    errors,
    getValues = () => {},
    shipmentDetails,
    documents = [],
    setDocuments = () => {},
}) => {
    const { markAsShipmentType, markAsShipmentTemplate, trackerTemplate } = useContext(HomeContext);
    const { organizations, doGetOrganizations } = useContext(OrganizationContext);
    const { origins, setOrigins, destinations, setDestinations, firstLocode, doGetLocodes } = useContext(TrackShipmentContext);
    const { templates, doGetTemplates } = useContext(TemplateContext);

    const [templateChooser, setTemplateChooser] = useState(false);
    const [roleOptions, setRoleOptions] = useState(roles.slice(0, 2));
    const [selectedRole, setSelectedRole] = useState(roles[0]);
    const [selectedDocument, setSelectedDocument] = useState(reuploadDocument[0]);
    const [selectedShipmentType, setSelectedShipmentType] = useState(shipmentTypes[0]);
    const [orgLoading, setOrgLoading] = useState(false);
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [templateLoading, setTemplateLoading] = useState(false);
    const [maxDepartureDate, setMaxDepartureDate] = useState();
    const [minArrivalDate, setMinArrivalDate] = useState();
    const [departureDate, setDepartureDate] = useState();
    const [arrivalDate, setArrivalDate] = useState();
    const [selectedOrigin, setSelectedOrigin] = useState();
    const [selectedDestination, setSelectedDestination] = useState();
    const [selectedOrg, setSelectedOrg] = useState();

     /*eslint-disable */
     useEffect(() => {
        doGetOrganizations({ page: 0, limit: ORGANIZATION_PAGE_SIZE });
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
        doGetTemplates({ 
            tab: MINE, 
            limit: TEMPLATE_PAGE_SIZE, 
            page: 0,
        });

        if (firstLocode && firstLocode.length > 0) {
            setOrigins(firstLocode);
            setDestinations(firstLocode);
        }
    }, [])

    useEffect(() => {
        if (shipmentDetails) {
            const { creatorRole, routes, departureDate, arrivalDate, shippingDetails, orgId, documentType } = shipmentDetails;
            const sOrg = organizations.find(o => o._id === orgId);
            const currentRoleOption = roleOptions.find(r => r.id === creatorRole);
            const allRoleOption = creatorRole ? roles.find(r => r.id === creatorRole) : null;
            
            if (!currentRoleOption && allRoleOption) setRoleOptions(roles);
            setTemplateChooser(!!shipmentDetails.templateId);
            setSelectedRole(allRoleOption || roles[0]);
            setSelectedOrigin({
                key: routes.pol.location.locode,
                value: `${routes.pol.location.name}, ${routes.pol.location.country} (${routes.pol.location.locode})`,
                item: routes.pol.location
            });
            setSelectedDestination({
                key: routes.pod.location.locode,
                value: `${routes.pod.location.name}, ${routes.pod.location.country} (${routes.pod.location.locode})`,
                item: routes.pod.location
            });
            setDepartureDate(new Date(departureDate));
            setArrivalDate(new Date(arrivalDate));
            if (shippingDetails) setSelectedShipmentType(shipmentTypes.find(t => t.id === shippingDetails.shipmentType));
            setSelectedOrg(sOrg ? {
                key: sOrg._id,
                value: sOrg.name
            } : null);
            if (documentType) setSelectedDocument(reuploadDocument.find(d => d.id === documentType));
        }

        if (markAsShipmentType !== MARK_NEW) {
            switch(markAsShipmentType) {
                case MARK_TRACK_SHIPMENT:
                    if (trackerTemplate) {
                        const { prePol, postPod } = trackerTemplate;

                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: prePol.location.locode,
                            isSearching: true
                        });
                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: postPod.location.locode,
                            isSearching: true
                        }, null, null, DESTINATION);
                        setSelectedOrigin({
                            key: prePol.location.locode,
                            value: `${prePol.location.name}, ${prePol.location.country} (${prePol.location.locode})`,
                            item: prePol.location
                        });
                        setSelectedDestination({
                            key: postPod.location.locode,
                            value: `${postPod.location.name}, ${postPod.location.country} (${postPod.location.locode})`,
                            item: postPod.location
                        });
                        setDepartureDate(new Date(prePol.items[0].items[0].date));
                        setArrivalDate(new Date(postPod.items[0].items[0].date));
                    }
                    break;
                case MARK_BOOKING_REQUEST:
                    if (markAsShipmentTemplate) {
                        const { creatorRole, loadingPort, dischargePort, type } = markAsShipmentTemplate;

                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: loadingPort.locode,
                            isSearching: true
                        });
                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: dischargePort.locode,
                            isSearching: true
                        }, null, null, DESTINATION);
                        setSelectedRole(roles.find(r => r.id === creatorRole));
                        setSelectedOrigin({
                            key: loadingPort.locode,
                            value: `${loadingPort.city}, ${loadingPort.country} (${loadingPort.locode})`,
                            item: loadingPort
                        });
                        setSelectedDestination({
                            key: dischargePort.locode,
                            value: `${dischargePort.city}, ${dischargePort.country} (${dischargePort.locode})`,
                            item: dischargePort
                        });
                        setSelectedShipmentType(shipmentTypes.find(t => t.id === type));

                        if (shipmentDetails) {
                            const { departureDate, arrivalDate } = shipmentDetails;

                            setDepartureDate(new Date(departureDate));
                            setArrivalDate(new Date(arrivalDate));
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [markAsShipmentType, markAsShipmentTemplate, trackerTemplate, shipmentDetails])

    useEffect(() => {
        if (shipmentDetails && organizations && organizations.length > 0) {
            if (shipmentDetails.orgId) {
                const sOrg = organizations.find(o => o._id === shipmentDetails.orgId);
                if (sOrg) {
                    setSelectedOrg({
                        key: sOrg._id,
                        value: sOrg.name
                    })
                }
            }
        }
    }, [shipmentDetails, organizations])
    /*eslint-enable */

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
          if (!uploadFiles[i].name) return
          fileList.push(uploadFiles[i])
        }
        setDocuments(oldFiles => [...oldFiles, ...fileList])
    }

    const handleFileRemove = file => {
        if (!file) return;
        setDocuments(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    const handleShowAllRoles = () => {
        setRoleOptions(roles);
    }

    const onSearchOrganization = (value) => {
        setOrgLoading(true);
        doGetOrganizations({
			page: 0,
			limit: ORGANIZATION_PAGE_SIZE,
            name: value.trim(),
			isSearching: true
		}, () => {
			setOrgLoading(false);
		}, false);
    }

    const onSearchOriginLocode = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }
    const onSearchTemplate = (value) => {
        setTemplateLoading(true);

        doGetTemplates({
            limit: TEMPLATE_PAGE_SIZE,
            name: value.trim(),
            tab: MINE
        }, () => {
            setTemplateLoading(false);
        }, false);
    }

    const isMarkNewShipment = () => markAsShipmentType === MARK_NEW;
    const isMarkTrackShipment = () => markAsShipmentType === MARK_TRACK_SHIPMENT;
    const isMarkBRShipment = () => markAsShipmentType === MARK_BOOKING_REQUEST;

    return (
        <div className="tr__add-shipment-details-form">
            <div className="tr__add-shipment-details-form--group mbx4">
                <Dropdown
                    className="select big-label"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="input"
                    name="organization"
                    label="Choose Your Organization"
                    defaultValue={selectedOrg}
                    options={organizations && organizations.length > 0 ? organizations.map(org => {
                        return {
                            key: org._id,
                            value: org.name
                        }
                    }) : []}
                    placeholder="Select Organization"
                    dropdownPosition="center"
                    onInputChange={onSearchOrganization}
                    searchLoading={orgLoading}
                    // refs={register({ required: !markAsShipmentTemplate })}
                    refs={register({ required: true })}
                    error={!!errors.organization}
                    errorMessage={getErrorMessage(errors.organization, "Organization")}
                />
                {organizations && organizations.length === 0 && (
                    <p className="text-red mtx3">You don't belong to any organizations!</p>
                )}
            </div>
            <div className="tr__add-shipment-details-form--group mbx4">
                <RadioGroup
                    label="Your role in this shipment"
                    name="shipmentRole"
                    className="big-label"
                    horizontal
                    items={roleOptions}
                    value={selectedRole}
                    disabled={isMarkBRShipment()}
                    onChange={isMarkBRShipment() ? null : role => setSelectedRole(role)}
                    refs={register({ required: true })}
                    error={!!errors.shipmentRole}
                    errorMessage={getErrorMessage(errors.shipmentRole, "Shipment Role")}
                />
                {roleOptions.length < roles.length && !isMarkBRShipment() && (
                    <p
                        className="tr__link"
                        onClick={handleShowAllRoles}
                    >
                        Select other role
                    </p>
                )}
            </div>
            <div className="tr__add-shipment-details-form--group mbx4">
                <RadioGroup
                    label="Select type of shipment"
                    name="shipmentType"
                    className="big-label"
                    horizontal
                    items={shipmentTypes}
                    value={selectedShipmentType}
                    disabled={isMarkBRShipment()}
                    onChange={isMarkBRShipment() ? null : type => setSelectedShipmentType(type)}
                    refs={register({ required: true })}
                    error={!!errors.shipmentType}
                    errorMessage={getErrorMessage(errors.shipmentType, "Shipment Type")}
                />
            </div>
            <div className="tr__add-shipment-details-form--group row mbx4">
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="originPort"
                        label="Origin port"
                        comparable={true}
                        placeholder="You can search by name or port code"
                        disabled={!isMarkNewShipment()}
                        dropdownPosition="center"
                        error={!!errors.originPort}
                        defaultValue={selectedOrigin}
                        errorMessage={getErrorMessage(errors.originPort, "Origin", "Origin port and Destination port can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('destinationPort') })}
                        options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        onInputChange={onSearchOriginLocode}
                        searchLoading={originLoading}
                    />
                </div>
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destinationPort"
                        label="Destination port"
                        comparable={true}
                        disabled={!isMarkNewShipment()}
                        placeholder="You can search by name or port code"
                        dropdownPosition="center"
                        error={!!errors.destinationPort}
                        defaultValue={selectedDestination}
                        errorMessage={getErrorMessage(errors.destinationPort, "Destination Port", "Origin port and Destination port can't be the same")}
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        refs={register({ required: true, validate: value => value !== getValues('originPort') })}
                        onInputChange={onSearchDestLocode}
                        searchLoading={destinationLoading}
                    />
                </div>
            </div>
            <div className="tr__add-shipment-details-form--group row mbx4">
                <div className="col-6">
                    <Datepicker
                        name="vesselDepartureDate"
                        label="Vessel departure date"
                        placeholder="Add departure date"
                        disabled={isMarkTrackShipment()}
                        start={departureDate}
                        error={!!errors.vesselDepartureDate}
                        errorMessage={getErrorMessage(errors.vesselDepartureDate, "Vessel Departure Date")}
                        refs={register({ required: true })}
                        maxDate={maxDepartureDate}
                        onDatesChange={dates => setMinArrivalDate(dates)}
                    />
                </div>
                <div className="col-6">
                    <Datepicker
                        name="vesselArrivalDate"
                        label="Vessel arrival date"
                        placeholder="Add arrival date"
                        disabled={isMarkTrackShipment()}
                        start={arrivalDate}
                        error={!!errors.vesselArrivalDate}
                        errorMessage={getErrorMessage(errors.vesselArrivalDate, "Vessel Arrival Date")}
                        refs={register({ required: true })}
                        minDate={minArrivalDate}
                        onDatesChange={dates => setMaxDepartureDate(dates)}
                    />
                </div>
            </div>

            {/*** Upload document ***/}
            <div className="tr__add-shipment-details-form--group">
                <RadioGroup
                    name="documentType"
                    buttonMode={false}
                    label="Upload Document"
                    className='big-label'
                    type="default"
                    horizontal
                    items={reuploadDocument}
                    value={selectedDocument}
                    error={!!errors.documentType}
                    errorMessage={getErrorMessage(errors.documentType, "Document Type")}
                    refs={register({ required: true })}
                    onChange={doc => setSelectedDocument(doc)}
                />
            </div>
            <div className="tr__add-shipment-details-form--group mbx4">
                <FileUploader
                    name="shipmentDetailsDocs"
                    className="big-label"
                    handleDrop={handleFileUpload}
                    handleRemove={handleFileRemove}
                    uploadedFiles={documents}
                    mode="file"
                    multiple={false}
                    error={!!errors.shipmentDetailsDocs}
                    errorMessage={getErrorMessage(errors.shipmentDetailsDocs, "ShipmentDetails Docs Type")}
                    refs={register}
                    outputFormat='base64'
                    limitNumberUploadFile={MAX_FILE_SHIPMENT_DETAIL_DOCS}
                    limitSizePerFile={MAX_SIZE_SHIPMENT_DETAIL_DOC}
                />
            </div>
            {/*** !!! Upload document ***/}

            <div className="tr__add-shipment-details-form--group mbx4">
                <div className="template-chooser">
                    <div className="template-chooser--header d-flex align-items-center justify-content-between">
                        <h4 className="h4 f-medium">
                            <span>Choose Template</span>
                        </h4>
                        {templates && templates.length > 0 ? (
                            <Checkbox
                                name="templateChooser"
                                type="checkbox"
                                checked={templateChooser}
                                className="toggle right"
                                onChange={() => setTemplateChooser(!templateChooser)}
                                disabled={!(templates && templates.length > 0)}
                            />
                        ) : (
                            <small className="f-regular">There is no template available</small>
                        )}
                    </div>
                    {templateChooser && (
                        <div className="template-chooser--content mtx2 d-flex align-items-center justify-content-between">
                            <Dropdown
                                className="select mrx4"
                                icon="icon-chevron-down"
                                iconPosition="right"
                                name="template"
                                options={templates && templates.length > 0 ? templates.map(temp => {
                                    return {
                                        key: temp._id,
                                        value: temp.name,
                                    }
                                }) : []}
                                defaultValue={shipmentDetails && shipmentDetails.templateId ? {
                                    key: shipmentDetails.templateId,
                                    value: templates.find(t => t._id === shipmentDetails.templateId).name
                                } : null}
                                mode="input"
                                placeholder="Select template"
                                dropdownPosition="top full"
                                onInputChange={onSearchTemplate}
                                error={!!errors.template}
                                errorMessage={getErrorMessage(errors.template, "Template")}
                                refs={register({ required: true })}
                                searchLoading={templateLoading}
                            />
                            <Button
                                className="small"
                            >
                                Preview
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
AddShipmentDetailsForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    getValues: PropTypes.func,
    shipmentDetails: PropTypes.object,
    documents: PropTypes.array,
    setDocuments: PropTypes.func,
};
