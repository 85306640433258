import React, { useContext } from 'react';
import PropsTypes from 'prop-types';

import './index.scss';
import {
    Button,
    ShipmentFilter
} from 'components';
import { HomeContext, ModalContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { MARK_NEW, SHIPMENT_DETAILS } from 'actions';

export const ActiveShipmentsHeader = ({ title, noOfActiveShipments }) => {
    const history = useHistory();

    const { setMarkAsShipmentType } = useContext(HomeContext);
    const { activeShipmentFilter, setActiveShipmentFilter } = useContext(ModalContext);
    const { isArchive } = useContext(HomeContext);

    return (
        <>
            <div className="tr__active-shipments-header mtx1 mbx2">
                <div className="row">
                    <div className={`${isArchive ? 'col-12 flex-column' : 'col-6 align-items-center'} d-flex`}>
                        {title && (
                            <h1 className="h1 f-medium">{title}</h1>
                        )}
                        {noOfActiveShipments > 0 && (
                            <p className={`${isArchive ? '' : 'mlx2'} f-medium`}>
                                {noOfActiveShipments}{isArchive ? '' : ' active'} shipment{noOfActiveShipments > 1 ? 's' : ''}
                            </p>
                        )}
                    </div>
                    {!isArchive && (
                        <div className="col-6 d-flex align-items-center justify-content-end">
                            <h4 className="h4 f-medium text-right" onClick={() => setActiveShipmentFilter(true)}>
                                <i className="icon-filter" />
                                <span>Filter</span>
                            </h4>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setMarkAsShipmentType(MARK_NEW);
                                    history.push(SHIPMENT_DETAILS);
                                }}
                            >
                                Create A Shipment
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <ShipmentFilter
                open={activeShipmentFilter}
                onCancel={() => setActiveShipmentFilter(false)}
            />
        </>
    )
};

ActiveShipmentsHeader.propTypes = {
    title: PropsTypes.string,
    noOfActiveShipments: PropsTypes.number
};