import React, { useState } from 'react';

import {
    Modal,
    FileUploader,
    RadioGroup
} from 'components';
import { reuploadDocument } from 'utils';

export const ReuploadDocument = ({ open = false, onCancel = () => {}, onSubmit = () => {} }) => {
    const [selectedDocument, setSelectedDocument] = useState(reuploadDocument[0]);
    const [files, setFiles] = useState([]);

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
          if (!uploadFiles[i].name) return
          fileList.push(uploadFiles[i])
        }
        setFiles(oldFiles => [...oldFiles, ...fileList])
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    return (
        <Modal
            open={open}
            className="tr__reupload-document"
            onCancel={onCancel}
            title="Reupload Document"
            btnClasses="justify-content-between"
            submitButton
            submitButtonLabel="Verify"
            onSubmitClick={onSubmit}
            footerLabel="Once you upload the document and review the data, we will remove the old data from last document."
            isBackDropClickable={false}
        >
            <div className="tr__add-shipment-details-form--group mbx2">
                <RadioGroup
                    name="documentType"
                    buttonMode={false}
                    type="default"
                    horizontal
                    items={reuploadDocument}
                    value={selectedDocument}
                    onChange={doc => setSelectedDocument(doc)}
                />
            </div>
            <div className="tr__add-shipment-details-form--group mbx3">
                <FileUploader
                    name="reuploadDocuments"
                    handleDrop={handleFileUpload}
                    handleRemove={handleFileRemove}
                    uploadedFiles={files}
                    mode="list"
                />
            </div>
        </Modal>
    )
};