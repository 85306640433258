import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Modal,
    Textarea,
    Input,
} from 'components';
import { getErrorMessage } from 'utils';
import { useForm } from 'react-hook-form';
import { HomeContext, IssueContext, ModalContext } from 'contexts';
import { useHistory, useLocation } from 'react-router-dom';
import { ISSUES_URL } from 'actions';
import { MultiSelect } from 'components/common';

export const IssueModal = ({ open = false, onCancel, isEdit = false, setSearchParams = () => {}, searchParams }) => {
    const history = useHistory();
    const { handleSubmit, register, errors, reset, formState } = useForm({
        mode: "onChange"
    });
    const { doCreateIssues, issue, doUpdateIssue, taskIssue } = useContext(IssueContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const { setAddIssue, setEditIssue } = useContext(ModalContext);
    const [issuesSearched, setIssuesSearched] = useState([]);
    const [selectedTask, setSelectedTask] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentIdCreate = pathnameArr[pathnameArr.length - 2];
    const shipmentIdUpdate = pathnameArr[pathnameArr.length - 3];
   /*eslint-disable*/
    useEffect(() => {
        setIssuesSearched(taskIssue);
    }, [taskIssue])
     /*eslint-enable*/

    const doAddIssue = (data) => {
        const {
            title,
            description, 
        } = data

        const payload = {
            title: title,
            description: description,
            linkedTasks: selectedValue
        }

        doCreateIssues(shipmentIdCreate, payload, (data) => {
            reset();
            setAddIssue(false);
            setNotificationMessage(`
                <p> Issue has been added successfully!</p>
            `);
            setSelectedTask([]);
            history.push(`${ISSUES_URL.replace(':shipmentId', shipmentIdCreate)}/${data._id}`);
        });
    }

    const EditIssue = (data) => {
        const {
            title,
            description, 
        } = data

        const payload = {
            title: title,
            description: description,
            linkedTasks: selectedValue
        }
        doUpdateIssue(shipmentIdUpdate, issue._id, payload, () => {
            reset();
            setSelectedTask([]);
            setAddIssue(false);
            setEditIssue(false);
            setNotificationMessage(`
            <p> Issue has been updated successfully!</p>
        `);
        });
    }

    const onHideEditIssueModal = () => {
        reset();
        onCancel();
        setSelectedTask([]);
        setEditIssue(false);
    }
    const handleSearchTasksIssue = value => {
        const filteredArray = taskIssue.filter(tasks => tasks.taskName.includes(value));
        setIssuesSearched(filteredArray)
    };
    const handleChange = item => {
        setSelectedValue(item.map(e => e.id));
        setSelectedTask(item.map(e => {
            return e
        }));
    }

    return (
        <Modal
            open={open}
            className="tr__issue-modal"
            onCancel={onHideEditIssueModal}
            title={isEdit ? "Edit Issue" : "Create Issue"}
            submitButton
            submitButtonLabel={isEdit ? "Save Changes" : "Create"}
            onSubmitClick={isEdit ? handleSubmit(EditIssue) : handleSubmit(doAddIssue)}
            submitButtonDisabled = {!formState.isValid}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit()}>
                    {children}
                </form>
            )}
        >
            <div className="tr__issue-modal--form">
                <Input
                    label="Title"
                    className="mbx3"
                    name="title"
                    refs={register({ required: true, maxLength: 50 })}
                    defaultValue={issue ? issue.title : ''}
                    placeholder="Enter Title"
                    error={!!errors.title}
                    errorMessage={getErrorMessage(errors.title, "Title", "Title must not exceed 50 characters")}
                />
                <Textarea
                    label="Description"
                    className="mbx3"
                    name="description"
                    refs={register({ maxLength: 200 })}
                    defaultValue={issue ? issue.description : ''}
                    placeholder="Enter description"
                    error={!!errors.description}
                    errorMessage={getErrorMessage(errors.description, "Description", "Description must not exceed 200 characters")}
                />
                <MultiSelect
                    className="mbx2"
                    options={issuesSearched && issuesSearched.length > 0 ? issuesSearched.map(item => {
                        return {
                            id: item._id,
                            description: item.taskName,
                        }
                    }) : []}
                    name="linkedTask"
                    value={isEdit && issue && issue.linkedTasks ? issue.linkedTasks : selectedTask}
                    multiple={true}
                    isSelectable={true}
                    label='Linked task (Optional)'
                    placeholder='Choose a task'
                    onChange={handleChange}
                    onInputChange={handleSearchTasksIssue}
                    errorMessage='Please choose at least 1 link task'
                    register={register}
                    // errors={errors}
                    // formState={formState}
                />
            </div>
        </Modal>
    )
};

IssueModal.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    isEdit: PropTypes.bool,
}