import React, { useContext, useState, useEffect } from 'react';
import './index.scss';
import {
    Modal
} from 'components';
import { AssigneeList } from '../components';
import { CollaboratorsContext, HomeContext, ModalContext } from 'contexts';
import { MEMBER, TEAM } from 'actions';

export const AllCollaborators = ({ open = false, onCancel }) => {
    const { setCollaboratorId } = useContext(HomeContext);
    const { setRemoveCollaborator } = useContext(ModalContext);
    const { collaborators } = useContext(CollaboratorsContext);
    const [members,setMembers] = useState([]);
    const [teams,setTeams] = useState([]);
    /*eslint-disable */
    useEffect(() => {
        setMembers(collaborators.filter(collaborator => collaborator.type === MEMBER.toUpperCase()));
        setTeams(collaborators.filter(collaborator => collaborator.type === TEAM.toUpperCase()));
    }, [collaborators])
    /*eslint-enable */
    const handleRemoveMember = (member) => {
        console.log(member);
        setCollaboratorId(member._id);
        setRemoveCollaborator(true);
    }
    return (
        <Modal
            open={open}
            className="tr__all-collaborators"
            onCancel={onCancel}
            title='All Collaborators'
            isBackDropClickable={false}
        >
            <AssigneeList
                title="Individuals"
                assignees={members}
                onRemove={handleRemoveMember}
            />
            <AssigneeList
                className="mtx3"
                title="Teams"
                assignees={teams}
                onRemove={handleRemoveMember}
                hasOrgName={true}
            />
        </Modal>
    )
};