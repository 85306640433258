import React, { createContext, useContext, useEffect, useState } from 'react';
import * as _ from 'lodash';

import {
    getActiveShipments,
    OPERATION_FAILED_MESSAGE,
} from 'actions';
import { HomeContext, AuthContext } from 'contexts';
import { CollaboratorsContextProvider } from './CollaboratorsContext';
import { ManagementContextProvider } from './ManagementContext';
import { ShipmentInfoContextProvider } from './ShipmentInfoContext';
import { IssueContextProvider } from './IssueContext';
import { TemplateContextProvider } from './TemplateContext';
import { ExtractedContextProvider } from './ExtractedContext';

export const ActiveShipmentsContext = createContext();

export const ActiveShipmentsContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setNotificationType } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [activeShipments, setActiveShipments] = useState([]);
    const [cacheActiveShipments, setCacheActiveShipments] = useState([]);
    const [needAttentionActiveShipments, setNeedAttentionActiveShipments] = useState([]);
    const [cacheNeedAttentionActiveShipments, setCacheNeedAttentionActiveShipments] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalitems] = useState(0);
    const [filter, setFilter] = useState(null);
    const [carriers, setCarriers] = useState([]);
    const [needAttentionCarriers, setNeedAttentionCarriers] = useState([]);
    const [ports, setPorts] = useState([]);
    const [bookingNos, setBookingNos] = useState([]);
    const [blNumbers, setBlNumbers] = useState([]);

    /* eslint-disable */
    useEffect(() => {
        if (filter && (cacheActiveShipments.length || cacheNeedAttentionActiveShipments.length)) {
            if (cacheActiveShipments.length) setActiveShipments(handleFilterShipments(cacheActiveShipments));
            if (cacheNeedAttentionActiveShipments.length) setNeedAttentionActiveShipments(handleFilterShipments(cacheNeedAttentionActiveShipments));

            setTotalitems(handleFilterShipments(cacheActiveShipments).length + handleFilterShipments(cacheNeedAttentionActiveShipments).length);
        }
    }, [filter, cacheActiveShipments, cacheNeedAttentionActiveShipments]);

    useEffect(() => {
        if (cacheActiveShipments.length) {
            setCarriers(handleCollectCarrier(cacheActiveShipments));
            setPorts(handleCollectPorts(cacheActiveShipments));
            setBookingNos(handleCollectBookingNos(cacheActiveShipments));
            setBlNumbers(handleCollectBlNumbers(cacheActiveShipments));
        }
        if (cacheNeedAttentionActiveShipments.length) {
            setNeedAttentionCarriers(handleCollectCarrier(cacheNeedAttentionActiveShipments));
            setPorts(handleCollectPorts(cacheNeedAttentionActiveShipments));
            setBookingNos(handleCollectBookingNos(cacheNeedAttentionActiveShipments));
            setBlNumbers(handleCollectBlNumbers(cacheNeedAttentionActiveShipments));
        }
    }, [cacheActiveShipments, cacheNeedAttentionActiveShipments]);
    /* eslint-enable */

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetActiveShipments = async (params, callback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);

            const response = await getActiveShipments(params, loggedInUser);
            const { data, status } = response;
            
            if (status === 200) {
                setActiveShipments(data.items || []);
                setCacheActiveShipments(data.items || []);
                setTotalPages(data.totalPage);
                setCurrentPage(data.currentPage);
                setTotalitems(totalItems + data.totalItems);

                if (callback) callback();
            }

            if (hasLoading) setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGetNeedAttentionActiveShipments = async (params, callback, hasLoading = true) => {
        try {
            if (hasLoading) setLoading(true);

            if (params) params.attention = true;

            const response = await getActiveShipments(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setNeedAttentionActiveShipments(data.items || []);
                setCacheNeedAttentionActiveShipments(data.items || []);

                if (callback) callback();
            }

            if (hasLoading) setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doSetFilter = (params) => {
        setFilter(params);
    }

    const handleCollectCarrier = shipments => {
        let carriers = [];

        for (let shipment of shipments)
            if (shipment.bookingDetail && shipment.bookingDetail.carrierName && !carriers.includes(shipment.bookingDetail.carrierName))
                carriers.push(shipment.bookingDetail.carrierName);

        return carriers;
    }

    const handleCollectPorts = shipments => {
        let tmpPorts = ports;

        for (let shipment of shipments)
            if (shipment.routes && (shipment.routes.pol || shipment.routes.pod)) {
                if (!_.find(tmpPorts, item => item.locode === shipment.routes.pol.location.locode)) tmpPorts.push(shipment.routes.pol.location);
                if (!_.find(tmpPorts, item => item.locode === shipment.routes.pod.location.locode)) tmpPorts.push(shipment.routes.pod.location);
            }

        return tmpPorts;
    }

    const handleCollectBookingNos = shipments => {
        let tmpBookingNos = bookingNos;

        for (let shipment of shipments)
            if (shipment.bookingDetail && shipment.bookingDetail.confirmationNo && shipment.bookingDetail.confirmationNo.length)
                for (let item of shipment.bookingDetail.confirmationNo)
                    if (!tmpBookingNos.includes(item)) tmpBookingNos.push(item);

        return tmpBookingNos;
    }

    const handleCollectBlNumbers = shipments => {
        let tmpBlNumbers = blNumbers;

        for (let shipment of shipments) {
            if (shipment.bookingDetail && shipment.bookingDetail.masterBoL && shipment.bookingDetail.masterBoL.length)
                for (let item of shipment.bookingDetail.masterBoL)
                    if (!tmpBlNumbers.includes(item)) tmpBlNumbers.push(item);

            if (shipment.bookingDetail && shipment.bookingDetail.houseBoL && shipment.bookingDetail.houseBoL.length)
                for (let item of shipment.bookingDetail.houseBoL)
                    if (!tmpBlNumbers.includes(item)) tmpBlNumbers.push(item);
        }

        return tmpBlNumbers;
    }

    const handleFilterShipments = shipments => {
        let filteredShipments = _.cloneDeep(shipments);
        let removeIds = [];

        // Filter by roles
        if (filter.shipmentRole.length) {
            for (let shipment of filteredShipments) {
                if(!_.find(shipment.roleInShipments, item => filter.shipmentRole.includes(item.roleType.toLowerCase().replace('_', '-'))))
                    removeIds.push(shipment._id);
            }
        }
        filteredShipments = filteredShipments.filter(shipment => !removeIds.includes(shipment._id));

        /**
         * Filter by ports
         */
        // By origin
        if (filter.origin) filteredShipments = filteredShipments.filter(shipment => shipment.routes.pol.location.locode === filter.origin);
        // By destination
        if (filter.destination) filteredShipments = filteredShipments.filter(shipment => shipment.routes.pod.location.locode === filter.destination);

        // Filter by events
        if (filter.eventType && filter.vesselShedule) {
            const times = filter.vesselShedule.split(' - ');
            const startTime = new Date(times[0]).getTime();
            const endTime = new Date(times[1]).getTime();

            if (filter.eventType === '1') {
                filteredShipments = filteredShipments.filter(shipment => startTime < shipment.routes.pol.date && shipment.routes.pol.date < endTime);
            }

            if (filter.eventType === '2') {
                filteredShipments = filteredShipments.filter(shipment => startTime < shipment.routes.pod.date && shipment.routes.pod.date < endTime);
            }
        }

        // Filter by shipment type
        if (filter.shipmentType) filteredShipments = filteredShipments.filter(shipment => shipment.shippingDetails.shipmentType === filter.shipmentType);

        // Filter by carriers
        if (filter.carrier && filter.carrier.split(',').length) {
            for (let shipment of filteredShipments) {
                if(shipment.bookingDetail && shipment.bookingDetail.carrierName && !filter.carrier.split(',').includes(shipment.bookingDetail.carrierName))
                    removeIds.push(shipment._id);
            }
        }
        filteredShipments = filteredShipments.filter(shipment => !removeIds.includes(shipment._id));

        // Filter by Booking No.
        if (filter.bookingNo) filteredShipments = filteredShipments.filter(shipment => shipment.bookingDetail
            && shipment.bookingDetail.confirmationNo
            && !!_.find(shipment.bookingDetail.confirmationNo, item => filter.bookingNo.split(',').includes(item))
        );

        // Filter by BL Number
        if (filter.blNumber) filteredShipments = filteredShipments.filter(shipment => shipment.bookingDetail
            && (
                ((shipment.bookingDetail.masterBoL) && !!_.find(shipment.bookingDetail.masterBoL, item => filter.blNumber.split(',').includes(item)))
                || ((shipment.bookingDetail.masterBoL) && !!_.find(shipment.bookingDetail.houseBoL, item => filter.blNumber.split(',').includes(item)))
            )
        );

        return filteredShipments;
    }

    const handlerClearFilterShipment = () => {
        setActiveShipments(cacheActiveShipments);
        setNeedAttentionActiveShipments(cacheNeedAttentionActiveShipments);

        setTotalitems(cacheActiveShipments.length + cacheNeedAttentionActiveShipments.length);
    }

    return(
        <ActiveShipmentsContext.Provider
            value={{
                activeShipments,
                doGetActiveShipments,
                needAttentionActiveShipments,
                doGetNeedAttentionActiveShipments,
                doSetFilter,
                currentPage,
                totalPages,
                totalItems,
                carriers,
                needAttentionCarriers,
                ports,
                bookingNos,
                blNumbers,
                handlerClearFilterShipment,
            }}
        >
            <TemplateContextProvider>
                <IssueContextProvider>
                    <CollaboratorsContextProvider>
                        <ShipmentInfoContextProvider>
                            <ManagementContextProvider>
                                <ExtractedContextProvider>
                                    { children }
                                </ExtractedContextProvider>
                            </ManagementContextProvider>
                        </ShipmentInfoContextProvider>
                    </CollaboratorsContextProvider> 
                </IssueContextProvider>
            </TemplateContextProvider>
        </ActiveShipmentsContext.Provider>
    );
};
