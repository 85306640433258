import React from 'react';
import PropTypes from 'prop-types';

import {
    EditShipmentInfoInput,
    Collapse
} from 'components';

export const EditContainerTracking = ({ register, shipmentDetails, watch, editShipmentInfo }) => {
    const containerValues = shipmentDetails && shipmentDetails.containerTracking ? shipmentDetails.containerTracking.containers : [];
    const newContainerNumber = watch ? watch("containerNumber",containerValues) : '';

    return (
        <div className="tr__verify-details-form--group mbx4">
            <Collapse title="Container Tracking" className="details" initialOpen={true}>
                <div className="tr__verify-details-form--group--content">
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="containerNumber"
                                        label="Container number"
                                        placeholder="BN823592"
                                        refs={register()}
                                        maxLength={50}
                                        defaultValue={containerValues.length > 0 ? containerValues.map(c => c.containerNumber).join(', ') : ''}
                                        originalvalue={containerValues.length > 0 ? containerValues.map(c => c.containerNumber).join(', ') : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newContainerNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
};

EditContainerTracking.propTypes = {
    register: PropTypes.func,
    shipmentDetails: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool
};
