import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    MapBox,
    SearchResults,
    Collapse
} from 'components';
import {
    shipmentProcess
} from 'utils';
import { TrackShipmentContext } from 'contexts';
import { DONE } from 'actions';

export const ShipmentMap = ({ className = '', shipmentInfo }) => {
    const [markers, setMarkers] = useState([]);
    const [activeRoutes, setActiveRoutes] = useState([]);
    const [markersStatus, setMarkersStatus] = useState([]);
    const [markersInfo, setMarkersInfo] = useState([]);
    const shipmentMapId = 'active-shipment-map';
    const {
        selectedTracker,
        trackers,
        routes,
        setTrackers,
        setSelectedTracker,
        setRoutes,
    } = useContext(TrackShipmentContext);


     /*eslint-disable */
     useEffect(() => {
        return () => {
            setTrackers([]);
            setSelectedTracker(null);
            setRoutes(null);
        }
    }, [])
    
    useEffect(() => {
        if (selectedTracker) {
            const { prePol, postPod } = selectedTracker;
            const isRoutes = routes && routes.routeArray && routes.routeArray.length;
            
            let newActiveRoutes = [];
            let tempMarkers = [];
            let tempMarkerStatus = [];
            let tempMarkerInfo = [];

            if (isRoutes) 
            {
                newActiveRoutes = [
                    [prePol.location.lng, prePol.location.lat],
                    ...routes.routeArray
                ];
                tempMarkers = [
                    [prePol.location.lng, prePol.location.lat],
                    ...routes.routeArray,
                    [postPod.location.lng, postPod.location.lat]
                ];
                tempMarkerStatus = [
                    prePol.status === DONE,
                    ...Array(routes.routeArray.length).fill(true),
                    postPod.status === DONE
                ];
                tempMarkerInfo = [
                    `${prePol.location.name} (${prePol.location.locode})`,
                    ...Array(routes.routeArray.length).fill(''),
                    `${postPod.location.name} (${postPod.location.locode})`
                ];

                if (postPod.status === DONE) newActiveRoutes.push([postPod.location.lng, postPod.location.lat])
            } else {
                tempMarkers = [
                    [prePol.location.lng, prePol.location.lat],
                    [postPod.location.lng, postPod.location.lat]
                ];
                tempMarkerStatus = [
                    prePol.status === DONE,
                    postPod.status === DONE
                ];
                tempMarkerInfo = [
                    `${prePol.location.name} (${prePol.location.locode})`,
                    `${postPod.location.name} (${postPod.location.locode})`
                ];
            }

            setMarkers(tempMarkers);

            setActiveRoutes(newActiveRoutes);

            setMarkersStatus(tempMarkerStatus);

            setMarkersInfo(tempMarkerInfo);
        } else {
            setMarkers([]);
            setMarkersStatus([]);
            setMarkersInfo([]);
            setActiveRoutes([]);
        }
    }, [selectedTracker, routes])

    /*eslint-disable */
    useEffect(() => {
        setMarkers([]);
        setActiveRoutes([]);
        setMarkersInfo([]);
        setMarkersStatus([]);
    }, [trackers])
    /*eslint-enable */

    return (
        <Collapse
            className={`tr__shipment-map ${className}`}
            title="View Shipment on Map"
        >
            <div className='tr__shipment-map--container d-flex'>
                <div className="tr__shipment-map--map">
                    {/* <Map
                        id={shipmentMapId}
                        pos1={[1.583758, 103.360898]}
                        pos2={[6.064526, 100.338914]}
                        pos3={[17.66686420888392, 83.23774434375001]}
                        pos4={[14.616259, 79.910743]}
                    /> */}
                    <MapBox
                        id={shipmentMapId}
                        markers={markers}
                        routes={activeRoutes}
                        status={markersStatus}
                        infos={markersInfo}
                    />
                </div>
                <div className="tr__shipment-map--info">
                    <SearchResults
                        shipmentProcess={shipmentProcess}
                        shipmentInfo={shipmentInfo}
                    />
                </div>
            </div>
        </Collapse>
    )
};

ShipmentMap.propTypes = {
    className: PropTypes.string
};