import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    FileUploader,
    Dropdown,
    Button
} from 'components';
import { sampleOptions } from 'utils';

export const UploadDocuments = ({ className = '', title, label, name, mode, type, onFilesUpload, onCancel }) => {
    const [files, setFiles] = useState([]);

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
          if (!uploadFiles[i].name) return
          fileList.push(uploadFiles[i])
        }
        setFiles(oldFiles => [...oldFiles, ...fileList]);
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    const handleUploadDocuments = () => {
        onFilesUpload(files);
    }

    const isListMode = () => mode === 'list';
    const isDocMode = () => mode === 'doc';

    return (
        <div className={`tr__upload-documents ${mode} ${className}`}>
            {title && (
                <h5 className="h5 mbx3 mtx1 f-medium">{title}</h5>
            )}
            <FileUploader
                name={name}
                handleDrop={handleFileUpload}
                handleRemove={handleFileRemove}
                uploadedFiles={files}
                mode={isDocMode() ? 'list' : mode}
                label={label}
                type={type}
            />
            {isListMode() && (
                <div className="tr__upload-documents--footer d-flex align-items-center justify-content-between">
                    <span className="tr__link" onClick={onCancel}>Cancel</span>
                    <Button
                        type="primary"
                        disabled={files.length === 0}
                        onClick={handleUploadDocuments}
                    >
                        Upload
                    </Button>
                </div>
            )}

            {isDocMode() && (
                <>
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="documentTypes"
                        label="Select document type"
                        options={sampleOptions}
                        placeholder="You can select any document type"
                        dropdownPosition="center"
                    />
                    
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="documentTask"
                        label="Select document task"
                        options={sampleOptions}
                        placeholder="You can select any document task"
                        dropdownPosition="center"
                    />
                    
                    <div className="tr__upload-documents--footer mtx3 d-flex align-items-center">
                        <div className="note d-flex align-items-center">
                            <i className="icon-shield" />
                            <p>All of your documents are highly secured here</p>
                        </div>
                        <Button
                            className="big-btn-size"
                            type="primary"
                            onClick={handleUploadDocuments}
                        >
                            Upload document
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
};

UploadDocuments.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    mode: PropTypes.string,
    onFilesUpload: PropTypes.func,
    onCancel: PropTypes.func
};