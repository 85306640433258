import React, { createContext, useContext, useEffect, useState } from 'react';
import { HomeContext } from './HomeContext';

import { getToken } from 'utils';
import {
    getUploadedSignedFileUrl,
    getOcrData,
    getBase64FromUrl
    // updateOcrData
} from 'actions';

export const ExtractedContext = createContext();


export const ExtractedContextProvider = ({ children }) => {
    const { setLoading } = useContext(HomeContext);
    const [token, setToken] = useState();
    const [ocrData, setOcrData] = useState({});
    const [fileData, setFileData] = useState({});

    /*eslint-disable */
    useEffect(() => {
        setToken(getToken())
    })

    const doGetOcrData = async (docId, callback) => {
        try {
            setLoading(true);

            const [responseOcr, responseUrl] = await Promise.all([
                getOcrData(docId, token),
                getUploadedSignedFileUrl(docId, token)
            ])
            if (responseOcr.status === 200 && responseUrl.status === 200) {
                setOcrData(responseOcr.data);

                const base64 = await getBase64FromUrl(responseUrl.data?.ret?.downloadSignedURI?.itemURI);
                if (responseUrl.data?.ret?.fileExtension === 'pdf') {
                    setFileData({
                        contentType: 'application/pdf',
                        data: `data:application/pdf;base64,${base64}`
                    });
                } else {
                    setFileData({
                        contentType: `image/${responseUrl.data?.ret?.fileExtension}`,
                        data: `data:image/${responseUrl.data?.ret?.fileExtension};base64,${base64}`
                    });
                }

                if (callback)
                    callback();
            }
        } catch (error) {
        }
        setLoading(false);
    }

    return (
        <ExtractedContext.Provider
            value={{
                ocrData,
                fileData,
                setOcrData,
                doGetOcrData
            }}
        >
            { children}
        </ExtractedContext.Provider>
    );
};
