import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import './index.scss';
import { Dropdown, Input, Datepicker } from 'components';
import { getErrorMessage } from 'utils';
import { BookingRequestContext, TrackShipmentContext } from 'contexts';
import { DESTINATION, LOCODE_PAGE_SIZE, ORIGIN } from 'actions';

export const ShipmentForm = ({
    register = () => {},
    errors = [],
    containers = [],
    setContainers = () => {},
    typeOfShipment,
    getValues = () => {},
    shipmentDetails
}) => {
    
    const { brId } = useParams();
    const { origins, destinations, doGetLocodes } = useContext(TrackShipmentContext);
    const { bookingRequest } = useContext(BookingRequestContext);

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [loadingPortLoading, setLoadingPortLoading] = useState(false);
    const [dischargePortLoading, setDischargePortLoading] = useState(false);
    const [placeReceipt, setPlaceReceipt] = useState('');
    const [placeDelivery, setPlaceDelivery] = useState('');

    /*eslint-disable */
    useEffect(() => {
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
        const scripts = document.scripts;
        const MAPBOX_GEOCODER_URL = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.min.js';
        let isInjected = false;

        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src === MAPBOX_GEOCODER_URL) {
                isInjected = true;
                break;
            }
        }

        if (!isInjected) {
            let mapGeocoderScriptElement = document.createElement('script');
            mapGeocoderScriptElement.src = MAPBOX_GEOCODER_URL;

            document.head.appendChild(mapGeocoderScriptElement);
        } else {
            setScriptLoaded(true);
        }
    }, [])

    useEffect(() => {
        if (window.MapboxGeocoder) setScriptLoaded(true);
    }, [window.MapboxGeocoder])

    useEffect(() => {
        if (scriptLoaded) {
            addGeocoder('pr', 'placeOfReceipt', 'Place of receipt', setPlaceReceipt);
            addGeocoder('pd', 'placeOfDelivery', 'Place of Delivery', setPlaceDelivery);
        }
    }, [scriptLoaded])

    useEffect(() => {
        if (bookingRequest) {
            setPlaceReceipt(bookingRequest.placeOfReceipt);
            setPlaceDelivery(bookingRequest.placeOfDelivery);
        } else {
            setPlaceReceipt('');
            setPlaceDelivery('');
        }
    }, [bookingRequest])

    const addGeocoder = (key, selector, placeholder, setPlace) => {
        window[key] = null;
        if (window.MapboxGeocoder) {
            window[key] = new window.MapboxGeocoder({
                accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
                types: 'country,region,place,postcode,locality,neighborhood',
                placeholder
            });

            window[key].addTo(`#${selector}`);

            window[key].on('result', result => {
                setPlace(result.result.place_name);
            })
        }
    }

    const handleSearch = (value, key, setValue) => {
        setValue(value);
        if (window[key]) {
            if (value) window[key].setInput(value)._geocode(value);
            else window[key].setInput(value);
        }
    }

    const onSearchLoadingPort = value => {
        setLoadingPortLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setLoadingPortLoading(false);
        }, () => {
            setLoadingPortLoading(false);
        });
    }

    const onSearchDischargePort = value => {
        setDischargePortLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setDischargePortLoading(false);
        }, () => {
            setDischargePortLoading(false);
        }, DESTINATION);
    }

    const getLocodeName = (locodes, locode, type) => {
        let selectedLocode = locodes.find(l => l.locode === locode);
        if (!selectedLocode) {
            doGetLocodes({
                limit: LOCODE_PAGE_SIZE,
                cop: locode,
                isSearching: true
            }, data => {
                selectedLocode = data.find(l => l.locode === locode);
                return `${selectedLocode.city}, ${selectedLocode.country} (${selectedLocode.locode})`
            }, () => {
                return '';
            }, type);
        } else {
            return `${selectedLocode.city}, ${selectedLocode.country} (${selectedLocode.locode})`
        }
    }

    return (
        <div className="tr__shipment-form">
            <div className="row">
                <div id="demo" />
                {typeOfShipment ? (
                    <>
                        <div className="col-12 col-sm-6">
                            <Input
                                className="mtx3"
                                name="numberPackage"
                                placeholder="Number of package"
                                defaultValue={shipmentDetails ? shipmentDetails.numberOfPackage : (bookingRequest ? bookingRequest.numberOfPackage : '')}
                                refs={register({ required: true, pattern: /[0-9]+/ })}
                                error={!!errors.numberPackage}
                                errorMessage={getErrorMessage(errors.numberPackage, "Number of package")}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Input
                                className="mtx3"
                                name="numberPallet"
                                placeholder="Number of pallet"
                                refs={register({ required: true, pattern: /[0-9]+/ })}
                                defaultValue={shipmentDetails ? shipmentDetails.numberOfPallet : (bookingRequest ? bookingRequest.numberOfPallet : '')}
                                error={!!errors.numberPallet}
                                errorMessage={getErrorMessage(errors.numberPallet, "Numnber of pallet")}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Input
                                className="mtx3"
                                name="totalVolume"
                                placeholder="Total of volume"
                                defaultValue={shipmentDetails ? shipmentDetails.totalOfVolume : (bookingRequest ? bookingRequest.totalOfVolume : '')}
                                refs={register({ required: true, pattern: /[0-9]+/ })}
                                error={!!errors.totalVolume}
                                errorMessage={getErrorMessage(errors.totalVolume, "Total of volume")}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {(shipmentDetails && shipmentDetails.containers && shipmentDetails.containers.length === containers.length ? shipmentDetails.containers : containers).map((container, containerIndex) => (
                            <React.Fragment key={containerIndex}>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        className="mtx3"
                                        name={`numberContainer-${containerIndex}`}
                                        placeholder="Number of container"
                                        defaultValue={container ? container.numberOfContainer : ''}
                                        refs={register({ required: true, pattern: /[0-9]+/ })}
                                        error={!!errors[`numberContainer-${containerIndex}`]}
                                        errorMessage={getErrorMessage(errors[`numberContainer-${containerIndex}`], "Number of containers")}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        className="mtx3"
                                        name={`typeContainer-${containerIndex}`}
                                        placeholder="Type of container"
                                        defaultValue={container ? container.typeOfContainer : ''}
                                        refs={register({ required: true })}
                                        error={!!errors[`typeContainer-${containerIndex}`]}
                                        errorMessage={getErrorMessage(errors[`typeContainer-${containerIndex}`], "Type of container")}
                                    />
                                </div>
                            </React.Fragment>
                        ))}
                        <p
                            className="tr__link mtx3"
                            onClick={() => setContainers(oldContainers => [...oldContainers, oldContainers.length])}
                        >
                            <i className="icon-plus mrx1"/>
                            <span>Add more container</span>
                        </p>
                    </>
                )}
                <div className="col-12 col-sm-6 with-label">
                    <Input
                        className="mtx3"
                        name="totalWeight"
                        placeholder="Total weight"
                        defaultValue={shipmentDetails ? shipmentDetails.totalWeight.value : (bookingRequest ? bookingRequest.totalWeight.value : '')}
                        refs={register({ required: true, pattern: /[0-9]+/ })}
                        error={!!errors.totalWeight}
                        errorMessage={getErrorMessage(errors.totalWeight, "Total weight")}
                    />
                    <Dropdown
                        className="select mtx3 no-icon"
                        mode="select"
                        name="weightUnit"
                        defaultValue={{
                            key: 'KG',
                            value: 'Kg'
                        }}
                        refs={register({ required: true })}
                        dropdownPosition="center"
                    />
                </div>
                <div className={`col-12 ${typeOfShipment ? '' : 'col-sm-6'} with-label`}>
                    <Input
                        className="mtx3"
                        name="temperature"
                        placeholder="Temperature"
                        defaultValue={shipmentDetails ? shipmentDetails.temperature.value : (bookingRequest ? bookingRequest.temperature.value : '')}
                        refs={register({ required: true, pattern: /[0-9]+/ })}
                        error={!!errors.temperature}
                        errorMessage={getErrorMessage(errors.temperature, "Temperature")}
                    />
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="unitOfMeasure"
                        defaultValue={{
                            key: 'CENTIGRADE',
                            value: 'Celsius'
                        }}
                        refs={register({ required: true })}
                        options={[{
                            key: 'CENTIGRADE',
                            value: 'Celsius'
                        }, {
                            key: 'FAHRENHEIT',
                            value: 'Fahrenheit'
                        }]}
                        dropdownPosition="center"
                    />
                </div>
                <div className="col-12">
                    <Input
                        className="mtx3"
                        name="remark"
                        placeholder="Remark"
                        defaultValue={shipmentDetails ? shipmentDetails.remark : (bookingRequest ? bookingRequest.remark : '')}
                        refs={register({ required: true })}
                        error={!!errors.remark}
                        errorMessage={getErrorMessage(errors.remark, "Remark")}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="loadingPort"
                        error={!!errors.loadingPort}
                        comparable={true}
                        defaultValue={shipmentDetails ? {
                            key: shipmentDetails.loadingPort,
                            value: getLocodeName(origins, shipmentDetails.loadingPort, ORIGIN)
                        } : (bookingRequest ? {
                            key: bookingRequest.loadingPort.locode,
                            value: `${bookingRequest.loadingPort.city}, ${bookingRequest.loadingPort.country} (${bookingRequest.loadingPort.locode})`
                        } : null)}
                        errorMessage={getErrorMessage(errors.loadingPort, "Loading port", "Loading port and Discharge port can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('dischargePort') })}
                        options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder="Loading port"
                        dropdownPosition="center"
                        onInputChange={onSearchLoadingPort}
                        searchLoading={loadingPortLoading}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        className="mtx3"
                        name="placeReceipt"
                        placeholder="Place of receipt"
                        refs={register({ required: true })}
                        onChange={e => handleSearch(e.target.value, 'pr', setPlaceReceipt)}
                        value={placeReceipt}
                        error={!!errors.placeReceipt}
                        errorMessage={getErrorMessage(errors.placeReceipt, "Place of receipt")}
                    />
                    <div id="placeOfReceipt" className={placeReceipt ? '' : 'no-result'} />
                </div>
                <div className="col-12">
                    <Datepicker
                        className="mtx3"
                        name="dateReceipt"
                        placeholder="Date of receipt"
                        placement="top-start"
                        refs={register({ required: true })}
                        start={shipmentDetails ? new Date(shipmentDetails.dateOfReceipt.fromDate) : (bookingRequest ? new Date(bookingRequest.dateOfReceipt.fromDate) : null)}
                        end={shipmentDetails ? new Date(shipmentDetails.dateOfReceipt.toDate) : (bookingRequest ? new Date(bookingRequest.dateOfReceipt.toDate) : null)}
                        error={!!errors.dateReceipt}
                        errorMessage={getErrorMessage(errors.dateReceipt, "Date of receipt")}
                        range={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="dischargePort"
                        comparable={true}
                        error={!!errors.dischargePort}
                        errorMessage={getErrorMessage(errors.dischargePort, "Discharge port", "Loading port and Discharge port can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('loadingPort') })}
                        defaultValue={shipmentDetails ? {
                            key: shipmentDetails.dischargePort,
                            value: getLocodeName(destinations, shipmentDetails.dischargePort, DESTINATION)
                        } : (bookingRequest ? {
                            key: bookingRequest.dischargePort.locode,
                            value: `${bookingRequest.dischargePort.city}, ${bookingRequest.dischargePort.country} (${bookingRequest.dischargePort.locode})`
                        } : null)}
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder="Discharge port"
                        dropdownPosition="center"
                        onInputChange={onSearchDischargePort}
                        searchLoading={dischargePortLoading}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        className="mtx3"
                        name="placeDelivery"
                        placeholder="Place of Delivery"
                        refs={register({ required: true })}
                        onChange={e => handleSearch(e.target.value, 'pd', setPlaceDelivery)}
                        value={placeDelivery}
                        error={!!errors.placeDelivery}
                        errorMessage={getErrorMessage(errors.placeDelivery, "Place of Delivery")}
                    />
                    <div id="placeOfDelivery" className={placeDelivery ? '' : 'no-result'} />
                </div>
                {typeOfShipment ? (
                    <>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="freightExport"
                                placeholder="Export Container Freight Station Address"
                                defaultValue={shipmentDetails ? shipmentDetails.exportAddress : (bookingRequest ? bookingRequest.exportAddress : '')}
                                refs={register({ required: true })}
                                error={!!errors.freightExport}
                                errorMessage={getErrorMessage(errors.freightExport, "Freight Export Address")}
                            />
                        </div>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="freightImport"
                                placeholder="Import Container Freight Station Address"
                                defaultValue={shipmentDetails ? shipmentDetails.importAddress : (bookingRequest ? bookingRequest.importAddress : '')}
                                refs={register({ required: true })}
                                error={!!errors.remark}
                                errorMessage={getErrorMessage(errors.freightImport, "Freight Import Address")}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="emptyAddress"
                                placeholder="Empty P/U Address"
                                defaultValue={shipmentDetails ? shipmentDetails.emptyAddress : (bookingRequest ? bookingRequest.emptyAddress : '')}
                                refs={register({ required: true })}
                                error={!!errors.emptyAddress}
                                errorMessage={getErrorMessage(errors.emptyAddress, "Empty P/U Address")}
                            />
                        </div>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="fullAddress"
                                placeholder="Drop Full Adress"
                                defaultValue={shipmentDetails ? shipmentDetails.dropAddress : (bookingRequest ? bookingRequest.dropAddress : '')}
                                refs={register({ required: true })}
                                error={!!errors.fullAddress}
                                errorMessage={getErrorMessage(errors.fullAddress, "Drop Full Address")}
                            />
                        </div>
                    </>
                )}
                {brId && (
                    <div className="col-12 single-row">
                        <label className="big-label f-medium mtx5 ptx4">Reference</label>
                        <Input
                            className="mtx1"
                            name="bookingRequestNumber"
                            placeholder="Booking number"
                            defaultValue={shipmentDetails ? shipmentDetails.bookingRequestNumber : (bookingRequest ? bookingRequest.bookingRequestNumber : '')}
                            refs={register()}
                        />
                    </div>
                )}
                <div className="col-12 single-row">
                    {brId && (<label className="big-label f-medium mtx5 ptx4">Add Note</label>)}
                    <Input
                        className={brId ? 'mtx1' : 'mtx3'}
                        name="note"
                        placeholder="Note"
                        defaultValue={shipmentDetails ? shipmentDetails.note : (bookingRequest ? bookingRequest.note : '')}
                        refs={register()}
                    />
                </div>
            </div>
        </div>
    )
};

ShipmentForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.any,
    containers: PropTypes.array,
    setContainers: PropTypes.func,
    typeOfShipment: PropTypes.bool,
    getValues: PropTypes.func,
    shipmentDetails: PropTypes.any
};