import React, { useState } from 'react';

import {
    Dropdown,
    FileUploader
} from 'components';
import { sampleOptions } from 'utils';

export const UploadInvoicesForm = () => {
    const [files, setFiles] = useState([]);

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
            const currentItem = uploadFiles[i];
            if (!currentItem.name) return
            const isExist = files.find(f => f.name === currentItem.name && f.size === currentItem.size);
            if (!isExist) fileList.push(currentItem)
        }
        setFiles(oldFiles => [...oldFiles, ...fileList])
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    return (
        <div className="tr__upload-invoices-form">
            <Dropdown
                className="select mbx4"
                icon="icon-chevron-down"
                iconPosition="right"
                required={true}
                mode="select"
                name="ftaOrigin"
                label="Origin"
                options={sampleOptions}
                placeholder="Select origin"
                dropdownPosition="center"
            />
            <Dropdown
                className="select mbx4"
                icon="icon-chevron-down"
                iconPosition="right"
                required={true}
                mode="select"
                name="ftaDestination"
                label="Destination"
                options={sampleOptions}
                placeholder="Select destination"
                dropdownPosition="center"
            />
            <FileUploader
                name="shipmentDetailsDocs"
                label="Upload Invoices"
                className="big-label"
                type="invoice"
                handleDrop={handleFileUpload}
                handleRemove={handleFileRemove}
                uploadedFiles={files}
                mode="list"
            />
        </div>
    )
};
