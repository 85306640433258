import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import { Input, Button, Checkbox, Datepicker } from 'components';
import {
    convertDate,
    getCreditCardType,
    getErrorMessage,
    isEmail,
    isValidCardNumber,
    isValidCVV,
    isValidExpiredDate,
    hidingCardNumber
} from 'utils';
import { OrganizationContext } from 'contexts';
import { EXPIRED_DATE_FORMAT } from 'actions';

export const BillingDetailsForm = ({
    isModalForm = true,
    isEdit = true,
    title,
    register = () => {},
    errors = [],
    getValues = () => {},
    paymentCards = [],
    setPaymentCards = () => {},
    billingEmails = [],
    setBillingEmails = () => {}
}) => {
    const cardTemplate = {
        _id: 0,
        primary: true
    }
    const [cvvToggle, setCVVToggle] = useState(null);
    const { organization } = useContext(OrganizationContext);

    /*eslint-disable */
    useEffect(() => {
        setPaymentCards(isModalForm ? [] : [cardTemplate]);
    }, [])

    useEffect(() => {
        if (!isEdit && organization) {
            const { paymentCards, billingEmails } = organization;
            setPaymentCards(isModalForm ? [] : paymentCards);
            setBillingEmails(billingEmails && billingEmails.length > 0 ? billingEmails.filter(email => email) : []);
        }
    }, [organization, isEdit])
    /*eslint-enable */

    const handleToggle = card => {
        setPaymentCards([
            ...paymentCards.map(c => {
                return {
                    ...c,
                    primary: c._id === card._id
                };
            })
        ])
    }

    const addBillingEmail = () => setBillingEmails([...billingEmails, '']);
    
    const addPaymentCard = () => {
        const newCard = {
            _id: paymentCards.length,
            primary: paymentCards.length === 0 ? true : false
        };

        setPaymentCards([...paymentCards, newCard])
    };

    const removePaymentCard = card => {
        setPaymentCards([...paymentCards.filter(c => c._id !== card._id)])
    };

    const getCardType = cardNo => {
        if (getCreditCardType) return getCreditCardType(cardNo);
        return 'visa';
    };

    const getCurrentDate = () => {
        const now = new Date();
        return `${now.getFullYear()}-${now.getMonth() + 1}-01`;
    }

    return (
        <div className="tr__billing-details-form">
            {title && (
                <h2 className="h2 f-medium details-heading">{title}</h2>
            )}

            {billingEmails.map((email, emailIndex) => (
                <Input
                    key={emailIndex}
                    label={emailIndex === 0 ? `Add Billing Manager` : ''}
                    placeholder="Enter billing manager"
                    name={`billingEmail${emailIndex}`}
                    defaultValue={email}
                    refs={register({ validate: value => isEmail(value, false) })}
                    error={!!errors[`billingEmail${emailIndex}`]}
                    errorMessage={getErrorMessage(errors[`billingEmail${emailIndex}`], "Billing email", "Invalid email format")}
                />
            ))}

            <div className="mtx3">
                {isEdit && (
                    <Button
                        className="outline icon small mbx2"
                        icon="icon-plus"
                        onClick={addBillingEmail}
                    >
                        Add New
                    </Button>
                )}

                <div className="row">
                    {paymentCards.map((card, cardIndex) => {
                        return (
                            <div className="col-12 col-sm-6" key={cardIndex}>
                                <div className="tr__billing-details-form__card mbx2">
                                    {isEdit && (
                                        <p className="tr__link remove-card" onClick={() => removePaymentCard(card)}>-</p>
                                    )}
                                    <div className="mbx2 d-flex align-items-center justify-content-between">
                                        <img src={require(`assets/images/payments/${getCardType(getValues(`cardNumber${cardIndex}`) || card.cardNumber)}.png`)} alt="" />
                                        {isEdit ? (
                                            <Checkbox
                                                name="toggleAdjustDays"
                                                type="checkbox"
                                                checked={card.primary}
                                                onChange={() => handleToggle(card)}
                                                className="toggle"
                                                label="Make Primary"
                                            />
                                        ) : (card.primary && (
                                            <p className="task-status default">Primary</p>
                                        ))}
                                    </div>
                                    <div className="col-12">
                                        <Input
                                            label="Name on Card"
                                            className="mbx3"
                                            name={`cardName${cardIndex}`}
                                            defaultValue={card.cardName}
                                            placeholder="Your name on card"
                                            refs={register({ required: true })}
                                            error={!!errors[`cardName${cardIndex}`]}
                                            errorMessage={getErrorMessage(errors[`cardName${cardIndex}`], "Card name")}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <Input
                                            label="Card number"
                                            className="mbx3"
                                            name={`cardNumber${cardIndex}`}
                                            defaultValue={isEdit ? card.cardNumber : hidingCardNumber(card.cardNumber)}
                                            placeholder="e.g: 4111xxxxxxxx1111, 4111 xxxx xxxx 1111"
                                            refs={register({ required: true, validate: isValidCardNumber })}
                                            error={!!errors[`cardNumber${cardIndex}`]}
                                            errorMessage={getErrorMessage(errors[`cardNumber${cardIndex}`], "Card number", "Invalid card number")}
                                        />
                                    </div>
                                    <div className="col-12 position-relative">
                                        <Datepicker
                                            name="dueDate"
                                            placeholder="Add due date"
                                            minDate={moment(getCurrentDate()).toDate()}
                                            start={card.expiredDate ? new Date(card.expiredDate) : null}
                                            showMonthYearPicker
                                            showYearDropdown
                                            render={(value, onClick, setStartDate) => {
                                                const selectedValue = value ? moment(new Date(value)).format('MM/yyyy') : '';
                                                return (
                                                    <div className="expired-date" onClick={isEdit ? onClick : null}>
                                                        <Input
                                                            label="Expired date"
                                                            className="mbx3 expired-date--input"
                                                            name={`expiredDate${cardIndex}`}
                                                            defaultValue={selectedValue ? selectedValue : (card.expiredDate ? convertDate(card.expiredDate, EXPIRED_DATE_FORMAT) : '')}
                                                            placeholder="e.g: 12/2020"
                                                            refs={register({ required: true, validate: isValidExpiredDate })}
                                                            error={!!errors[`expiredDate${cardIndex}`]}
                                                            errorMessage={getErrorMessage(errors[`expiredDate${cardIndex}`], "Expired date", "Invalid expired date")}
                                                        /> 
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <Input
                                            label="CVV"
                                            type={cvvToggle === cardIndex ? 'text' : 'password'}
                                            className="mbx3"
                                            name={`cvv${cardIndex}`}
                                            defaultValue={card.cvv}
                                            icon="icon-eye"
                                            iconPosition="right"
                                            placeholder="CVV"
                                            mask="9999"
                                            onIconToggle={() => setCVVToggle(cvvToggle === null ? cardIndex : null)}
                                            refs={register({ required: true, validate: isValidCVV })}
                                            error={!!errors[`cvv${cardIndex}`]}
                                            errorMessage={getErrorMessage(errors[`cvv${cardIndex}`], "CVV", "Invalid CVV")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {isEdit && (
                        <div className="col-12 col-sm-6">
                            <div className={`add-card ${isModalForm ? 'small' : ''}`} onClick={addPaymentCard}>
                                <i className="icon-credit-cards mbx2" />
                                <p>
                                    <i className="icon-plus mrx05" />
                                    <span>{isModalForm ? 'Add a payment card' : 'Add more payment card'}</span>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

BillingDetailsForm.propTypes = {
    title: PropTypes.string,
    isModalForm: PropTypes.bool,
    isEdit: PropTypes.bool,
    handleSubmit: PropTypes.func,
    register: PropTypes.any,
    errors: PropTypes.any,
    paymentCards: PropTypes.array,
    setPaymentCards: PropTypes.func,
    billingEmails: PropTypes.array,
    setBillingEmails: PropTypes.func,
    getValues: PropTypes.func
};