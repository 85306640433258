import React, { useContext, useState } from 'react'

import './index.scss';
import { ModalContext } from 'contexts';
import {
    Modal,
    StepsBreadcrum,
    Dropdown,
    RadioGroup,
    Button,
    ShipmentDocument,
    Checkbox,
    AddPartnersForm,
    VerifyDetailsForm
} from 'components';
import {
    steps,
    roles,
    linksCheckbox,
    sampleOptions,
    documentList
} from 'utils';
import { useHistory } from 'react-router-dom';
import { ACTIVE_SHIPMENTS_URL } from 'actions';

export const LinkTaskModal = ({ open = false, onCancel }) => {
    const { setAddLink } = useContext(ModalContext);
    const [templateChooser, setTemplateChooser] = useState(true);
    const [documentChecked, setDocumentChecked] = useState(documentList.slice(0, 2));
    const [selectedRole, setSelectedRole] = useState(roles[0]);
    const [selectedLink, setSelectedLink] = useState(linksCheckbox[0]);
    const [step, setStep] = useState(0);

    const history = useHistory();

    const handleCancel = () => {
        if (step === 1) {
            setStep(step - 1);
        } else {
            setAddLink(false);
        }
    }

    const handleNext = () => {
        if (step < 2) {
            setStep(step + 1);
        } else {
            setStep(0);
            setAddLink(false);
            history.push(ACTIVE_SHIPMENTS_URL);
        }
    }

    return (

        <Modal
            open={open}
            className="tr__add-link-task"
            onCancel={onCancel}
            onCancelClick={onCancel}
            title="Create A Linked Shipment"
            footerLabel=""
            isBackDropClickable={false}
        >
            <div className="tr__add-link-task__form">
                <StepsBreadcrum
                    steps={steps}
                    activeStep={step}

                />
                {step === 0 && (
                    <>
                        <div className="col-12 mtx4">
                            <Dropdown
                                label="Choose Your Organization"
                                className="select mtx2 large-label"
                                icon="icon-chevron-down"
                                iconPosition="right"
                                mode="select"
                                name="selectOrganization"
                                options={sampleOptions}
                                placeholder="Select Organization"
                                dropdownPosition="center"
                            />
                        </div>

                        <div className="col-12 mtx4">
                            <RadioGroup
                                name="shipmentRole"
                                label="Your role in this shipment"
                                className="large-label"
                                horizontal
                                items={roles.slice(0, 2)}
                                value={selectedRole}
                                onChange={role => setSelectedRole(role)}
                            />
                        </div>

                        <div className="col-12 mtx4">
                            <div className="template-chooser">
                                <div className="template-chooser--header d-flex align-items-center justify-content-between">
                                    <h4 className="h4 f-medium">Choose Template</h4>
                                    <Checkbox
                                        name="templateChooser"
                                        type="checkbox"
                                        checked={templateChooser}
                                        className="toggle right"
                                        onChange={() => setTemplateChooser(!templateChooser)}
                                    />
                                </div>
                                {templateChooser && (
                                    <div className="template-chooser--content mtx2 d-flex align-items-center justify-content-between">
                                        <Dropdown
                                            className="select mrx4"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="select"
                                            name="organization"
                                            options={sampleOptions}
                                            placeholder="Select template"
                                            dropdownPosition="center"
                                        />
                                        <Button
                                            className="small"
                                        >
                                            Preview
                                </Button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 mtx4">
                            <RadioGroup
                                label="Shipment Information"
                                className="large-label"
                                name="vesselType"
                                buttonMode={false}
                                type="default"
                                horizontal
                                items={linksCheckbox}
                                value={selectedLink}
                                onChange={e => setSelectedLink(e)}
                                description="All shipment information will be kept as original shipment. You can always edit later. Do you want to keep all the documents from SGSIN-AUSYD-25092019 (1234)?"
                            />
                        </div>

                        {selectedLink.id === 'yes' && (
                            <div className="col-12 mtx2 tr__add-link__list-file">
                                <ShipmentDocument
                                    icon="icon-file"
                                    values={documentChecked}
                                    items={documentList}
                                    onChange={(docs => setDocumentChecked(docs))}
                                />
                            </div>
                        )}
                    </>
                )}
                {step === 1 && (
                    <AddPartnersForm />
                )}
                {step === 2 && (
                    <VerifyDetailsForm />
                )}

            </div>
            <div className="tr__add-link-task__footer d-flex align-items-center justify-content-between">
                <div></div>
                <div>
                    <p 
                        className="tr__link secondary f-medium" 
                        onClick={handleCancel}
                    >
                        {step === 1 ? 'Back' : 'Cancel'}
                    </p>
                    <Button onClick={handleNext}>
                        {step === 2 ? "Save" : "Continue"}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
