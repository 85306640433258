import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import moment from 'moment';
import {
    Tab,
    TabPane,
    Button,
    Table,
    MembersAvatar,
    IssueDetails
} from 'components';
import { HomeContext, IssueContext, ModalContext } from 'contexts';
import { ALL, CLOSED, ISSUES_URL, ISSUE_DETAILS_URL, ISSUE_PAGE_SIZE } from 'actions';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

export const Issues = () => {
    const history = useHistory();
    const { setAddIssue } = useContext(ModalContext);
    const { 
        issues,
        totalPages, 
        currentPage, 
        doGetIssues,
        setCurrentPage,
        setIssue,
        doGetTasksIssue
    } = useContext(IssueContext);
    const { isArchive } = useContext(HomeContext);
    const [tabs] = useState(['All', 'Open', 'Closed']);
    const [status, setStatus] = useState(ALL);
    const { shipmentId } = useParams();
    /*eslint-disable*/
    useEffect(() => {
        doGetIssues(shipmentId, {
            page : 0,
            status: status,
            limit : ISSUE_PAGE_SIZE
        }, () => {
            setIssue(null);
        })
    },[status])
    /*eslint-enable*/

    const columns = [
		{
			dataIndex: 'unread,status,title,description',
			render: (unread , status, title, description) => (
                <div className={`issue-title d-flex ${status !== CLOSED ? 'active' : ''}`}>
                    <i className={`${status === CLOSED ? 'icon-check' : 'icon-warning'} mrx1`} />
                    <div className="issue-title--info">
                    
                        <h6 className="h6 mbx1">{title} {unread}</h6>
                        <p>{description}</p>
                    </div>
                </div>
            ),
            done: (unread, status, title, description) => unread ? 'done' : false,
        },
        {
			dataIndex: 'partners',
			render: partners => <MembersAvatar members={partners} skip={5} />
        },
        {
			dataIndex: 'base,status',
			render: (base, status) => (
                <div className="issue-date">
                    <h6 className="h6 mbx1">{status !== CLOSED ? moment(base.createdDate).format('DD MMM YYYY | hh:mm A') :  moment(base.updatedDate).format('DD MMM YYYY | hh:mm A')}</h6>
                    <p className={`${status !== CLOSED ? 'open' : ''}`}>{status === CLOSED ? 'Closed on': "Created on"}</p>
                </div>
            )
		}
    ];
    
    const showIssueDetails = issue => {
        if (issue) {
            history.push(`${ISSUES_URL.replace(':shipmentId', shipmentId)}/${issue._id}`);
		}
    }

    const handleAddIssue = () => {
        setAddIssue(true);
        doGetTasksIssue(shipmentId);
    }

    const loadMoreIssues = () => {
        doGetIssues(shipmentId, {
            page: currentPage + 1,
            status: status,
            limit: ISSUE_PAGE_SIZE
        })
    }

    return  (
        <Switch>
            <Route path={ISSUE_DETAILS_URL} exact>
                <IssueDetails />
            </Route>
            <Route path={ISSUES_URL} exact>
                <div className='tr__shipment-issues'>
                    <div className="tr__shipment-issues--header d-flex align-items-center justify-content-end">
                        {!isArchive && (
                            <Button
                                onClick={handleAddIssue}
                            >
                                Create New Issue
                            </Button>
                        )}
                    </div>
                    <Tab
                        initialTab={0}
                        onTabClick={tab => {
                            setStatus(tab.toUpperCase());
                            setCurrentPage(0);
                        }}
                    >
                        {tabs.map((tab, tabIndex) => (
                            <TabPane
                                key={tabIndex}
                                tabName={tab}
                                tabSlug={tab.toLowerCase().replace(' ', '-')}
                            >
                                <Table
                                    className="border"
                                    dataSource={issues}
                                    columns={columns}
                                    onRowClick={showIssueDetails}
                                />
                                {totalPages > currentPage + 1 && (
                                    <div className="load-more-link mtx2 text-center">
                                        <p className="tr__link" onClick={loadMoreIssues}>
                                        Load more Issues...</p>
                                    </div>
                                )}
                            </TabPane>
                        ))}
                    </Tab>
                </div>
            </Route>
        </Switch>
    );
};