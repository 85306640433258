import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    Modal,
    OrganizationDetailsForm,
    BillingDetailsForm
} from 'components';
import { AuthContext, HomeContext, ModalContext, OrganizationContext, UploadFileContext } from 'contexts';
import { getBase64Mime, getMimeFileExtension, getTimestamp, getUnixTimestamp } from 'utils';
import { FILE_TYPE_ORG_FILES, FILE_SUBTYPE_ORG_LOGO } from "actions";

export const AddOrganization = ({ open = false, onCancel }) => {
    const { doCreateOrganization } = useContext(OrganizationContext);
    const { setAddOrganization } = useContext(ModalContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const { doUploadFile } = useContext(UploadFileContext);
    const { loggedInUser } = useContext(AuthContext);

    const { handleSubmit, register, errors, reset, watch } = useForm();
    const [billingEmails, setBillingEmails] = useState(['']);
    const [paymentCards, setPaymentCards] = useState([]);
    const [logo, setLogo] = useState(null);

    const doAddOrganization = async data => {
        const {
            organizationName,
            contactEmail,
            organizationPhoneNo,
            cAddress,
            country,
            postalCode,
            companyRegisteredAddress,
            registrationNumber,
            companyTaxNumber,
        } = data;

        let logoId = null;

        if (logo) {
            const uploadedLogo = await doUploadFile({
                type: FILE_TYPE_ORG_FILES,
                subType: FILE_SUBTYPE_ORG_LOGO,
                fileName: `logo_organizations_${getUnixTimestamp()}`,
                fileExtension: getMimeFileExtension(getBase64Mime(logo)),
            }, logo, loggedInUser);

            logoId = uploadedLogo.id;
        }

        const payload = {
            logo: logoId,
            name: organizationName,
            contactEmail,
            contactPhoneNumber: organizationPhoneNo,
            companyAddress: cAddress,
            country,
            postalCode,
            registeredAddress: companyRegisteredAddress,
            registrationNumber,
            taxNumber: companyTaxNumber,
            billingEmails: billingEmails.map((email, emailIndex) => {
                return data[`billingEmail${emailIndex}`];
            }).filter(email => email),
            paymentCards: paymentCards.map((card, cardIndex) => {
                return {
                    cardName: data[`cardName${cardIndex}`],
                    cardNumber: data[`cardNumber${cardIndex}`],
                    expiredDate: getTimestamp(data[`expiredDate${cardIndex}`]),
                    cvv: data[`cvv${cardIndex}`],
                    primary: card.primary
                }
            })
        }

        doCreateOrganization(payload, () => {
            reset();
            setBillingEmails(['']);
            setPaymentCards([]);
            setAddOrganization(false);
            setNotificationMessage(`
                <p><strong>${payload.name}</strong> organization has been created successfully!</p>
            `);
        });
    }

    return (
        <Modal
            open={open}
            className="tr__add-organization no-padding"
            onCancel={onCancel}
            title="Add New Organization"
            submitButton
            submitButtonLabel="Add"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(doAddOrganization)}>
                    {children}
                </form>
            )}
        >
            <OrganizationDetailsForm
                title="Organization Details"
                register={register}
                errors={errors}
                isEdit={true}
                logo={logo}
                setLogo={setLogo}
            />
            <BillingDetailsForm
                title="Billing Details"
                register={register}
                errors={errors}
                paymentCards={paymentCards}
                setPaymentCards={setPaymentCards}
                billingEmails={billingEmails}
                setBillingEmails={setBillingEmails}
                getValues={watch}
            />
        </Modal>
    )
};

AddOrganization.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
};
