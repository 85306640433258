import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import { TrackShipmentContext } from 'contexts';

export const AISTracking = ({ aisTracking = [] }) => {
    const { firstLocode } = useContext(TrackShipmentContext);
    
    const getLocationValue = code => {
        if (firstLocode && firstLocode.length > 0) {
            const selectedLocode = firstLocode.find(l => l.locode === code);
            if (!selectedLocode) return code;
            const { city, country, locode } = selectedLocode;
            return city + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
        }
        return '';
    }

    return aisTracking.length > 0 ? <InfoBlock
        className="tr__details-box"
        title="AIS Tracking"
    >
        <InfoRow>
            {aisTracking.map((vessel, vesselIndex) => (
                <div className={`d-flex ${vesselIndex > 0 ? 'mtx3' : ''}`} key={vesselIndex}>
                    <div className="col-12 col-sm-3">
                        <div className='row'>
                            <InfoCell
                                className="col-sm-6"
                                label="Vessel name"
                                value={vessel.name}
                            />
                            <InfoCell
                                className="col-sm-6"
                                label="IMO"
                                value={vessel.imo ? `${vessel.imo}` : ''}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className='row'>
                            <InfoCell
                                className="col-sm-6"
                                label="Voyage"
                                value={vessel.voyage ? `${vessel.voyage}` : ''}
                            />
                            <InfoCell
                                className="col-sm-6"
                                label="Departure"
                                value={getLocationValue(vessel.departure)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className='row'>
                            <InfoCell
                                className="col-sm-6"
                                label="Arrival"
                                value={getLocationValue(vessel.arrival)}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </InfoRow>
    </InfoBlock> : ''
};

AISTracking.propTypes = {
    aisTracking: PropTypes.array
};