import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { CheckboxGroup } from 'components';
import { getErrorMessage, taskRoles } from 'utils';

export const AddRole = ({ title, note, register = () => {}, roles, setRoles, errors = [], eventTask, isEditTask}) => {
    /*eslint-disable*/
    useEffect(() => {
        if (isEditTask && eventTask && eventTask.roles && eventTask.roles.length > 0) {
            const selectedTaskRoles = taskRoles.filter(r => eventTask.roles.some(role => role.toLowerCase().replace('_' , '-') === r.id));
            setRoles(selectedTaskRoles.map(role => {
                return {
                    ...role,
                    disabled: true
                }
            }) );
        } else {
            setRoles([]);
        }
    }, [eventTask])
    /*eslint-enable*/

    return(
        <div className="tr__add-role">
            {title && (
                <h4 className="h4 f-medium">{title}</h4>
            )}
            <CheckboxGroup
                name="taskRoles"
                type="checkbox"
                horizontal
                items={taskRoles}
                values={roles}
                refs={register({ required: true })}
                error={!!errors.typeOfTask}
                errorMessage={getErrorMessage(errors.taskRoles, "task Roles")}
                onChange={roles => setRoles(roles)}
                buttonMode={true}
            />
        </div>
    )
};

AddRole.propTypes = {
    title: PropTypes.string,
    note: PropTypes.string
}