import React from 'react';
import './index.scss';
import {
    Modal,
} from 'components';

export const CloneTemplate = ({ open = false, onCancel }) => {
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title="Clone Template"
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Clone"
            cancelButton
            cancelButtonLabel="Cancel"
            cancelButtonType="primary"
            cancelButtonClassNames="label"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">Are you sure you want to clone this template?</h6>
            </div>
        </Modal>
    )
};