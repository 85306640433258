import React from 'react';

import {
    Modal,
} from 'components';

export const DeleteSection = ({ open = false, onCancel }) => {
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title="Delete Template"
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel="Yes"
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">Are you sure you want to delete this template?</h6>
            </div>
        </Modal>
    )
};